import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged,User } from 'firebase/auth';
import { SERVER_URL,UserData} from './Types';
import ZipCodeManagementModal from './ZipCodeManagementModal';

const rowStyles: React.CSSProperties = {
  //borderRadius: '10px',
  padding: '40px',
  border: '1px solid #000000',
  margin: '10px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'black',
  fontSize: '20px',
};

const Providers: React.FC = () => {
  const auth = getAuth();
  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<UserData[]>([]); // State for storing fetched users
  //const [pendingUsers, setPendingUsers] = useState<UserData[]>([]); // State for storing fetched users

  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close
  const [selectedProviderUserData, setSelectedProviderUserData] = useState<UserData | null>(null); // State for selected provider data

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/users`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //console.log('thisone',data.assignments);

      setUsers(data.verifiedProviders); // Update the state with the fetched user data
      //setPendingUsers(data.unverifiedProviders); // Update the state with the fetched user data

    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error; // Handle the error as needed in your component
    }
  };

  useEffect(() => {
    // Fetch user data when the component mounts
    fetchUsers();
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  // Function to render a list of zip codes for a user or an "Add Zipcode" button if none are assigned
  const openModal = (userData:UserData) => {
    setSelectedProviderUserData(userData);
    setIsModalOpen(true);
  };
  
  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
<h1 style={{ marginLeft: '60px',marginTop:'30px',marginBottom:'50px',fontSize:35 }}>Providers</h1>
      {users.length > 0 ? (
        <ul>
          {users.map((userData) => (
            <li key={userData.uid} style={rowStyles}>
              <div>
                <span style={{ fontWeight: 'bold' }}>Email:</span>
                <br />
                <span>{userData.email}</span>
              </div>
              <div>
                <span style={{ fontWeight: 'bold' }}>UID:</span>
                <br />
                <span>{userData.uid}</span>
              </div>
              <div>
                <span style={{ fontWeight: 'bold' }}>Assignments:</span>
                <br />
                {userData.assignments && userData.assignments.length > 0 ? (
                  <ul>
                    {userData.assignments.map((assignment) => {
                      return (
                        <li  key={assignment.id} style={{ marginTop: '20px' ,border: '1px solid #000000',borderRadius: '5px',padding: '20px' }}>
                          Priority: {assignment.priority}, Zip Code: {assignment.ZipCode}, Rate: {assignment.rate}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p>No assignments available</p>
                  
                )}
<button style={{ marginTop: '40px' }} onClick={() => openModal(userData)}>Manage Assignments</button>

              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <p style={{ fontSize: '24px',marginTop:-500 }}>Loading...</p>
      </div>
            )}
  {isModalOpen && (
        <ZipCodeManagementModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          //@ts-ignore
          userData={selectedProviderUserData}
          fetchUsers={fetchUsers}
        />
      )}
    </div>
  );
};


export default Providers;
