import {
  arrayUnion,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import React, { useState, CSSProperties, useEffect } from "react";
import { db } from "../App";
import { processHistoryLogClient } from "./Functions";

interface AddToCartModalProps {
  isOpen: boolean;
  onClose: () => void;
  product: any; // Assuming WarehouseEntry is correctly typed elsewhere
  //onAddToCart: (product: any, quantity: number) => void;
  cartItems: any[];
  selectedStoreId: any;
  addToCart: any
}

const AddToCartModal: React.FC<AddToCartModalProps> = ({
  isOpen,
  onClose,
  product,
  //onAddToCart,
  cartItems,
  selectedStoreId,
  addToCart, // Receive the addToCart function as a prop

}) => {
  const [quantity, setQuantity] = useState(""); // Default quantity to 1

  const [isProcessing, setIsProcessing] = useState(false);


  useEffect(() => {
    if (isOpen) {
      // Reset quantity when the modal opens
      setQuantity("");
    }
  }, [isOpen]);
  
  if (!isOpen) return null;


  
  const handleAddToCart = () => {
    const quantityValue = parseInt(quantity, 10);
    addToCart(product, quantityValue);
    onClose();
  };
  

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <h1 style={{ fontSize: 25 }}>Add to Cart</h1>
        <input
          type="number"
          placeholder="Quantity"
          value={quantity.toString()}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "" || /^\d+$/.test(value)) {
              setQuantity(value);
            }
          }}
          style={modalStyles.inputStyles}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button style={modalStyles.closeButton} onClick={onClose}>
            Cancel
          </button>
          <button
            style={modalStyles.addButton}
            onClick={handleAddToCart}
            disabled={isProcessing}
          >
            {isProcessing ? "Processing..." : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

// Reuse and adapt the modalStyles from the previous example for consistency
const modalStyles: { [key: string]: CSSProperties } = {
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  },
  container: {
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "300px", // Set a max width for larger screens
    width: "90%", // Responsive width
    maxHeight: "85vh",
    overflowY: "auto",
  },
  inputStyles: {
    borderRadius: "12px", // Softer edges
    border: "1px solid #ced4da", // Lighter border color
    padding: "15px", // Increased padding
    marginTop: "12px", // Consistent margin top
    marginBottom: "12px", // Consistent margin bottom
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Subtle inner shadow for depth
    width: "calc(100% - 30px)", // Adjust width to account for padding
    fontSize: "16px", // Slightly larger font size for readability
  },
  closeButton: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
  },
  addButton: {
    fontSize: "16px",
    fontWeight: "600",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
  },
};

export default AddToCartModal;
