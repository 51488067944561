// Types.tsx


//export const SERVER_URL = 'http://localhost:2020';
export const SERVER_URL = 'http://164.92.198.165:2020';

//export const SERVER_URL = 'http://192.168.1.121:3000';

interface Location {
    latitude: number;
    longitude: number;
    latitudeDelta: number;
    longitudeDelta: number;
  }

  interface PricingData { 
    id: string;
    Value: number;
  }
  
  interface Order {
    orderID: string;
    service: string;
    orderedBy: string;
    dateOfService: string;
    dateOrderPlaced: string;
    timeOfService: string;
    orderStatus: string;
    address: {
      cityAddress: string;
      numberAddress: string;
      streetAddress: string;
      stateAddress: string;
      zipCodeAddress: string;
      phoneAddress: string;
      location: Location;
    };
  }

  interface Assignment {
    id: string;
    ZipCode: string;
    priority: number; 
    rate: number;
  }
  
  interface UserData {
    uid: string;
    email: string;
    assignments?: Assignment[];
  }

  // Define a type for ZipCode data
interface ZipCode {
  id: number;
  zipCode: string;
  // Add other properties as needed
}
  
  export { Location, Order, UserData, Assignment, ZipCode, PricingData };
  