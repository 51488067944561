// components/TopBar.tsx
import React, { useState, MouseEvent } from "react";
import { getAuth } from "firebase/auth"; // Make sure to adjust the path
import { CSSProperties } from "react"; // Import CSSProperties
import { FaAngleDown } from "react-icons/fa";
//import bIcon from '../apsee.png';
import bIcon from "../star.jpeg";
import { useLanguage } from "../LanguageContext";

const TopBar: React.FC = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const { language } = useLanguage();

  const [menuOpen, setMenuOpen] = useState(false);

  const [menuPosition, setMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const toggleMenu = (event: MouseEvent) => {
    if (!menuOpen) {
      const rect = event.currentTarget.getBoundingClientRect();
      setMenuPosition({
        x: rect.left,
        y: rect.bottom,
      });
    } else {
      setMenuPosition(null);
    }
    setMenuOpen(!menuOpen);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  const topBarStyle: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "30px 40px",
    backgroundColor: "#1d344c",
    //backgroundColor: "purple",

    flexDirection:
      language === "kurdish"
        ? ("row-reverse" as "row-reverse")
        : ("row" as "row"),
  };

  return (
    <div style={topBarStyle}>
      <div style={styles.logoContainer}>
        <img src={bIcon} style={styles.logoImage} alt="Logo" />
        <div style={styles.logo}>Market</div>
      </div>
      {currentUser && (
        <div onClick={toggleMenu} style={styles.userInfo}>
          <span>{currentUser.email}</span>
          <FaAngleDown style={styles.icon} />
        </div>
      )}
      {menuOpen && (
        <div
          style={{
            ...styles.dropdown,
            left: menuPosition ? menuPosition.x : 0,
            top: menuPosition ? menuPosition.y : 0,
          }}
        >
          <button style={{ fontSize: 15 }} onClick={handleLogout}>
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

const styles: {
  userInfo: CSSProperties; // Apply CSSProperties type
  dropdownIcon: CSSProperties; // Apply CSSProperties type
  dropdown: CSSProperties; // Apply CSSProperties type
  icon: CSSProperties; // Add icon to the styles object
  logo: CSSProperties; // Add logo to the styles object
  logoImage: CSSProperties; // Add this for the image
  logoContainer: CSSProperties; // Add this for the new container
} = {
  logoContainer: {
    display: "flex",
    alignItems: "center", // To align the icon and text vertically
  },
  logo: {
    fontWeight: "bold", // Make the text bold
    fontSize: "26px", // Apply the desired font size
    color: "white",
    fontFamily: "'Segoe UI', sans-serif",
  },
  logoImage: {
    width: "150px", // Adjust the width as needed
    height: "50px", // Adjust the height as needed
    objectFit: "contain", // This ensures the image keeps its aspect ratio
    marginRight: "10px", // Adjust space between the image and the logo text
    borderRadius: 10,
  },
  icon: {
    fontSize: 20, // Set the desired icon size
    marginRight: 5,
    color: "white",
  },

  userInfo: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
  },
  dropdownIcon: {
    marginLeft: "5px",
    width: "20px",
    height: "20px",
  },
  dropdown: {
    position: "absolute",
    //top: '50px', // Adjust as needed
    //right: '20px',
    backgroundColor: "#1d344c",
    border: "1px solid black",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "10px",
  },
};

export default TopBar;
