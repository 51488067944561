
import { doc, getDoc, updateDoc, setDoc, arrayUnion, Timestamp, collection, addDoc } from "firebase/firestore";
import { db } from '../App'; // Assume db is your Firestore instance initialized in App.js


// Function to process history log on the client-side
export async function processHistoryLogClient(
  itemId: any,
  actionByUid: any,
  actionType: any,
  details: any,
  transaction: any = null // Optional transaction parameter
) {
  const timestamp = Timestamp.fromDate(new Date());
  const logEntry = {
    actionByUid,
    actionType,
    timestamp: timestamp.toMillis(),
    details,
  };

  const historyDocRef = doc(db, "ProcessHistory", itemId);

  try {
    if (transaction) {
      // Transactional version
      // Remove the read operation
      // Directly set the historyDocRef document (creates or merges)
      transaction.set(historyDocRef, {}, { merge: true });

      // Add the log entry to the 'history' subcollection
      const historyCollectionRef = collection(historyDocRef, "history");
      const newLogId = generateFirestoreId(); // Generate a unique ID
      const newLogRef = doc(historyCollectionRef, newLogId);
      transaction.set(newLogRef, logEntry);
    } else {
      // Non-transactional version (existing code)
      const docSnap = await getDoc(historyDocRef);
      if (!docSnap.exists()) {
        await setDoc(historyDocRef, {}); // Create an empty document
      }
      // Add the log entry to the 'history' subcollection
      const historyCollectionRef = collection(historyDocRef, "history");
      await addDoc(historyCollectionRef, logEntry);
    }

    console.log("History log entry added successfully to subcollection");
  } catch (error) {
    console.error("Error updating history log:", error);
    throw error; // Re-throw the error to be caught in restoreInvoice
  }
}

function generateFirestoreId() {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
}




export const formatEpochDate = (milliseconds: any) => {
  // Create a new Date object with the milliseconds value
  const date = new Date(milliseconds);

  // Array of month names
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Extract date components
  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  // Return the formatted date string (e.g., "Jul 11, 2023")
  return `${month} ${day}, ${year}`;
};


export const formatEpochTime = (milliseconds: any) => {
  // Create a new Date object with the milliseconds value
  const date = new Date(milliseconds);

  // Extract time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Return the formatted time string (e.g., "08:24")
  return `${hours}:${minutes}`;
};


export const formatBase64Image = (base64: string) => {
  if (!base64.startsWith('data:image')) {
    return `data:image/jpeg;base64,${base64}`; // Assuming it's a JPEG. Change if necessary.
  }
  return base64;
};


export const formatDateFromTimestamp = (milliseconds: any) => {
  if (!milliseconds || isNaN(milliseconds)) {
    // If milliseconds is null, undefined, or not a number, return a placeholder or an empty string
    return "Invalid Date";
  }

  // Proceed with the original logic since milliseconds is valid
  const date = new Date(milliseconds);
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const formatDateTimeFromTimestamp = (milliseconds: any) => {
  if (!milliseconds || isNaN(milliseconds)) {
    // If milliseconds is null, undefined, or not a number, return a placeholder or an empty string
    return "Invalid DateTime";
  }

  // Proceed with the original logic since milliseconds is valid
  const date = new Date(milliseconds);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};



export const formatDate = (dateString: any) => {
  const date = new Date(
    dateString.replace(
      /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/,
      "$2/$1/$3 $4:$5:$6"
    )
  );
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatTime = (dateTimeString: string) => {
  const date = new Date(
    dateTimeString.replace(
      /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/,
      "$2/$1/$3 $4:$5:$6"
    )
  );
  return date.toLocaleTimeString("en-US", {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // Set to true for 12-hour format, false for 24-hour format
  });
};
