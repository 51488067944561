//@ts-nocheck
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import React, { CSSProperties, useEffect, useState } from "react";
import { db } from "../App";
import { formatBase64Image, processHistoryLogClient } from "./Functions";
import InvoiceDetailsModal from "./InvoiceDetailsModal"; // Import InvoiceDetailsModal
import { FaArrowDown, FaShoppingCart, FaFileInvoice } from "react-icons/fa";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import CartAdminModal from "./CartAdminModal";

const ItemDetailsModal = ({ isOpen, onClose, item }: any) => {
  const [mode, setMode] = useState("details"); // 'details' or 'restock'

  const [viewMode, setViewMode] = useState("value"); // 'value' or 'chart'

  const [additionalQuantity, setAdditionalQuantity] = useState(0);
  const [newUnitPrice, setNewUnitPrice] = useState(item.unitPrice || 0);
  const [newQuantity, setNewQuantity] = useState(item.quantity); // Added state for quantity

  const [newVat, setNewVat] = useState(item.vat || false);

  const [newName, setNewName] = useState(item.name || "");
  const [newDescription, setNewDescription] = useState(item.description || "");
  const [newBarcode, setNewBarcode] = useState(item.barcode || "");

  const [newCategory, setNewCategory] = useState(item.category || "");
  const [categories, setCategories] = useState<string[]>([]);
  const [existingBarcodes, setExistingBarcodes] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [newPriority, setNewPriority] = useState(item.priority || 0); // New state for priority

  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false); // State to control InvoiceDetailsModal visibility
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null); // State to store selected invoice ID

  const [isProcessing, setIsProcessing] = useState(false);

  const [newImage, setNewImage] = useState(item.imageUrl || ""); // State to handle the image

  const [historyData, setHistoryData] = useState([]);

  const [loadingStores, setLoadingStores] = useState({}); // Store loading states
  const [storeDetails, setStoreDetails] = useState({});

  const [reservedCarts, setReservedCarts] = useState([]);
  const [isReserveModalOpen, setIsReserveModalOpen] = useState(false);
  const [isLoadingReservedCarts, setIsLoadingReservedCarts] = useState(false);
  const [selectedCart, setSelectedCart] = useState(null);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);

  const handleReserveClick = async () => {
    setIsLoadingReservedCarts(true);

    // Fetch carts containing this item
    const cartsWithItem = await fetchCartsContainingItem(item.id);

    // Fetch pending invoices containing this item
    const pendingInvoicesWithItem = await fetchPendingInvoicesContainingItem(
      item.id
    );

    // Add a `type` property to each object to distinguish between carts and invoices
    const reservedItems = [
      ...cartsWithItem.map((cart) => ({ ...cart, type: "cart" })),
      ...pendingInvoicesWithItem.map((invoice) => ({
        ...invoice,
        type: "invoice",
      })),
    ];

    setReservedCarts(reservedItems);

    setIsLoadingReservedCarts(false);
    setIsReserveModalOpen(true);
  };

  const fetchPendingInvoicesContainingItem = async (itemId) => {
    const invoicesRef = collection(db, "Invoices");
  
    try {
      // Fetch all invoices without querying for any specific field
      const invoicesSnapshot = await getDocs(invoicesRef);
  
      // Log the number of invoices fetched
      console.log("Number of invoices fetched:", invoicesSnapshot.size);
  
      const filteredInvoices = [];
  
      // Loop through the fetched invoices and check for the item
      for (const invoiceDoc of invoicesSnapshot.docs) {
        const invoiceData = invoiceDoc.data();
  
        // Log the entire invoice to see the structure
        console.log("Invoice Data:", invoiceData);
  
        // Check if the 'status' field is missing or is 'Pending'
        if (!("status" in invoiceData) || invoiceData.status === 'Pending') {
          const items = invoiceData.items || [];
          const itemInInvoice = items.find(
            (invoiceItem) => invoiceItem.docId === itemId
          );
  
          // If the item exists in the invoice, push it to the array
          if (itemInInvoice) {
            filteredInvoices.push({
              id: invoiceDoc.id,
              storeName: invoiceData.storeName,
              items,
              totalPrice: invoiceData.totalPrice,
              phoneNumber: invoiceData.phoneNumber,
            });
          }
        }
      }
  
      // Log the filtered invoices
      console.log(
        "Invoices without status or with status 'Pending' containing the item:",
        filteredInvoices
      );
  
      return filteredInvoices;
    } catch (error) {
      console.error("Error fetching invoices:", error);
      return [];
    }
  };

  const fetchCartsContainingItem = async (itemId) => {
    const cartsRef = collection(db, "Cart");
    const cartsSnapshot = await getDocs(cartsRef);
    const cartsWithItem = [];

    for (const cartDoc of cartsSnapshot.docs) {
      const cartData = cartDoc.data();
      const items = cartData.items || [];

      // Check if the cart contains the item
      const itemInCart = items.find((cartItem) => cartItem.id === itemId);

      if (itemInCart) {
        // Fetch store details if necessary
        let storeName = "admin";
        if (cartDoc.id !== "admin") {
          const prospectDocRef = doc(db, "Prospects", cartDoc.id);
          const prospectDoc = await getDoc(prospectDocRef);
          if (prospectDoc.exists()) {
            storeName = prospectDoc.data().name;
          }
        }

        cartsWithItem.push({
          id: cartDoc.id,
          storeName,
          items,
        });
      }
    }

    return cartsWithItem;
  };

  const fetchItemDetails = async (items) => {
    if (items.length === 0) return [];

    const itemIds = items.map((item) => item.id);
    const chunkSize = 30;
    const chunks = [];

    for (let i = 0; i < itemIds.length; i += chunkSize) {
      chunks.push(itemIds.slice(i, i + chunkSize));
    }

    const itemDetailsMap = new Map();

    await Promise.all(
      chunks.map(async (chunk) => {
        const q = query(
          collection(db, "Warehouse"),
          where("__name__", "in", chunk)
        );
        const itemSnapshots = await getDocs(q);
        itemSnapshots.forEach((doc) => {
          itemDetailsMap.set(doc.id, doc.data());
        });
      })
    );

    // Return items with their details correctly mapped
    return items.map((item) => {
      const itemDetail = itemDetailsMap.get(item.id);
      return {
        ...item,
        unitPrice: itemDetail ? itemDetail.unitPrice : item.unitPrice,
      };
    });
  };

  const calculateTotalAmount = (items) => {
    return items.reduce((total, item) => {
      return total + (item.unitPrice || 0) * item.quantity;
    }, 0);
  };

  const handleOpenCartModal = (cart) => {
    setSelectedCart(cart);
    setIsCartModalOpen(true);
  };

  const handleCloseCartModal = () => {
    setSelectedCart(null);
    setIsCartModalOpen(false);
  };

  const [hideUnchangedUpdates, setHideUnchangedUpdates] = useState(true);

  const handleToggleChange = () => {
    setHideUnchangedUpdates(!hideUnchangedUpdates);
  };

  /*
  useEffect(() => {
    if (!item.id) return;

    // Listen to changes on the document
    const itemDocRef = doc(db, "Warehouse", item.id);
    const unsubscribe = onSnapshot(itemDocRef, (docSnap) => {
      if (docSnap.exists()) {
        const itemData = docSnap.data();
        //setItem(itemData);
        // Update state variables with the new data
        setNewUnitPrice(itemData.unitPrice || 0);
        //setNewQuantity(itemData.quantity || 0);
        setNewVat(itemData.vat || false);
        setNewName(itemData.name || "");
        setNewDescription(itemData.description || "");
        setNewBarcode(itemData.barcode || "");
        setNewCategory(itemData.category || "");
        setNewPriority(itemData.priority || 0);
        setNewImage(itemData.imageUrl || "");
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [item.id]);
  */

  useEffect(() => {
    if (item) {
      console.log("ItemDetailsModal received updated item:", item);
    }
  }, [item]);

  /*
  useEffect(() => {
    console.log(item.quantity)
    if (mode === "price") {
      setNewQuantity(item.quantity);
    }
  }, [item]);
*/

  const loadStoreDetails = async (storeId) => {
    if (!storeId) {
      console.error("storeId is undefined or null");
      return;
    }

    //console.log("STORE NOWNOW", storeId); // Log the storeId being checked

    if (storeDetails[storeId] || loadingStores[storeId]) return;

    // console.log(`Loading store details for storeId: ${storeId}`);

    setLoadingStores((prev) => ({ ...prev, [storeId]: true }));

    const storeDocRef = doc(db, "Prospects", storeId);
    const storeDocSnap = await getDoc(storeDocRef);
    if (storeDocSnap.exists()) {
      console.log(
        `Store details found for storeId: ${storeId}`,
        storeDocSnap.data()
      );
      setStoreDetails((prev) => ({ ...prev, [storeId]: storeDocSnap.data() }));
    } else {
      //console.log(`No store details found for storeId: ${storeId}`);
      setStoreDetails((prev) => ({
        ...prev,
        [storeId]: { name: "Unknown Store" },
      }));
    }

    setLoadingStores((prev) => ({ ...prev, [storeId]: false }));
  };

  useEffect(() => {
    const handleScroll = () => {
      console.log("Handle scroll triggered");
      const historyEntries = document.querySelectorAll(".historyEntry");
      historyEntries.forEach((entry) => {
        const storeId = entry.getAttribute("data-storeid");
        console.log("Checking storeId in scroll:", storeId);
        if (entry.getBoundingClientRect().top < window.innerHeight) {
          loadStoreDetails(storeId);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [historyData]);

  useEffect(() => {
    if (historyData.length > 0) {
      historyData.forEach((historyEntry) => {
        const user = historyEntry.actionByUid;
        //console.log('Calling loadStoreDetails for storeId:', user);
        loadStoreDetails(user);
      });
    }
  }, [historyData]);

  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === "value" ? "chart" : "value"));
  };

  const handleInvoiceClick = (invoiceId: string) => {
    setSelectedInvoiceId(invoiceId);
    setInvoiceModalOpen(true);
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Categories"), (snapshot) => {
      const fetchedCategories = snapshot.docs.map((doc) => doc.data().Category);
      setCategories(fetchedCategories);
    });

    return () => unsubscribe();
  }, []);

  const [userUid, setUserUid] = useState("admin");

  /*
  useEffect(() => {
    const fetchBarcodes = async () => {
      if (!isOpen) return;

      setIsUpdating(true);
      const querySnapshot = await getDocs(collection(db, "Warehouse"));
      const fetchedBarcodes = querySnapshot.docs.map(
        (doc) => doc.data().barcode
      );
      setExistingBarcodes(
        fetchedBarcodes.filter((barcode) => barcode !== item.barcode)
      ); // Exclude the current item's barcode
      setIsUpdating(false);
    };

    fetchBarcodes();
  }, [isOpen]);
  */

  const fetchHistoryData = async () => {
    if (!item || !item.id) return;

    setIsLoadingHistory(true);
    const historyCollectionRef = collection(
      db,
      "ProcessHistory",
      item.id,
      "history"
    );
    try {
      const querySnapshot = await getDocs(historyCollectionRef);
      let historyData = querySnapshot.docs.map((doc) => doc.data());

      // Sort historyData by timestamp in ascending order (oldest to newest)
      historyData.sort((a, b) => a.timestamp - b.timestamp);

      setHistoryData(historyData);
      console.log(historyData);
    } catch (error) {
      console.error("Error fetching history data: ", error);
      setHistoryData([]); // Handle the error by setting no data
    } finally {
      setIsLoadingHistory(false);
    }
  };

  const ViewHistory = async () => {
    fetchHistoryData();

    setMode("history");
  };

  const handleArchive = async () => {
    if (!item || !item.id) {
      alert("Item details are missing.");
      return;
    }

    // Check if the item's reserved quantity is more than 0
    if (item.reserved > 0) {
      alert(`Cannot archive ${item.name} as it has reserved quantities.`);
      return;
    }

    const itemRef = doc(db, "Warehouse", item.id);
    try {
      await updateDoc(itemRef, {
        archived: true, // Set the 'archived' field to true
      });
      alert(`Successfully archived ${item.name}.`);
      onClose(); // Close the modal after archiving
    } catch (error) {
      console.error("Error archiving document: ", error);
      alert("Failed to archive item.");
    }
  };

  const handleUnarchive = async () => {
    if (!item || !item.id) {
      alert("Item details are missing.");
      return;
    }
    const itemRef = doc(db, "Warehouse", item.id);
    try {
      await updateDoc(itemRef, { archived: false });
      alert(`Successfully unarchived ${item.name}.`);
      onClose();
    } catch (error) {
      console.error("Error unarchiving item: ", error);
      alert("Failed to unarchive item.");
    }
  };

  const handleDelete = async () => {
    if (!item || !item.id) {
      alert("Item details are missing.");
      return;
    }

    const itemRef = doc(db, "Warehouse", item.id);
    try {
      await deleteDoc(itemRef);
      alert(`Successfully deleted ${item.name}.`);
      onClose(); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting document: ", error);
      alert("Failed to delete item.");
    }
  };

  const handleUpdateDetails = async () => {
    // Validation checks for unit price
    if (newUnitPrice <= 0 || !/^\d+(\.\d{0,2})?$/.test(String(newUnitPrice))) {
      alert("Please enter a valid unit price with up to two decimal places.");
      return;
    }
    // Validation for barcode uniqueness
    if (newBarcode && existingBarcodes.includes(newBarcode)) {
      alert(
        "The barcode already exists in the warehouse. Please use a unique barcode."
      );
      return;
    }
    // Validation for quantity (allowing zero but checking against reserved quantity)
    if (!Number.isInteger(newQuantity) || newQuantity < 0) {
      alert("Please enter a valid non-negative integer for the quantity.");
      return;
    }
    if (item.reserved > newQuantity) {
      alert(
        `The new quantity cannot be less than the reserved quantity (${item.reserved}).`
      );
      return;
    }

    setIsProcessing(true); // Disable the button

    // Document reference
    const itemRef = doc(db, "Warehouse", item.id);
    try {
      // Update the document
      await updateDoc(itemRef, {
        name: newName,
        description: newDescription,
        barcode: newBarcode,
        category: newCategory,
        unitPrice: newUnitPrice,
        quantity: newQuantity,
        vat: newVat, // Include VAT field in the update
        imageUrl: newImage, // Update the image URL
        priority: newPriority, // Include priority in the update
      });

      // Optionally log the update
      await processHistoryLogClient(item.id, userUid, "item_update", {
        quantity: newQuantity,

        prevDetails: {
          name: item.name,
          description: item.description,
          barcode: item.barcode,
          category: item.category,
          price: item.unitPrice,
          quantity: item.quantity,
          vat: item.vat, // Include previous VAT status in the log
          imageUrl: item.imageUrl, // Log previous image
        },
        newDetails: {
          name: newName,
          description: newDescription,
          barcode: newBarcode,
          category: newCategory,
          price: newUnitPrice,
          quantity: newQuantity,
          vat: newVat, // Include new VAT status in the log
          imageUrl: newImage, // Log new image
          priority: newPriority, // Include priority in the update
        },
        additionalInfo: { updatedBy: userUid },
      });

      alert(`Details updated successfully for ${item.name}.`);
      onClose();
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Failed to update item details.");
    } finally {
      setIsProcessing(false); // Re-enable the button
    }
  };

  const resizeImage = (
    img: HTMLImageElement,
    maxWidth: number,
    maxHeight: number,
    callback: (resizedDataUrl: string) => void
  ) => {
    const canvas = document.createElement("canvas");
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = width * (maxHeight / height);
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d")!;
    ctx.drawImage(img, 0, 0, width, height);
    callback(canvas.toDataURL());
  };

  const handleImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          resizeImage(img, 500, 500, (resizedDataUrl) => {
            const base64WithoutPrefix = resizedDataUrl.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            );

            // Determine the correct prefix based on the file type
            const fileType = file.type;
            let prefix = "data:image/jpeg;base64,";
            if (fileType === "image/png") {
              prefix = "data:image/png;base64,";
            }

            setNewImage(prefix + base64WithoutPrefix); // Use setNewImage instead of setImage
          });
        };
        img.src = e.target!.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRestock = async () => {
    if (!Number.isInteger(additionalQuantity) || additionalQuantity <= 0) {
      alert("Please enter a valid positive integer quantity to add.");
      return;
    }

    setIsProcessing(true); // Disable the button

    // Calculate the new quantity after restock
    const newQuantity = item.quantity + additionalQuantity;

    const itemRef = doc(db, "Warehouse", item.id);
    try {
      await updateDoc(itemRef, { quantity: newQuantity });

      // After successfully updating item quantity in handleRestock
      await processHistoryLogClient(item.id, userUid, "restock", {
        prevQuantity: item.quantity,
        quantity: newQuantity,
        additionalInfo: { restockedBy: userUid, additionalQuantity },
      });

      alert(
        `Successfully restocked ${item.name} with ${additionalQuantity} units.`
      );
      onClose();
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Failed to restock item.");
    } finally {
      setIsProcessing(false); // Re-enable the button
    }
  };

  const calculateActionOpacityAndDisplay = (action, item, index) => {
    let opacity = 1;
    let displayValue = item.value;

    // Apply the same logic but swap index 1 and index 2 wherever they're used
    if (["create", "restock", "item_update"].includes(action)) {
      opacity = index === 0 ? 0 : 1;
    } else if (["reserve", "unreserve"].includes(action)) {
      opacity = index === 2 ? 0 : 1; // Swapped index 1 with 2
    } else if (["deny_invoice"].includes(action)) {
      opacity = index === 2 ? 0 : 1; // Swapped index 1 with 2
    } else if (["accept_invoice"].includes(action)) {
      opacity = index === 1 ? 0 : 1; // Swapped index 2 with 1
    } else if (["restock_item/void invoice"].includes(action)) {
      opacity = index === 0 ? 0 : 1;
    } else if (["restore_invoice"].includes(action)) {
      opacity = index === 2 ? 0 : 1;
    }

    // Swapping logic in the second part as well
    if (["reserve"].includes(action)) {
      displayValue =
        index === 0
          ? `+${Math.abs(item.value)}`
          : index === 1
          ? `-${Math.abs(item.value)}`
          : item.value; // Swapped index 2 with 1
    } else if (["unreserve"].includes(action)) {
      displayValue =
        index === 0
          ? `-${Math.abs(item.value)}`
          : index === 1
          ? `+${Math.abs(item.value)}`
          : item.value; // Swapped index 2 with 1
    } else if (["deny_invoice"].includes(action)) {
      displayValue =
        index === 0 ? `-${Math.abs(item.value)}` : `+${Math.abs(item.value)}`;
    } else if (["accept_invoice"].includes(action)) {
      displayValue =
        index === 0 || index === 2 ? `-${Math.abs(item.value)}` : item.value; // Swapped index 1 with 2
    } else if (["restore_invoice"].includes(action)) {
      displayValue =
      index === 1 ? `-${Math.abs(item.value)}` : `+${Math.abs(item.value)}`;
    } else if (item.value >= 0) {
      displayValue = `+${item.value}`;
    }

    return { opacity, displayValue };
  };

  const calculateHistoryEntryDetails = (
    historyEntry,
    prevEntry,
    storeDetails,
    loadingStores
  ) => {
    const date = new Date(historyEntry.timestamp);
    const formattedDate = date.toLocaleString();

    const action = historyEntry.actionType;
    const user = historyEntry.actionByUid;

    let reservedStock = 0;
    let warehouseStock = 0;
    let availableStock = 0;
    let actionAmount = 0;

    // Default reservedStock to the previous row's reserved value
    reservedStock =
      prevEntry.reservedStock ||
      prevEntry.details?.newReserved ||
      prevEntry.details?.reserved ||
      0;

    if (action === "item_update") {
      // Current quantity from item_update details
      const prevQuantity =
        prevEntry.warehouseStock !== undefined
          ? prevEntry.warehouseStock
          : historyEntry.details.prevDetails?.quantity || 0;

      // Current quantity from item_update details
      warehouseStock = historyEntry.details.newDetails?.quantity || 0;

      // Calculate the action amount as the difference between new and previous quantities
      actionAmount = warehouseStock - prevQuantity;

      // Calculate available stock
      availableStock = prevEntry.availableStock - actionAmount * -1;

      reservedStock = prevEntry.reservedStock;
    } else if (action === "restock") {
      // Difference between quantity and prevQuantity for restock
      const prevQuantity = historyEntry.details.prevQuantity || 0;
      actionAmount = historyEntry.details.quantity - prevQuantity;

      // Use previous entry to calculate new values
      const prevAvailableStock = prevEntry.availableStock || 0;
      const prevWarehouseStock = prevEntry.warehouseStock || 0;

      // Update warehouse and available stock based on previous values
      warehouseStock = prevWarehouseStock + actionAmount;
      availableStock = prevAvailableStock + actionAmount;
    } else {
      reservedStock = historyEntry.details.newReserved || 0;
      warehouseStock = historyEntry.details.quantity || "error";
      availableStock = warehouseStock - reservedStock;

      // Calculate actionAmount based on other actions
      if (action === "reserve") {
        const prevReserved = historyEntry.details.previousReserved || 0;
        actionAmount = reservedStock - prevReserved;
      } else if (action === "create") {
        actionAmount = warehouseStock;
      } else if (action === "accept_invoice") {
        const prevQuantity = historyEntry.details.previousQuantity || 0;
        actionAmount = prevQuantity - warehouseStock;

        reservedStock = (prevEntry.reservedStock || 0) - actionAmount;
        availableStock = prevEntry.availableStock;
      } else if (["deny_invoice", "unreserve"].includes(action)) {
        const prevReserved = historyEntry.details.previousReserved || 0;
        actionAmount = prevReserved - reservedStock;
      } else if (action === "restock_item/void invoice") {
        const prevQuantity = historyEntry.details.previousQuantity || 0;
        actionAmount = historyEntry.details.quantity - prevQuantity;
        reservedStock = prevEntry.reservedStock || 0;
        //availableStock = prevEntry.availableStock + actionAmount;
      } else if (action === "restore_invoice") {
        actionAmount =
          historyEntry.details.newReserved -
          historyEntry.details.previousReserved;
        reservedStock = historyEntry.details.newReserved;
        //availableStock = prevEntry.availableStock + actionAmount;
      }
    }

    // Store calculated values in the history entry for next iteration use
    historyEntry.warehouseStock = warehouseStock;
    historyEntry.availableStock = availableStock;
    historyEntry.reservedStock = reservedStock;

    // Get store details and loading state
    const store = storeDetails[user];
    const isLoading = loadingStores[user];

    // Determine colors
    const reservedColor = reservedStock === 0 ? "grey" : "#FFCC00";
    const warehouseColor = warehouseStock === 0 ? "red" : "blue";
    const availableColor = availableStock === 0 ? "red" : "green";

    // Return all calculated and derived values
    return {
      formattedDate,
      action,
      user,
      reservedStock,
      warehouseStock,
      availableStock,
      actionAmount,
      store,
      isLoading,
      reservedColor,
      warehouseColor,
      availableColor,
    };
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0,0,0,0.2)",
          }}
        >
          <p>{label}</p>
          <p style={{ color: "#8884d8" }}>Total Stock: {payload[0].value}</p>
          <p style={{ color: "#00cc66" }}>
            Available Stock: {payload[1].value}
          </p>
          <p style={{ color: "#FFCC00" }}>Reserved Stock: {payload[2].value}</p>
          {/* Add the action type to the tooltip */}
          <p style={{ color: "black", fontWeight: "bold" }}>
            Action: {payload[0].payload.action}{" "}
            {/* Access action from payload */}
          </p>
        </div>
      );
    }

    return null;
  };

  const [chartData, setChartData] = useState([]); // Store chart data

  // Function to generate chart data from history entries
  const generateChartData = () => {
    const chartData = historyData.map((historyEntry, index) => {
      const prevEntry = historyData[index - 1] || {};

      // Calculate stock details for this entry
      const { formattedDate, warehouseStock, reservedStock, availableStock } =
        calculateHistoryEntryDetails(
          historyEntry,
          prevEntry,
          storeDetails,
          loadingStores
        );

      // Include the actionType in the chart data
      return {
        date: formattedDate,
        stock: warehouseStock,
        reserveStock: reservedStock,
        availableStock: availableStock,
        action: historyEntry.actionType, // Add actionType here
      };
    });

    setChartData(chartData); // Store generated chart data
  };

  useEffect(() => {
    if (mode === "stock_chart") {
      generateChartData(); // Generate chart data when chart mode is activated
    }
  }, [mode, historyData]);

  const renderStockChart = () => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barCategoryGap="0%" // Remove gap between bar categories
          barGap={0} // Remove gap between bars within a category
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          {/* Use the custom tooltip */}
          <Tooltip content={<CustomTooltip />} />
          <Legend />

          {/* Total Stock Bar */}
          <Bar
            dataKey="stock"
            fill="rgba(0,0,0,0)" // Make the Total Stock bar transparent
            name="Total Stock"
            barSize={10}
            fill="#8884d8" // Color for Total Stock
          />

          {/* Available Stock Bar */}
          <Bar
            dataKey="availableStock"
            stackId="a" // Stack this bar with Reserved Stock
            fill="#00cc66" // Green color for Available Stock
            name="Available Stock"
            barSize={10}
          />

          {/* Reserved Stock Bar */}
          <Bar
            dataKey="reserveStock"
            stackId="a" // Stack this bar with Available Stock
            fill="#FFCC00" // Yellow color for Reserved Stock
            name="Reserved Stock"
            barSize={10}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  if (!isOpen) return null;

  const labelStyle: React.CSSProperties = {
    marginBottom: "5px",
    fontWeight: 600,
    fontSize: 15,
    padding: "15px 10px", // Twice the padding vertically compared to horizontally
    borderRadius: "15px",
    maxWidth: "60%",
  };

  //console.log(item.name)

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        {isReserveModalOpen && (
          <div style={modalStyles.background}>
            <div style={modalStyles.container}>
              <h2>Stores with Reserved Quantities</h2>
              {isLoadingReservedCarts ? (
                <p>Loading...</p>
              ) : reservedCarts.length > 0 ? (
                reservedCarts.map((cartOrInvoice) => {
                  const isInvoice = cartOrInvoice.type === "invoice";
                  const leftBackgroundColor = isInvoice ? "#007bff" : "#28a745"; // Blue for invoices, Green for carts
                  const IconComponent = isInvoice
                    ? FaFileInvoice
                    : FaShoppingCart; // Icon based on type
                  return (
                    <div
                      key={cartOrInvoice.id}
                      style={modalStyles.cartCard}
                      onClick={() => {
                        if (isInvoice) {
                          handleInvoiceClick(cartOrInvoice.id); // Open invoice modal
                        } else {
                          handleOpenCartModal(cartOrInvoice); // Open cart modal
                        }
                      }}
                    >
                      {/* Left Side Icon with Background */}
                      <div
                        style={{
                          ...modalStyles.leftSide,
                          backgroundColor: leftBackgroundColor, // Blue or Green based on type
                        }}
                      >
                        <IconComponent size={24} color="white" />{" "}
                        {/* Updated size */}
                      </div>

                      {/* Right Side Details */}
                      <div style={modalStyles.rightSide}>
                        <h3>Store Name: {cartOrInvoice.storeName}</h3>
                        {isInvoice ? (
                          <div>
                            <p>Type: Invoice</p>
                            <p>Invoice ID: {cartOrInvoice.id}</p>
                            <p>
                              Reserved Quantity:{" "}
                              {cartOrInvoice.items.reduce(
                                (total, item) => total + item.quantity,
                                0
                              )}{" "}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p>Type: Cart</p>
                            <p>
                              Reserved Quantity:{" "}
                              {
                                cartOrInvoice.items.find(
                                  (cartItem) => cartItem.id === item.id
                                )?.quantity
                              }
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No stores or pending invoices have this item reserved.</p>
              )}

              <button
                style={modalStyles.button}
                onClick={() => setIsReserveModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {mode === "details" && (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <div style={{ display: "flex" }}>
                  {/* Container for Item Details (Text) */}
                  <div style={{ flex: 1, paddingRight: "20px" }}>
                    <h2>Item Details</h2>
                    <p>Name: {item.name}</p>
                    <p>Category: {item.category}</p>
                    <p>Description: {item.description}</p>
                    <p>Barcode: {item.barcode}</p>
                    <p>ID: {item.id}</p>
                    <p>Unit Price: £{item.unitPrice}</p>
                    <p>VAT: {item.vat ? "Yes" : "No"}</p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* Text for TOTAL STOCK */}
                    <div
                      style={{
                        marginRight: "10px",
                        textAlign: "right",
                        border: "4px solid black",
                        padding: "5px",
                        fontWeight: "700",
                        backgroundColor: "blue",
                        color: "white", // Ensure the text is readable against the blue background
                      }}
                    >
                      <p style={{ margin: 0 }}>TOTAL STOCK: {item.quantity}</p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        height: "200px", // Set the desired height for the bars
                        width: "80px", // Set a fixed total width for the container
                        border: "4px solid black", // Border surrounding both bars
                        marginBottom: "40px",
                      }}
                    >
                      {/* Total Stock Bar (Blue) */}
                      <div
                        style={{
                          backgroundColor: "blue",
                          height: "100%", // Full height of the container
                          width: "50%", // Half the width of the container
                        }}
                      ></div>

                      {/* Reserved and Available Bars Container */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse", // Stack bars vertically with yellow on top
                          height: "100%", // Match the height of the total stock bar
                          width: "50%", // Half the width of the container
                        }}
                      >
                        {/* Available Quantity Bar */}
                        <div
                          style={{
                            backgroundColor: "green",
                            height: `${
                              item.reserved && item.reserved > 0
                                ? ((item.quantity - item.reserved) /
                                    item.quantity) *
                                  100
                                : 100 // Show full bar if reserved is 0 or undefined
                            }%`,
                            width: "100%",
                          }}
                        ></div>

                        {/* Reserved Quantity Bar */}
                        <div
                          style={{
                            backgroundColor: "#ecff00",
                            height: `${(item.reserved / item.quantity) * 100}%`,
                            width: "100%",
                          }}
                        ></div>
                      </div>
                    </div>

                    {/* Text for RESERVE and AVAILABLE STOCK */}
                    <div style={{ marginLeft: "10px", textAlign: "left" }}>
                      {/* Text for RESERVE */}
                      <div
                        style={{
                          border: "4px solid black",
                          padding: "5px",
                          fontWeight: "700",
                          backgroundColor:
                            item.reserved > 0 ? "#ecff00" : "grey", // Grey if reserved is 0
                          color: "black",
                          marginBottom: "40px",
                          textAlign: "center", // Center text horizontally
                          display: "flex", // Flex container
                          justifyContent: "center", // Center content horizontally
                          alignItems: "center", // Center content vertically
                          cursor: "pointer", // Change cursor to pointer
                        }}
                        onClick={handleReserveClick} // Add this line
                      >
                        <p style={{ margin: 0 }}>
                          RESERVE: {item.reserved || 0}
                        </p>
                      </div>

                      <div
                        style={{
                          border: "4px solid black",
                          padding: "5px",
                          backgroundColor: "green",
                          fontWeight: "700",
                          color: "white", // Ensure the text is readable against the green background
                        }}
                      >
                        <p style={{ margin: 0 }}>
                          AVAILABLE STOCK:{" "}
                          {
                            item.reserved && item.reserved > 0
                              ? item.quantity - item.reserved
                              : item.quantity // Show full quantity if reserved is 0 or undefined
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1, textAlign: "center" }}>
                  {item.imageUrl && (
                    <img
                      src={formatBase64Image(item.imageUrl)}
                      alt="Item Image"
                      style={{
                        width: "200px",
                        maxHeight: "200px",
                        margin: "30px auto", // Center image horizontally
                        borderRadius: "10px", // Rounded corners for the image
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <button style={modalStyles.closeButton} onClick={onClose}>
              Close
            </button>

            {/* Conditional buttons based on archived and invalid status */}
            {item.archived &&
            !["both", "aintequal", "negative"].includes(item.invalid) ? (
              <button style={modalStyles.button} onClick={handleUnarchive}>
                Unarchive Item
              </button>
            ) : (
              <>
                {/* Show all buttons even if archived and invalid */}
                <button
                  style={modalStyles.button}
                  onClick={() => ViewHistory()}
                >
                  View History
                </button>
                <button
                  style={modalStyles.button}
                  onClick={() => setMode("restock")}
                >
                  Restock Item
                </button>
                <button
                  style={modalStyles.button}
                  onClick={() => setMode("price")}
                >
                  Update Item Details
                </button>
                {item.archived ? (
                  <button style={modalStyles.button} onClick={handleUnarchive}>
                    Unarchive Item
                  </button>
                ) : (
                  <button style={modalStyles.button} onClick={handleArchive}>
                    Archive Item
                  </button>
                )}
              </>
            )}
          </>
        )}

        {mode === "restock" && (
          <>
            <h2>Restock {item.name}</h2>
            <input
              type="number"
              placeholder="Additional Quantity"
              value={additionalQuantity}
              onChange={(e) => setAdditionalQuantity(Number(e.target.value))}
            />
            <button
              style={modalStyles.closeButton}
              onClick={() => setMode("details")}
            >
              Back to Details
            </button>

            <button
              style={modalStyles.button}
              onClick={handleRestock}
              disabled={isProcessing} // Disable while processing
            >
              Update Quantity
            </button>
          </>
        )}
        {mode === "price" && (
          <>
            <h2>Update Item Details {item.name}</h2>
            <div style={labelStyle}>Name:</div>
            <input
              type="text"
              placeholder="Name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              style={modalStyles.inputStyles}
            />
            <div style={labelStyle}>Description:</div>
            <textarea
              placeholder="Description"
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              style={modalStyles.inputStyles}
            />
            <div style={labelStyle}>Barcode:</div>
            <input
              type="text"
              placeholder="Barcode"
              value={newBarcode}
              onChange={(e) => setNewBarcode(e.target.value)}
              style={modalStyles.inputStyles}
            />
            <div style={labelStyle}>Category:</div>
            <select
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              style={modalStyles.inputStyles}
            >
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <div style={labelStyle}>Unit Price:</div>
            <input
              type="number"
              placeholder="New Unit Price"
              value={newUnitPrice}
              onChange={(e) => setNewUnitPrice(Number(e.target.value))}
              style={modalStyles.inputStyles}
            />
            <div style={labelStyle}>Quantity:</div>
            <input
              type="number"
              placeholder="Quantity"
              value={newQuantity}
              onChange={(e) => setNewQuantity(Number(e.target.value))}
              style={modalStyles.inputStyles}
            />

            <div style={labelStyle}>Priority:</div>
            <input
              type="number"
              placeholder="Priority (Lower number = Higher priority)"
              value={newPriority}
              onChange={(e) => setNewPriority(Number(e.target.value))}
              style={modalStyles.inputStyles}
            />

            <div style={labelStyle}>Image:</div>
            {newImage && (
              <img
                src={newImage}
                alt="Item Image"
                style={{
                  width: "30%",
                  maxHeight: "30%",
                  margin: 30,
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              />
            )}
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
              style={modalStyles.inputStyles}
            />
            <div
              style={{
                ...labelStyle,
                display: "flex",
                alignItems: "center",
                marginBottom: "40px",
              }}
            >
              <label>VAT ITEM:</label>
              <input
                type="checkbox"
                checked={newVat}
                onChange={(e) => setNewVat(e.target.checked)}
                style={{ marginRight: "10px" }}
              />
            </div>
            <button
              style={modalStyles.closeButton}
              onClick={() => setMode("details")}
            >
              Back to Details
            </button>
            <button
              style={modalStyles.button}
              onClick={handleUpdateDetails}
              disabled={isProcessing} // Disable while processing
            >
              Update Item
            </button>
          </>
        )}

        {mode === "history" && (
          <>
            <div style={modalStyles.fixedHeader}>
              <h2 style={modalStyles.headerText}>Item Details</h2>
              <div style={modalStyles.buttonContainer}>
                <button
                  style={modalStyles.closeButton}
                  onClick={() => setMode("details")}
                >
                  Back to Details
                </button>
                <button
                  style={modalStyles.closeButton}
                  onClick={handleToggleChange}
                >
                  {hideUnchangedUpdates
                    ? "Only showing stock actions"
                    : "Showing all actions"}
                </button>
                <button
                  style={modalStyles.closeButton}
                  onClick={() => setMode("stock_chart")}
                >
                  {mode === "history"
                    ? "Showing value mode"
                    : "Showing chart mode"}
                </button>
              </div>
            </div>
            {isLoadingHistory ? (
              <div className="spinner-large"></div>
            ) : (
              <>
                {historyData.length > 0 ? (
                  <div style={modalStyles.historyContainer}>
                    {historyData.map((historyEntry, index) => {
                      const prevEntry = historyData[index - 1] || {};

                      // Call the function to calculate all the values
                      const {
                        formattedDate,
                        action,
                        user,
                        reservedStock,
                        warehouseStock,
                        availableStock,
                        actionAmount,
                        store,
                        isLoading,
                        reservedColor,
                        warehouseColor,
                        availableColor,
                      } = calculateHistoryEntryDetails(
                        historyEntry,
                        prevEntry,
                        storeDetails,
                        loadingStores
                      );

                      if (
                        hideUnchangedUpdates &&
                        (historyEntry.actionType === "generate_invoice" ||
                          (historyEntry.actionType === "item_update" &&
                            historyEntry.details.newDetails?.quantity ===
                              historyEntry.details.prevDetails?.quantity))
                      ) {
                        return null; // Skip rendering this entry in the UI
                      }

                      return (
                        <div
                          key={index}
                          style={modalStyles.historyColumn}
                          data-storeid={user}
                        >
                          <div style={modalStyles.historyEntry}>
                            <div style={modalStyles.historyTop}>
                              <div style={modalStyles.historyLeft}>
                                <p style={modalStyles.historyAction}>
                                  <p>{formattedDate}</p>
                                  {action}
                                </p>
                              </div>

                              {[
                                {
                                  label: "Action",
                                  value: actionAmount,
                                  color: "purple",
                                },
                                {
                                  label: "Action",
                                  value: actionAmount,
                                  color: "purple",
                                },
                                {
                                  label: "Action",
                                  value: actionAmount,
                                  color: "purple",
                                },
                              ].map((item, i) => {
                                const { opacity, displayValue } =
                                  calculateActionOpacityAndDisplay(
                                    action,
                                    item,
                                    i
                                  );

                                return (
                                  <div
                                    style={{
                                      ...modalStyles.historyRight,
                                      opacity,
                                    }}
                                    key={item.label}
                                  >
                                    <div style={modalStyles.valueColumn}>
                                      <div
                                        style={{
                                          ...modalStyles.circle,
                                          backgroundColor: item.color,
                                        }}
                                      >
                                        {displayValue}
                                      </div>
                                      {i >= 0 && (
                                        <FaArrowDown
                                          style={modalStyles.arrow}
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            <div style={modalStyles.historyBottom}>
                              <div style={modalStyles.historyLeft}>
                                {user !== "admin" &&
                                  (isLoading ? (
                                    <p>Loading...</p>
                                  ) : store ? (
                                    <div
                                      style={{
                                        fontWeight: 800,
                                        fontSize: "15px",
                                        color: "#0056b3", // Primary color for the action text
                                        display: "grid", // Use grid layout
                                        gridTemplateColumns: "repeat(2, 1fr)", // Create two columns with equal width
                                        gap: "0px", // Space between grid items
                                      }}
                                    >
                                      <p style={modalStyles.historyUser}>
                                        Store: {store.name}
                                      </p>
                                      <p style={modalStyles.historyUser}>
                                        Address: {store.address}
                                      </p>
                                      <p style={modalStyles.historyUser}>
                                        Email: {store.email}
                                      </p>
                                      <p style={modalStyles.historyUser}>
                                        Number: {store.phoneNumber}
                                      </p>
                                    </div>
                                  ) : (
                                    <p>Empty...</p>
                                  ))}

                                <p
                                  style={{
                                    fontWeight: 800,
                                    fontSize: "14px",
                                    color: "#0056b3", // Primary color for the action text
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "15px",
                                  }}
                                >
                                  {action === "accept_invoice" && (
                                    <button
                                      style={modalStyles.invoiceButton}
                                      onClick={() =>
                                        handleInvoiceClick(
                                          historyEntry.details.invoiceId
                                        )
                                      }
                                    >
                                      {historyEntry.details.invoiceId}
                                    </button>
                                  )}
                                  User: {user}
                                </p>
                              </div>

                              {[
                                {
                                  label: "Reserved",
                                  value: reservedStock,
                                  color: reservedColor,
                                },
                                {
                                  label: "Available", // Swap label
                                  value: availableStock, // Swap value
                                  color: availableColor, // Swap color
                                },
                                {
                                  label: "Warehouse", // Swap label
                                  value: warehouseStock, // Swap value
                                  color: warehouseColor, // Swap color
                                },
                              ].map((item, i) => (
                                <div style={modalStyles.historyRight}>
                                  <div
                                    key={item.label}
                                    style={modalStyles.valueColumn}
                                  >
                                    <div
                                      style={{
                                        ...modalStyles.circle,
                                        backgroundColor: item.color,
                                      }}
                                    >
                                      {item.value}
                                    </div>
                                    <div style={modalStyles.historyLabel}>
                                      {item.label}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p>No history data available.</p>
                )}
              </>
            )}
          </>
        )}

        {mode === "stock_chart" && (
          <>
            <div style={modalStyles.fixedHeader}>
              <h2 style={modalStyles.headerText}>Item Details</h2>
              <div style={modalStyles.buttonContainer}>
                <button
                  style={modalStyles.closeButton}
                  onClick={() => setMode("details")}
                >
                  Back to Details
                </button>
                <button
                  style={modalStyles.closeButton}
                  onClick={handleToggleChange}
                >
                  {hideUnchangedUpdates
                    ? "Only showing stock actions"
                    : "Showing all actions"}
                </button>
                <button
                  style={modalStyles.closeButton}
                  onClick={() => setMode("history")}
                >
                  {mode === "history"
                    ? "Showing value mode"
                    : "Showing chart mode"}
                </button>
              </div>
            </div>
            {renderStockChart()}
            <button
              style={modalStyles.closeButton}
              onClick={() => setMode("details")}
            >
              Back to Details
            </button>
          </>
        )}
      </div>
      {invoiceModalOpen && (
        <InvoiceDetailsModal
          isOpen={invoiceModalOpen}
          onClose={() => setInvoiceModalOpen(false)}
          invoiceId={selectedInvoiceId} // Pass the selected invoice ID
        />
      )}

      {isCartModalOpen && selectedCart && (
        <CartAdminModal
          isOpen={isCartModalOpen}
          onClose={handleCloseCartModal}
          cartId={selectedCart.id}
        />
      )}
    </div>
  );
};

export default ItemDetailsModal;

const modalStyles = {
  reserveModalContent: {
    padding: "20px",
    maxWidth: "600px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  reserveModalTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  },
  cartCard: {
    display: "flex",
    //backgroundColor: "pink",
    //border: "1px solid #e0e0e0",
    borderRadius: "8px",
    overflow: "hidden",
    marginBottom: "15px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    transition: "box-shadow 0.3s ease",
    "&:hover": {
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
    },
  },
  leftSide: {
    width: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },
  rightSide: {
    flex: 1,
    padding: "15px",
  },
  storeName: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  },

  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1002, // Increase zIndex
  },

  fixedHeader: {
    position: "sticky", // Alternatively, use 'fixed' if you want it completely fixed relative to the viewport
    top: 0, // Sticks to the top
    backgroundColor: "white",
    padding: "10px 0",
    zIndex: 1000, // Ensure it's above other content
    display: "flex",
    justifyContent: "space-between", // Ensure buttons are spaced nicely
    alignItems: "center",
  } as CSSProperties,

  // Add this style to align buttons in a row
  buttonContainer: {
    display: "flex",
    gap: "10px",
  } as CSSProperties,

  historyEntry: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderTop: "4px solid black",
    borderLeft: "4px solid black",
    borderRight: "4px solid black",
    //backgroundColor: "orange",
    //padding: "15px",
  } as CSSProperties,

  historyTop: {
    display: "flex",
    //marginBottom: "10px", // Add some spacing between top and bottom sections
  } as CSSProperties,

  historyBottom: {
    display: "flex",
  } as CSSProperties,

  historyLeft: {
    flex: 1, // Take up half of the width
    marginLeft: "10px", // Add some spacing between left and right
    //backgroundColor: "pink",
  } as CSSProperties,

  historyRight: {
    flex: 0.3, // Take up the other half of the width
    //backgroundColor: "blue",
  } as CSSProperties,

  historyContainer: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    padding: "20px 0",
  } as CSSProperties,

  historyColumn: {
    minWidth: "250px",
    marginRight: "20px",
    //padding: "15px",
    //borderRadius: "8px",
    backgroundColor: "#f0f0f0",
  } as CSSProperties,

  valueColumn: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  } as CSSProperties,

  circle: {
    width: "40px",
    height: "40px",
    //borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontWeight: "bold",
    marginBottom: "5px",
  } as CSSProperties,

  arrow: {
    color: "#333",
    marginTop: "5px",
    marginBottom: "5px",
  } as CSSProperties,

  invoiceButton: {
    fontSize: "12px",
    marginLeft: "10px",
    backgroundColor: "#007bff",
    color: "white",
    borderRadius: "5px",
    padding: "5px 10px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: "#0056b3",
    },
  } as CSSProperties,

  historyRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0", // Add padding for each row
  } as CSSProperties,

  actionDateContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline", // Align items to baseline for better vertical alignment
    fontSize: "14px", // Slightly smaller text size
  } as CSSProperties,

  historyUser: {
    fontSize: "14px", // Slightly smaller text size
  } as CSSProperties,

  historyLabel: {
    fontWeight: "bold",
    padding: "2px 5px",
    borderRadius: "5px",
    color: "black",
  } as CSSProperties,

  circleContainer: {
    display: "flex",
    alignItems: "center",
  } as CSSProperties,

  diff: {
    fontSize: "14px",
    fontWeight: "bold",
    marginRight: "5px", // Add space between the diff and circle
  } as CSSProperties,

  // Other styles...

  detailsHeading: {
    fontSize: "20px", // Increase font size
    fontWeight: 700,
    marginBottom: "5px", // Add some margin below
  } as CSSProperties,

  // Style for the Action text
  historyAction: {
    fontWeight: 800,
    fontSize: "18px",
    color: "#0056b3", // Primary color for the action text
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    //marginBottom: "4px", // Space below the action text
  } as CSSProperties,

  // Style for the Details text
  historyDetails: {
    fontSize: "14px",
    lineHeight: "1.4", // Line height for better readability
    color: "#333", // Dark color for detai#ls text
    backgroundColor: "#fff", // White background for the details section
    padding: "10px",
    //borderRadius: "6px", // Rounded corners for the details box
    border: "1px solid #dedede", // Border for the details box
  } as CSSProperties,

  closeButton: {
    fontSize: "14px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 12px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
    "&:hover": {
      backgroundColor: "#0056b3", // Darker shade on hover
    },
  } as CSSProperties,
  button: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
    "&:hover": {
      backgroundColor: "#0056b3", // Darker shade on hover
    },
  } as CSSProperties,

  inputStyles: {
    borderRadius: "12px", // Softer edges
    border: "1px solid #ced4da", // Lighter border color
    padding: "15px", // Increased padding
    marginTop: "12px", // Consistent margin top
    marginBottom: "12px", // Consistent margin bottom
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Subtle inner shadow for depth
    width: "calc(100% - 30px)", // Adjust width to account for padding
    fontSize: "14px", // Slightly larger font size for readability
  } as CSSProperties,
  headerText: {
    fontSize: "18px", // Larger font size for headers
    fontWeight: "bold", // Bold font weight for emphasis
    color: "#333", // Dark color for text
    textAlign: "center", // Center-align the text
    margin: "10px 0", // Margin for spacing above and below the text
    padding: "5px", // Optional padding
    marginBottom: 30,
    borderBottom: "1px solid #ddd", // Optional bottom border for a subtle line separator
  } as CSSProperties,
  centeredMessage: {
    display: "flex",
    fontSize: 30,
    fontWeight: "700",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100%", // Take full height of the container
    textAlign: "center", // Center text alignment
  } as CSSProperties,
  vehicleIcon: {
    width: "110px", // Set both width and height to the same value for a square shape
    height: "70px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "30%", // Circular shape
    objectFit: "cover",
    paddingLeft: 20,
    paddingRight: 20,
  } as CSSProperties,
  profileIcon: {
    width: "100px", // Set both width and height to the same value for a square shape
    height: "100px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "20%", // Circular shape
    marginBottom: "10px",
    objectFit: "cover",
  } as CSSProperties,
  additionalContentContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,

  providerDetailRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0", // Add padding for each row if needed
    // Add any additional styling you need for each detail row
  } as CSSProperties,

  barContainer: {
    //width: '60%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: 40,
    border: "1px solid #000000",
    borderRadius: 10,
    //backgroundColor:'pink'
  } as CSSProperties,
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "33.3333%", // Divide the bar container into three equal parts
  } as CSSProperties,

  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  } as CSSProperties, // Assert the entire object as CSSProperties
  container: {
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "720px", // Set a max width for larger screens
    width: "90%", // Responsive width
    maxHeight: "85vh",
    overflowY: "auto",
  } as CSSProperties,
  contentStyles: {
    // Define your styles here
    marginBottom: "10px",
  } as CSSProperties,

  /*

   leftSide: {
    width: "50%",
    marginRight: 30,
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,

    border: "1px solid #000000",
  } as CSSProperties,
  rightSide: {
    width: "50%",
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    border: "1px solid #000000",
  } as CSSProperties,


  historyEntry: {
    fontSize: "22px", // Increase font size
    fontWeight: 800,
    padding: "15px",
    margin: "10px 0",
    backgroundColor: "#f7f7f7", // Light grey background for each entry
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
    display: "flex",
    flexDirection: "column", // Stack children vertically
    gap: "5px", // Space between children
  } as CSSProperties,



  detailsHeading: {
    fontSize: "20px", // Increase font size
    fontWeight: 700,
    marginBottom: "5px", // Add some margin below
  } as CSSProperties,

  historyEntry: {
    fontSize: "22px", // Increase font size
    fontWeight: 800,
    padding: "15px",
    margin: "10px 0",
    backgroundColor: "#f7f7f7", // Light grey background for each entry
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
    display: "flex",
    flexDirection: "column", // Stack children vertically
    gap: "5px", // Space between children
  } as CSSProperties,

  circle: {
    display: "inline-block",
    width: "30px",
    height: "30px",
    lineHeight: "30px",
    borderRadius: "50%",
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    marginLeft: "5px", // Add space between the diff and circle
  } as CSSProperties,

  historyLeft: {
    flex: 0.7,
    paddingRight: "10px", // Add padding to the right
  } as CSSProperties,
  
  historyRight: {
    flex: 1,
    display: "flex",
    justifyContent: "space-around", // Center items horizontally
    textAlign: "center", // Center align the text
    fontSize: "12px", // Smaller text size
    paddingLeft: "10px", // Add padding to the left
  } as CSSProperties,
  // Style for the Action text
  historyAction: {
    fontWeight: 800,
    color: "#0056b3", // Primary color for the action text
    marginBottom: "4px", // Space below the action text
  } as CSSProperties,
  */
};
