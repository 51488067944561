import React, { CSSProperties, useState } from 'react';
import { db } from '../App'; // Assuming the db instance is correctly initialized in App.js
import { addDoc, collection } from 'firebase/firestore';

interface CategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CategoryModal: React.FC<CategoryModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [categoryName, setCategoryName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setErrorMessage('');

    const trimmedCategoryName = categoryName.trim();  // Trim the category name
    if (!trimmedCategoryName || trimmedCategoryName.length <= 3) {
        setErrorMessage('Category name must be more than 3 characters.'); // Set error message
        return;
    }

    try {
      const docRef = await addDoc(collection(db, 'Categories'), {
        Category: trimmedCategoryName,  // Use the trimmed category name
      });
      console.log('Category added with ID: ', docRef.id);
      onClose(); // Close the modal on success
    } catch (error) {
      console.error('Error adding category: ', error);
      alert('Error adding category. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <h1>Add Category</h1>
        {errorMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}
        <input
          type="text"
          placeholder="Category Name"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          style={modalStyles.inputStyles}
        />
        <div style={modalStyles.buttonsContainer}>
          <button style={modalStyles.closeButton} onClick={onClose}>
            Close
          </button>
          <button style={modalStyles.button} onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

// Assuming modalStyles is defined elsewhere in your code as provided in your initial example.
// You can reuse the same styling object for consistency.



// Reuse and adapt the modalStyles from the previous example for consistency
const modalStyles: { [key: string]: CSSProperties } = {

  
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  },
  container: {
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "400px", // Set a max width for larger screens
    width: "90%", // Responsive width
    maxHeight: "85vh",
    overflowY: "auto",
  },
  inputStyles: {
    borderRadius: "12px", // Softer edges
    border: "1px solid #ced4da", // Lighter border color
    padding: "15px", // Increased padding
    marginTop: "12px", // Consistent margin top
    marginBottom: "12px", // Consistent margin bottom
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Subtle inner shadow for depth
    width: "calc(100% - 30px)", // Adjust width to account for padding
    fontSize: "14px", // Slightly larger font size for readability
  },
  closeButton: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
    "&:hover": {
      backgroundColor: "#0056b3", // Darker shade on hover
    },
  } as CSSProperties,
  button: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
   
  },
  addButton: {
    fontSize: "16px",
    fontWeight: "600",
    backgroundColor: "#28a745", // Success color, similar to Bootstrap's .btn-success
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
   
  },
};


export default CategoryModal;
