//@ts-nocheck
import React, { useState } from "react";
import { db } from "../App";
import { doc, updateDoc, addDoc, collection } from "firebase/firestore";

interface ProspectDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  prospect: {
    id: string;
    name: string;
    address: string;
    email: string;
    password: string;
    phoneNumber: string;
    archived: boolean;
  };
}

const ProspectDetailsModal: React.FC<ProspectDetailsModalProps> = ({
  isOpen,
  onClose,
  prospect,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(prospect.phoneNumber);
  const [name, setName] = useState(prospect.name); // Add state for name
  const [address, setAddress] = useState(prospect.address); // Add state for address
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  if (!isOpen || !prospect) return null;

  const handleUpdateStoreDetails = async () => {
    if (!prospect.id) {
      alert("Prospect details are missing.");
      return;
    }

    const prospectRef = doc(db, "Prospects", prospect.id);

    setIsUpdating(true); // Set updating state to true while performing the update

    try {
      // Update the store details
      await updateDoc(prospectRef, {
        name: name.trim(),
        address: address.trim(),
        phoneNumber: phoneNumber.trim(),
      });

      // Log the update to prospectLogs
      await addDoc(collection(db, "prospectLogs"), {
        prospectId: prospect.id,
        timestamp: new Date(),
        updatedFields: {
          name: name.trim(),
          address: address.trim(),
          phoneNumber: phoneNumber.trim(),
        },
        updatedBy: "Admin", // Replace with the current user if available
      });

      alert(`Successfully updated store details for ${prospect.name}.`);
    } catch (error) {
      console.error("Error updating store details: ", error);
      alert("Failed to update store details.");
    } finally {
      setIsUpdating(false); // Set updating state back to false after the update
    }
  };

  const handleArchiveToggle = async () => {
    if (!prospect.id) {
      alert("Prospect details are missing.");
      return;
    }

    const prospectRef = doc(db, "Prospects", prospect.id);
    try {
      await updateDoc(prospectRef, {
        archived: !prospect.archived, // Toggle the 'archived' field
      });
      alert(
        `Successfully ${prospect.archived ? "unarchived" : "archived"} ${
          prospect.name
        }.`
      );
      onClose(); // Close the modal after archiving/unarchiving
    } catch (error) {
      console.error(
        `Error ${prospect.archived ? "unarchiving" : "archiving"} prospect: `,
        error
      );
      alert(
        `Failed to ${prospect.archived ? "unarchive" : "archive"} prospect.`
      );
    }
  };

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <h2>Prospect Details</h2>
        <p>
          Name:{" "}
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Update store name"
          />
        </p>
        <p>
          Address:{" "}
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Update store address"
          />
        </p>
        <p>
          Phone Number:{" "}
          <input
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Update phone number"
          />
        </p>
        <p>Email: {prospect.email}</p>

        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

        <div style={modalStyles.buttonContainer}>
          <button
            style={modalStyles.buttonStyle}
            onClick={handleUpdateStoreDetails}
            disabled={isUpdating}
          >
            {isUpdating ? "Updating..." : "Update Store Details"}
          </button>
          <button style={modalStyles.buttonStyle} onClick={handleArchiveToggle}>
            {prospect.archived ? "Unarchive" : "Archive"} Prospect
          </button>
          <button style={modalStyles.buttonStyle} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const modalStyles = {
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  container: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxWidth: "500px",
    width: "80%",
    maxHeight: "90%",
    overflowY: "auto",
  },
  buttonStyle: {
    padding: "10px 20px",
    margin: "5px",
    cursor: "pointer",
    borderRadius: "5px",
    border: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

export default ProspectDetailsModal;
