//@ts-nocheck
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { db } from "../App";

import {
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { formatEpochDate, formatEpochTime } from "./Functions";
import AddProspectModal from "./AddProspectModal";
import ProspectDetailsModal from "./ProspectDetailsModal";
import { FaStore } from "react-icons/fa"; // Import store icon

interface Prospect {
  id: string;
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
  archived?: boolean;
  // Add other relevant fields if necessary
}

const Prospects: React.FC = () => {
  const [prospects, setProspects] = useState<Prospect[]>([]);
  const [isAdding, setIsAdding] = useState(false); // To control the form/modal visibility
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [selectedProspect, setSelectedProspect] = useState<Prospect | null>(
    null
  );
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false); // To toggle between active and archived
  const [hoveredCard, setHoveredCard] = useState<string | null>(null); // For hover effects

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setIsLoading(true); // Set loading to true when component mounts
    const unsubscribe = onSnapshot(collection(db, "Prospects"), (snapshot) => {
      const prospectsList: Prospect[] = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Prospect[];
      setProspects(prospectsList);
      setIsLoading(false); // Set loading to false after prospects are fetched
    });

    return () => unsubscribe();
  }, []);

  const openDetailsModal = (prospect: Prospect) => {
    setSelectedProspect(prospect);
    setIsDetailsModalOpen(true);
  };

  const gridContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(340px, 1fr))", // Responsive grid layout
    gap: "60px 20px", // Space between grid items, "vertical horizontal"
    padding: "20px", // Padding around the entire grid
  };

  const prospectCardStyle: React.CSSProperties = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
    display: "flex",
    cursor: "pointer", // Indicate that the items are clickable
    height: "100%", // Ensure all cards are of equal height
    textDecoration: "none",
    color: "inherit",
    transition: "transform 0.2s, box-shadow 0.2s", // Smooth transition for hover effects
  };

  const prospectCardHoverStyle: React.CSSProperties = {
    transform: "translateY(-5px)",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
  };

  const leftSideStyle: React.CSSProperties = {
    width: "60px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    flexShrink: 0,
  };

  const rightSideStyle: React.CSSProperties = {
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
  };

  const addButtonStyle: React.CSSProperties = {
    backgroundColor: "#1c345d", // Example blue color
    color: "white",
    padding: "10px 20px",
    margin: "10px",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const scrollableContainerStyle: React.CSSProperties = {
    maxHeight: "75vh", // Adjust the height as needed
    overflowY: "auto", // Enable vertical scrolling
    padding: "0 20px", // Optional: padding for better aesthetics
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px",
          marginLeft: 40,
        }}
      >
        <div style={{ flexDirection: "row", display: "flex" }}>
          <h1>Prospects</h1>

          <input
            type="text"
            placeholder="Search prospects..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              padding: "10px",
              margin: "20px",
              borderRadius: "5px",
              fontSize: "16px",
            }}
          />
        </div>
        <div>
          <button style={addButtonStyle} onClick={() => setIsAdding(true)}>
            Add Prospect
          </button>
          <button
            style={addButtonStyle}
            onClick={() => setShowArchived((prev) => !prev)}
          >
            {showArchived ? "Show Active" : "Show Archived"}
          </button>
        </div>
      </div>
      <div style={scrollableContainerStyle}>
        <div style={gridContainerStyle}>
          {isLoading ? (
            <p>Loading...</p>
          ) : prospects.length > 0 ? (
            prospects
              .filter((prospect) =>
                showArchived
                  ? prospect.archived === true
                  : prospect.archived === false ||
                    prospect.archived === undefined
              )
              .filter(
                (prospect) =>
                  prospect.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  prospect.address
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  prospect.email
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
              )
              .map((prospect) => {
                const isArchived = prospect.archived === true;
                const leftBackgroundColor = isArchived ? "#A9A9A9" : "#1c345d"; // Gray if archived, else blue
                const isHovered = hoveredCard === prospect.id;

                return (
                  <div
                    key={prospect.id}
                    style={{
                      ...prospectCardStyle,
                      ...(isHovered ? prospectCardHoverStyle : {}),
                    }}
                    onClick={() => openDetailsModal(prospect)}
                    onMouseEnter={() => setHoveredCard(prospect.id)}
                    onMouseLeave={() => setHoveredCard(null)}
                  >
                    <div
                      style={{
                        ...leftSideStyle,
                        backgroundColor: leftBackgroundColor,
                      }}
                    >
                      <FaStore size={24} />
                    </div>
                    <div style={rightSideStyle}>
                      <div>
                        <h3>Store: {prospect.name}</h3>
                        <p>Address: {prospect.address}</p>
                        <p>Email: {prospect.email}</p>
                        <p>Number: {prospect.phoneNumber}</p>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <p>No prospects found.</p>
          )}
        </div>
      </div>

      {isAdding && (
        <AddProspectModal
          isOpen={isAdding}
          onClose={() => setIsAdding(false)}
        />
      )}
      {isDetailsModalOpen && selectedProspect && (
        <ProspectDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          prospect={selectedProspect}
        />
      )}
    </div>
  );
};

export default Prospects;
