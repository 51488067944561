// DispatchModal.tsx
import './DispatchModal.css'; // Import the CSS file

import React, { useState } from 'react';

interface DispatchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DispatchModal: React.FC<DispatchModalProps> = ({ isOpen, onClose }) => {
  const [modalContent, setModalContent] = useState(""); // State for modal content

  const handleSave = () => {
    // Implement your logic to save changes
    // Once changes are saved, close the modal
    onClose();
  };

  return (
    <div className={`dispatch-modal ${isOpen ? 'open' : ''}`}>
      {/* Apply CSS styles to cover the entire screen */}
      <div className={`modal-overlay ${isOpen ? 'open-overlay' : ''}`} onClick={onClose}></div>
      <div className={`modal-content ${isOpen ? 'open-content' : ''}`}>
        <h2>Select an option</h2>
        <ul>
          {/* Add your modal content and form inputs here */}
          <li>
            <input
              type="text"
              value={modalContent}
              onChange={(e) => setModalContent(e.target.value)}
            />
          </li>
        </ul>
        <button onClick={handleSave}>Save</button>
      </div>
    </div>
  );
};

export default DispatchModal;
