// ZipCodeSelector.tsx
import React, { useState, useEffect } from 'react';
import { SERVER_URL, UserData, ZipCode } from './Types';
import NewZipCodeModal from './NewZipCodeModal';

import './ZipCodeSelector.css';
import AssignZipCode from './AssignZipCode';

interface ZipCodeSelectorProps {
    onClose: () => void;
    userData: UserData; 
    updateAssignments: (user: UserData) => void;
    closeSelector: () => void;
  }

const ZipCodeSelector: React.FC<ZipCodeSelectorProps> = ({ onClose,userData,updateAssignments,closeSelector }) => {
    const [zipCodes, setZipCodes] = useState<ZipCode[]>([]); // Specify the type here
    const [isNewZipCodeModalOpen, setIsNewZipCodeModalOpen] = useState(false);
    const [selectedZipCode, setSelectedZipCode] = useState<string | null>(null); // Track the selected ZipCode
    const [isLoadingS, setIsLoadingS] = useState(false); 
    //const [isAssigned, setIsAssigned] = useState(false); // Initialize isAssigned as false


    const fetchZipCodes = async () => {
        console.log('Fetching ZipCodes...')

      try {
        const response = await fetch(`${SERVER_URL}/api/zipcodes`); // Adjust the API endpoint
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setZipCodes(data);

      } catch (error) {
        console.error('Error fetching ZipCodes:', error);
      }
    };

    useEffect(() => {

        fetchZipCodes();
      }, []);
      
      const closeSelectorAndGoBack = () => {
        closeSelector(); // Call the closeSelector callback
        // You can also add any additional logic you need before closing
      };

  const openNewZipCodeModal = () => {
    updateAssignments(userData);
    setIsNewZipCodeModalOpen(true);
  };

  const closeNewZipCodeModal = () => {
    updateAssignments(userData);
    setIsNewZipCodeModalOpen(false);
    //fetchZipCodes();
  };
  const selectZipCode = (zipCode: string) => {
    setIsLoadingS(true);
    setSelectedZipCode(zipCode);
    fetchZipCodes();
    updateAssignments(userData);
    setIsLoadingS(false);
  };

  const isOpen = true;
  return (
    <div >
      {isNewZipCodeModalOpen ? (
        // Render NewZipCodeModal when it's open
        <NewZipCodeModal updateAssignments={updateAssignments} isOpen={isNewZipCodeModalOpen} onClose={closeNewZipCodeModal} />
      ) : selectedZipCode ? ( // If a ZipCode is selected, render AssignZipCode component
        <AssignZipCode fetchZipCodes={fetchZipCodes} updateAssignments={updateAssignments} userData={userData} zipCode={selectedZipCode} onClose={() => setSelectedZipCode(null)} />
      ) : (
        // Render existing ZipCodes when NewZipCodeModal is closed
        <div className={`dispatch-modal ${isOpen ? 'open' : ''}`}>
          <div className={`modal-overlay ${isOpen ? 'open-overlay' : ''}`} onClick={onClose}></div>
          <div className={`modal-content ${isOpen ? 'open-content' : ''}`}>
            <div style={{width:300,textAlign:'center'}}>
          <h3 style={{marginTop:35}}>Existing ZipCodes</h3>
        <div>
          {zipCodes.map((zipCode) => {
            //@ts-ignore
            const isAssigned = userData.assignments.some(
              (assignment) => assignment.ZipCode === zipCode.zipCode
            );

            return (
              <div style={{marginLeft:40,marginRight:40,border: '1px solid #ccc',borderRadius: '5px',padding:20,marginBottom:20}} key={zipCode.id}>
                {zipCode.zipCode}{'   '}
                {isAssigned ? (
                  <button disabled>Assigned</button>
                ) : (
                  <button onClick={() => selectZipCode(zipCode.zipCode)}>Assign</button>
                )}
              </div>
            );
          })}
        </div>
        </div>
        <div style={{textAlign:'center',paddingTop:20,paddingBottom:40}}>
            <button onClick={openNewZipCodeModal}>Add New ZipCode</button>
            </div>
            <div style={{ textAlign: 'center' }}>
              {/* Back button to go back */}
              <button onClick={closeSelectorAndGoBack}>Back</button>
            </div>

          </div>
        </div>
      )}
      
    </div>
  );
};

export default ZipCodeSelector;
