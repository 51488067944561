// WarehouseRow.tsx

import React from "react";
import { formatBase64Image } from "./Functions";
import { FaExclamationTriangle, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const WarehouseRow: React.FC<{
  entry: any;
  openDetailsModal: (item: any) => void; // Added the openDetailsModal prop back
  showInvalidEntries: boolean; // Add the showInvalidEntries prop
}> = ({ entry, openDetailsModal, showInvalidEntries }) => {
  const displayReserved = entry.reserved ?? 0;
  const netQuantity = entry.quantity;

  // Define stock level thresholds
  const CRITICAL_STOCK_THRESHOLD = 5;
  const LOW_STOCK_THRESHOLD = 10;

  // Function to get stock level color
  const getStockLevelColor = (quantity: number): string => {
    if (quantity <= CRITICAL_STOCK_THRESHOLD) {
      return "red";
    } else if (quantity <= LOW_STOCK_THRESHOLD) {
      return "orange";
    } else {
      return "green";
    }
  };

  const displayBoxQuantity =
    entry.boxQuantity !== null ? entry.boxQuantity : "N/A";

  const columnWidths = {
    image: "10%",
    barcode: "12%",
    name: "11%",
    category: "11%",
    description: "12%",
    quantity: "11%",
    reserved: "11%",
    unitPrice: "11%",
    archived: "11%",
  };

  const scrollableListContainerStyles: React.CSSProperties = {
    flex: 0.9,
    overflowY: "auto",
    backgroundColor: '#dadada',
    fontWeight: '600',
  };

  // Function to get row styles based on entry properties
  const getRowStyles = (entry: any): React.CSSProperties => ({
    display: "flex",
    flexDirection: "row",
    fontSize: 20,
    alignItems: "center",
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor:
      netQuantity <= CRITICAL_STOCK_THRESHOLD
        ? "#ffe6e6" // Light red for critical stock
        : netQuantity <= LOW_STOCK_THRESHOLD
        ? "#fff5e6" // Light orange for low stock
        : "white",
    border: showInvalidEntries
      ? '4px solid blue' // Purple border when invalid entries are shown
      : entry.archived
      ? '1px solid red' // Red border for archived
      : undefined,
    cursor: 'pointer', // Add pointer cursor to indicate clickability
  });


  const columnStyles = (width: string): React.CSSProperties => ({
    flexBasis: width,
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    fontSize: "14px",
    color: "black",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    overflow: "hidden",
  });

  // Additional styles for text columns to handle long words
  const textColumnStyles: React.CSSProperties = {
    wordBreak: "break-word",
    textAlign: "center",
  };

  return (
    <div style={scrollableListContainerStyles}>
      <li
        key={entry.id}
        style={getRowStyles(entry)}
        onClick={() => openDetailsModal(entry)} // Make the entire row clickable
      >
        {entry.imageUrl && (
          <div style={columnStyles(columnWidths.image)}>
            <img
              src={formatBase64Image(entry.imageUrl)}
              alt="Product Image"
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                border: "3px solid #007ae4",
                marginLeft: "20px",
                objectFit: "cover",
              }}
            />
          </div>
        )}

        <div style={columnStyles(columnWidths.barcode)}>
          <span style={textColumnStyles}>{entry.barcode}</span>
        </div>
        <div style={columnStyles(columnWidths.name)}>
          <span style={textColumnStyles}>{entry.name}</span>
        </div>
        <div style={columnStyles(columnWidths.category)}>
          <span style={textColumnStyles}>{entry.category}</span>
        </div>
        <div style={columnStyles(columnWidths.description)}>
          <span
            style={textColumnStyles}
            title={entry.description}
          >
            {entry.description}
          </span>
        </div>
        <div style={columnStyles(columnWidths.quantity)}>
          {netQuantity <= CRITICAL_STOCK_THRESHOLD ? (
            <FaExclamationTriangle style={{ color: 'red', marginRight: 5 }} />
          ) : netQuantity <= LOW_STOCK_THRESHOLD ? (
            <FaExclamationTriangle style={{ color: 'orange', marginRight: 5 }} />
          ) : (
            <FaCheckCircle style={{ color: 'green', marginRight: 5 }} />
          )}
          <span style={{ color: getStockLevelColor(netQuantity) }}>
            {netQuantity}
          </span>
        </div>

        <div style={columnStyles(columnWidths.reserved)}>
          <span>{displayReserved}</span>
        </div>

        <div style={columnStyles(columnWidths.unitPrice)}>
          <span>£{entry.unitPrice}</span>
        </div>

        {/* Archived status with color and icon */}
        <div style={columnStyles(columnWidths.archived)}>
          {entry.archived ? (
            <>
              <FaTimesCircle style={{ color: 'red', marginRight: 5 }} />
              <span style={{ color: 'red' }}>Archived</span>
            </>
          ) : (
            <>
              <FaCheckCircle style={{ color: 'green', marginRight: 5 }} />
              <span style={{ color: 'green' }}>Active</span>
            </>
          )}
        </div>
      </li>
    </div>
  );
};

export default WarehouseRow;
