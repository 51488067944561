import React, { useEffect, useState } from "react";
import { CSSProperties } from "react";
import { getAuth } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../App"; // Ensure this points to your Firebase configuration
import InvoiceDetailsModal from "./InvoiceDetailsModal"; // Import the InvoiceDetailsModal component

const ProfileModal = ({ isOpen, onClose }: any) => {
  const [invoices, setInvoices] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);
  const auth = getAuth();
  const currentUserUid = auth.currentUser?.uid; // Get the current user's UID

  useEffect(() => {
    if (!currentUserUid) return;

    const fetchInvoices = async () => {
      try {
        const invoicesRef = collection(db, 'Invoices');
        const q = query(invoicesRef, where('storeId', '==', currentUserUid));
        const querySnapshot = await getDocs(q);
        const invoicesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInvoices(invoicesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching invoices:', error);
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [currentUserUid]);

  const handleDetailsClick = (invoice: any) => {
    setSelectedInvoice(invoice);
    setDetailsModalOpen(true);
  };

  if (!isOpen) return null;

  return (
    <>
      <div style={modalStyles.background}>
        <div style={modalStyles.container}>
          <h2>Profile Information</h2>
          {loading ? (
            <p>Loading...</p>
          ) : (
            invoices.length > 0 ? (
              <table style={modalStyles.table}>
                <thead>
                  <tr>
                    <th style={modalStyles.th}>Invoice ID</th>
                    <th style={modalStyles.th}>Number of Items</th>
                    <th style={modalStyles.th}>Total Price</th>
                    <th style={modalStyles.th}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map(invoice => (
                    <tr key={invoice.id}>
                      <td style={modalStyles.td}>{invoice.id}</td>
                      <td style={modalStyles.td}>{invoice.items.length}</td>
                      <td style={modalStyles.td}>{invoice.totalPrice.toFixed(2)}</td>
                      <td style={modalStyles.td}>
                        <button
                          style={modalStyles.detailsButton}
                          onClick={() => handleDetailsClick(invoice)}
                        >
                          Invoice Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No invoices found for this store.</p>
            )
          )}
          <button style={modalStyles.closeButton} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
      {selectedInvoice && (
        <InvoiceDetailsModal
          isOpen={detailsModalOpen}
          onClose={() => setDetailsModalOpen(false)}
          invoice={selectedInvoice}
        />
      )}
    </>
  );
};

export default ProfileModal;

const modalStyles = {
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  } as CSSProperties,
  container: {
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "600px",
    width: "90%",
  } as CSSProperties,
  closeButton: {
    fontSize: "16px",
    marginTop: "20px",
    fontWeight: "600",
    backgroundColor: "#007bff",
    color: "white",
    borderRadius: "5px",
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
  } as CSSProperties,
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  } as CSSProperties,
  th: {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
  } as CSSProperties,
  td: {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
  } as CSSProperties,
  detailsButton: {
    fontSize: "14px",
    backgroundColor: "#007bff",
    color: "white",
    borderRadius: "5px",
    padding: "5px 10px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
  } as CSSProperties,
};
