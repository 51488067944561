import { addDoc, collection, doc, getDocs, setDoc } from "firebase/firestore";
import React, { CSSProperties, useEffect, useState } from "react";
import { db } from "../App";
import { processHistoryLogClient } from "./Functions";
import defaultImage from "../place.png"; // Adjust this path to your default image

interface ParsePDFModalProps {
  isOpen: boolean;
  onClose: () => void;
  parsedData: { image: string; json: any }[]; // Adjusted the type of json to any to allow flexibility
}

const ParsePDFModal: React.FC<ParsePDFModalProps> = ({
  isOpen,
  onClose,
  parsedData,
}) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [globalCategory, setGlobalCategory] = useState<string>("");
  const [categories, setCategories] = useState<any[]>([]);
  const [editableData, setEditableData] = useState<{ [key: string]: any }>({});
  const [globalToggle, setGlobalToggle] = useState(false); //global barcode
  const [barcodeDisabled, setBarcodeDisabled] = useState<{
    [key: number]: boolean;
  }>({});

  const [vatSelection, setVatSelection] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [globalVat, setGlobalVat] = useState<boolean>(false);

  const [entering, setEntering] = useState<boolean>(false); // State variable to track if items are being entered into the warehouse

  const [remainingParsedData, setRemainingParsedData] = useState<
    { image: string; json: any }[]
  >([]);

  const getBase64FromImageUrl = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);
        resolve(canvas.toDataURL());
      };
      img.onerror = reject;
    });
  };

  const handleDeleteItem = (index: number) => {
    // Remove the item from remainingParsedData
    setRemainingParsedData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });

    // Remove the item from editableData
    setEditableData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[index];
      // Shift remaining items
      for (let i = index; i < Object.keys(updatedData).length; i++) {
        updatedData[i] = updatedData[i + 1];
      }
      delete updatedData[Object.keys(updatedData).length - 1];
      return updatedData;
    });

    // Remove the item from selectedCategories
    setSelectedCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      updatedCategories.splice(index, 1);
      return updatedCategories;
    });
  };

  const handleCheckboxChange = (index: number) => {
    setBarcodeDisabled((prev) => ({ ...prev, [index]: !prev[index] }));
    if (barcodeDisabled[index]) {
      // Clear the barcode when the checkbox is checked to disable the field
      handleEditableChange(index, "barcode", "");
    }
  };

  const handleGlobalToggle = () => {
    const newToggleState = !globalToggle;
    setGlobalToggle(newToggleState);
    setBarcodeDisabled(
      Object.fromEntries(
        new Array(remainingParsedData.length)
          .fill(null)
          .map((_, i) => [i, newToggleState])
      )
    );

    // Initialize editableData object if it doesn't exist
    const updatedEditableData = { ...editableData };
    remainingParsedData.forEach((_, index) => {
      if (!updatedEditableData[index]) {
        updatedEditableData[index] = {};
      }
      updatedEditableData[index].barcode = "";
    });
    setEditableData(updatedEditableData);
  };

  const handleClose = () => {
    // Reset the local states here
    setGlobalCategory("");
    setSelectedCategories([]);
    setEditableData({});
    setRemainingParsedData([]);

    // Call the parent's onClose handler
    onClose();
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, "Categories"));
      const fetchedCategories: any[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().Category,
      }));
      setCategories(fetchedCategories);
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (globalCategory && parsedData && Array.isArray(parsedData)) {
      setSelectedCategories(Array(parsedData.length).fill(globalCategory));
    }
  }, [globalCategory, parsedData]);

  useEffect(() => {
    if (parsedData) {
      const initialEditableData = parsedData.reduce((acc, item, index) => {
        //@ts-ignore
        acc[index] = item.json || item; // Adjust to handle both PDF and Excel formats
        return acc;
      }, {});
      setEditableData(initialEditableData);
      setRemainingParsedData(parsedData); // Initialize remainingParsedData with parsedData
    }
  }, [parsedData]);

  const handleDeleteImage = (index: number) => {
    setRemainingParsedData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };

  const handleCategoryChange = (index: number, category: string) => {
    const updatedCategories = [...selectedCategories];
    updatedCategories[index] = category;
    setSelectedCategories(updatedCategories);
  };

  const handleEditableChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedData = { ...editableData };

    // Initialize the object at index if it doesn't exist
    if (!updatedData[index]) {
      updatedData[index] = {};
    }

    // Apply trimming for name and barcode fields
    if (field === "name" || field === "barcode") {
      updatedData[index][field] = value.trim();
    } else {
      updatedData[index][field] = value;
    }

    setEditableData(updatedData);
  };

  const validateBarcode = (barcode: any) => {
    const maxLength = 128; // Set a reasonable max length
    const invalidCharsRegex = /[\/#%.\s]/; // Regex to detect invalid characters

    if (!barcode || barcode.length > maxLength) {
      return "Barcode is too long.";
    }

    if (invalidCharsRegex.test(barcode)) {
      return "Barcode contains invalid characters.";
    }

    return ""; // Return an empty string if no errors are found
  };

  const processParsedItems = async () => {
    setEntering(true);

    // Filter out entries using the default ID and collect their barcodes
    const newBarcodes = remainingParsedData
      .map((_, i) => editableData[i]?.barcode)
      .filter((barcode, index) => barcode && !barcodeDisabled[index]);

    // Check for duplicates among the new custom barcodes
    const hasDuplicateInNew = newBarcodes.some(
      (barcode, index) => newBarcodes.indexOf(barcode) !== index
    );
    if (hasDuplicateInNew) {
      const duplicateBarcode = newBarcodes.find(
        (barcode, index) => newBarcodes.indexOf(barcode) !== index
      );
      alert(`Duplicate custom barcode found: "${duplicateBarcode}".`);
      setEntering(false);
      return;
    }

    // Validate all items first
    const isValid = remainingParsedData.every((data, i) => {
      const editedItem = editableData[i];
      const category = selectedCategories[i];
      const unitPriceNumber = parseFloat(editedItem?.price); // Check if price exists
      const quantityNumber = parseInt(editedItem?.quantity, 10); // Check if quantity exists

      // Check if category is set
      if (!category) {
        alert(
          `Please ensure every item has a category set. Item ${i + 1}: Name: "${
            editedItem?.name
          }", Barcode: "${editedItem?.barcode}".`
        );
        setEntering(false);
        return false;
      }
      // Check if editedItem exists
      if (!editedItem) {
        alert(
          `Please make sure each item has a valid name and description. Item ${
            i + 1
          }: Name: "${editedItem?.name}", Barcode: "${editedItem?.barcode}".`
        );
        setEntering(false);
        return false;
      }

      if (!barcodeDisabled[i]) {
        const barcode = editedItem.barcode;

        if (typeof barcode !== "string" || barcode.trim() === "") {
          alert(
            `Please make sure item ${i + 1} has a valid barcode. Item ${
              i + 1
            }: Name: "${editedItem?.name}", Barcode: "${barcode}".`
          );
          setEntering(false);
          return false;
        }
      }

      // Check if name and description are strings and not empty
      if (
        typeof editedItem.name !== "string" ||
        editedItem.name.trim() === "" ||
        typeof editedItem.description !== "string" ||
        editedItem.description.trim() === ""
      ) {
        alert(
          `Please make sure each item has a valid name and description. Item ${
            i + 1
          }: Name: "${editedItem?.name}", Barcode: "${editedItem?.barcode}".`
        );
        setEntering(false);
        return false;
      }

      // Check if price and quantity are valid numbers
      if (isNaN(unitPriceNumber) || isNaN(quantityNumber)) {
        alert(
          `Please make sure price and quantity are valid numbers. Item ${
            i + 1
          }: Name: "${editedItem?.name}", Barcode: "${editedItem?.barcode}".`
        );
        setEntering(false);
        return false;
      }

      return true;
    });

    // If any item is invalid, stop processing
    if (!isValid) {
      setEntering(false);
      return;
    }

    // After all local checks are valid, proceed to check the warehouse
    //@ts-ignore
    let existingBarcodes = [];
    try {
      const querySnapshot = await getDocs(collection(db, "Warehouse"));
      existingBarcodes = querySnapshot.docs.map((doc) => doc.data().barcode); // Fetch the barcode field from each document
    } catch (error) {
      console.error("Error fetching existing barcodes: ", error);
      alert("Failed to fetch existing warehouse data.");
      setEntering(false);
      return;
    }

    // Check if any new custom barcode already exists in the warehouse
    const existingDuplicateBarcode = newBarcodes.find((barcode) =>
      //@ts-ignore
      existingBarcodes.includes(barcode)
    );
    if (existingDuplicateBarcode) {
      alert(
        `The custom barcode "${existingDuplicateBarcode}" already exists in the warehouse.`
      );
      setEntering(false);
      return;
    }

    // Convert the default image to base64 if needed
    const defaultImageBase64 = await getBase64FromImageUrl(defaultImage);

    // If all items are valid, add them to the warehouse
    for (let i = 0; i < remainingParsedData.length; i++) {
      const editedItem = editableData[i];
      const category = selectedCategories[i];
      const unitPriceNumber = parseFloat(editedItem.price);
      const quantityNumber = parseInt(editedItem.quantity, 10);

      const itemData = {
        name: editedItem.name,
        category,
        description: editedItem.description,
        quantity: quantityNumber,
        unitPrice: unitPriceNumber,
        imageUrl: remainingParsedData[i].image || defaultImageBase64,
        timestamp: Date.now(),
        reserve: 0,
        vat: vatSelection[i] || false, // Include the VAT field
        archived: false, // Set archived to false by default
      };

      if (barcodeDisabled[i] || !editedItem.barcode) {
        //@ts-ignore
        itemData.barcode = generateBarcode();
      } else {
        // Validate and use the custom barcode if provided
        const barcodeValidationResult = validateBarcode(editedItem.barcode);
        if (barcodeValidationResult) {
          alert(
            `Error in item ${i + 1}: ${barcodeValidationResult}. Item ${
              i + 1
            }: Name: "${editedItem?.name}", Barcode: "${editedItem?.barcode}".`
          );
          setEntering(false);
          return;
        }
        //@ts-ignore
        itemData.barcode = editedItem.barcode;
      }

      try {
        // Always add a new document with a generated ID
        const docRef = await addDoc(collection(db, "Warehouse"), itemData);
        console.log("Document successfully written!", docRef.id);
        await processHistoryLogClient(docRef.id, "userUID", "create", itemData);
      } catch (error) {
        console.error("Error writing document: ", error);
      }
    }

    setEntering(false);
    handleClose(); // Close the modal
  };

  function generateBarcode(): string {
    const timestampPart = Date.now().toString(); // Keep it numeric
    const randomPart = Math.floor(Math.random() * 1000000).toString(); // Generate a random number and convert it to a string
    return timestampPart + randomPart;
  }

  const handleDrop = (event: any, index: any) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageData = `data:${file.type};base64,${btoa(
          //@ts-ignore
          e.target.result
        )}`;
        setRemainingParsedData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index] = {
            ...updatedData[index],
            image: newImageData,
          };
          return updatedData;
        });
      };
      reader.readAsBinaryString(file);
    }
  };

  if (!isOpen) return null;

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        {entering && (
          <div style={modalStyles.overlay}>
            <div className="spinner-small"></div>{" "}
            {/* Use the spinner class here */}
          </div>
        )}
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            fontSize: "25px",
            color: "#6c757d",
          }}
          onClick={handleClose}
        >
          &#x2715;
        </div>
        <h1 style={{ fontSize: 25 }}>Parsed Images and JSON</h1>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <div style={{ marginBottom: "20px" }}>
              <label>Global Category: </label>
              <select
                value={globalCategory}
                style={{ ...modalStyles.inputGlobalStyle, marginTop: "20px" }}
                onChange={(e) => setGlobalCategory(e.target.value)}
              >
                <option value="">Select a global category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="checkbox"
                checked={globalToggle}
                onChange={handleGlobalToggle}
              />
              <label>Disable all custom barcodes</label>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <input
                type="checkbox"
                checked={globalVat}
                onChange={() => {
                  const newGlobalVat = !globalVat;
                  setGlobalVat(newGlobalVat);
                  setVatSelection(
                    Object.fromEntries(
                      new Array(remainingParsedData.length)
                        .fill(null)
                        .map((_, i) => [i, newGlobalVat])
                    )
                  );
                }}
              />
              <label>Apply VAT to all items</label>
            </div>

            {remainingParsedData &&
              remainingParsedData.map((data, index) => (
                <div
                  key={index}
                  style={{
                    ...modalStyles.parsedDataContainer,
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 50,
                  }}
                >
                  <div style={modalStyles.itemNumber}>{index + 1}-</div>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        fontSize: "20px",
                        color: "red",
                      }}
                      onClick={() => handleDeleteItem(index)} // Add this line
                    >
                      &#x2715;
                    </div>
                  </div>
                  <div
                    style={{
                      ...modalStyles.imageContainer,
                      marginRight: "20px",
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e, index)}
                  >
                    <div
                      style={{
                        //position: "absolute",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteImage(index)}
                    >
                      &#x2715;
                    </div>
                    <img
                      src={data.image || defaultImage}
                      alt={`Parsed Image ${index}`}
                      style={modalStyles.imageStyle}
                    />
                  </div>
                  <div style={modalStyles.jsonStyle}>
                    {/* Editable fields for name, description, quantity, and price */}
                    <div>
                      <div style={modalStyles.labelStyle}>Name:</div>
                      <input
                        type="text"
                        style={modalStyles.inputStyle}
                        value={
                          editableData[index] ? editableData[index].name : ""
                        }
                        onChange={(e) =>
                          handleEditableChange(index, "name", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <div style={modalStyles.labelStyle}>Description:</div>
                      <input
                        type="text"
                        style={modalStyles.inputStyle}
                        value={
                          editableData[index]
                            ? editableData[index].description
                            : ""
                        }
                        onChange={(e) =>
                          handleEditableChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <div style={modalStyles.labelStyle}>Quantity:</div>
                      <input
                        type="number"
                        style={modalStyles.inputStyle}
                        value={
                          editableData[index]
                            ? editableData[index].quantity
                            : ""
                        }
                        onChange={(e) =>
                          handleEditableChange(
                            index,
                            "quantity",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <div style={modalStyles.labelStyle}>Price:</div>
                      <input
                        type="number"
                        style={modalStyles.inputStyle}
                        value={
                          editableData[index] ? editableData[index].price : ""
                        }
                        onChange={(e) =>
                          handleEditableChange(index, "price", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <div style={modalStyles.labelStyle}>
                        <input
                          type="checkbox"
                          checked={vatSelection[index] || false}
                          onChange={() =>
                            setVatSelection((prev) => ({
                              ...prev,
                              [index]: !prev[index],
                            }))
                          }
                        />
                        <label>Apply VAT</label>
                      </div>
                      <div style={modalStyles.labelStyle}>
                        <input
                          type="checkbox"
                          checked={barcodeDisabled[index] || false}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label>Use default Barcode</label>
                      </div>

                      <div style={modalStyles.labelStyle}>Custom Barcode:</div>
                      <input
                        type="text"
                        style={modalStyles.inputStyle}
                        placeholder="Barcode"
                        value={
                          editableData[index] ? editableData[index].barcode : ""
                        }
                        onChange={(e) =>
                          handleEditableChange(index, "barcode", e.target.value)
                        }
                        disabled={barcodeDisabled[index] || false}
                      />
                    </div>
                    <select
                      style={{ ...modalStyles.inputStyle, marginTop: "20px" }}
                      value={selectedCategories[index] || ""}
                      onChange={(e) =>
                        handleCategoryChange(index, e.target.value)
                      }
                    >
                      <option value="">Select a category</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ))}

            <button
              style={modalStyles.closeButton}
              disabled={entering}
              onClick={handleClose}
            >
              Close
            </button>
            <button
              onClick={processParsedItems}
              disabled={entering}
              style={modalStyles.button}
            >
              Process Items
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Function to format JSON data with indentation
const formatJson = (jsonObject: any) => {
  if (jsonObject === null || typeof jsonObject === "undefined") {
    console.error("formatJson received null or undefined input");
    return "";
  }

  // Transform the object into key-value pairs
  const keyValuePairs = Object.entries(jsonObject).map(([key, value]) => {
    // Create a string from each key-value pair, e.g., "name: Value Pack Frame Fixings M10 x 100 Pack of 3"
    return `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`;
  });

  // Join all the strings into one large string separated by line breaks
  return keyValuePairs.join("\n");
};

const modalStyles: { [key: string]: CSSProperties } = {
  dropArea: {
    flex: 1,
    //border: "2px dashed #ccc",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1002, // Make sure the overlay appears above other modal content
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loadingIcon: {
    width: 50, // Adjust the width and height of the loading icon as needed
    height: 50,
  },

  inputStyle: {
    width: "170%",
    padding: 10,
    fontWeight: 500,
    fontSize: 14,
    border: "2px solid #ccc",
    borderRadius: 10,
  },

  inputGlobalStyle: {
    width: "70%",
    padding: 10,
    fontWeight: 500,
    fontSize: 14,
    border: "2px solid #ccc",
    borderRadius: 15,
  },

  labelStyle: {
    fontWeight: 600,
    fontSize: 17,
    paddingTop: "15px",
    paddingBottom: "5px",
  },

  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  },
  container: {
    position: "relative", // Add this line

    backgroundColor: "white",
    //padding: "50px 170px",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 100,
    paddingRight: 300,
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    //maxWidth: "450px", // Set a max width for larger screens
    //width: "90%", // Responsive width
    maxHeight: "85vh",
    overflowY: "auto",
  },
  imageContainer: {
    marginBottom: "20px",
    position: "relative", // Add this line
  },
  imageStyle: {
    width: "80%",
    maxWidth: 250,
    maxHeight: 450,
    height: "auto",
    border: "1px solid #ccc",
  },
  jsonStyle: {
    padding: 20,
    whiteSpace: "pre-wrap", // Preserve line breaks
    fontFamily: "monospace", // Use monospace font for JSON
  },
  closeButton: {
    fontSize: "16px",
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    marginTop: "20px",
    transition: "background-color 0.2s ease",
  },

  button: {
    fontSize: "16px",
    marginLeft: 20,
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    marginTop: "20px",
    transition: "background-color 0.2s ease",
  },
};

export default ParsePDFModal;
