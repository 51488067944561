// RowHeader.tsx

import React from "react";
import { BiSort } from "react-icons/bi";
import { FaImage } from "react-icons/fa";

interface RowHeaderProps {
  titles: {
    [key: string]: string;
  };
  percentages: {
    [key: string]: string;
  };
  sortCriteria: string | null;
  sortOrder: "asc" | "desc" | null;
  onSortChange: (criteria: string) => void;
}

const RowHeader: React.FC<RowHeaderProps> = ({
  titles,
  percentages,
  sortCriteria,
  sortOrder,
  onSortChange,
}) => {
  const headerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "space-between",
    backgroundColor: "#1c345d",
    color: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "13px",
    fontFamily: "PoppinsBold",
    paddingLeft: 10,
    paddingRight: 10,
  };

  const handleSortClick = (criteria: string) => {
    onSortChange(criteria);
  };

  // Define sortable columns
  const sortableColumns = ["quantity", "reserved"];

  return (
    <div style={headerStyles}>
      {Object.entries(titles).map(([key, title], index) => (
        <span
          key={key}
          style={{
            flexBasis: percentages[key],
            cursor: sortableColumns.includes(key) ? "pointer" : "default",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={
            sortableColumns.includes(key)
              ? () => handleSortClick(key)
              : undefined
          }
        >
          <span>{title}</span>
          {key === "image" ? (
            <FaImage style={{ marginLeft: 5, color: "white" }} />
          ) : sortableColumns.includes(key) ? (
            <BiSort
              style={{
                marginLeft: 5,
                color:
                  sortCriteria === key
                    ? sortOrder === "asc"
                      ? "red"
                      : "green"
                    : "white",
                transform:
                  sortCriteria === key
                    ? sortOrder === "asc"
                      ? "rotate(0deg)"
                      : "rotate(180deg)"
                    : "none",
                transition: "transform 0.2s",
              }}
            />
          ) : null}
        </span>
      ))}
    </div>
  );
};

export default RowHeader;
