import React, { useEffect, useRef, useState } from "react";
import { formatBase64Image } from "./Functions";

interface ScanSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  product: {
    imageUrl: string;
    name: string;
    barcode: string; // Use barcode here
  } | null;
  onBarcodeScan: (barcode: string) => void; // Pass the scan handler from parent
  cartItems: Array<{ barcode: string; quantity: number }>; // Pass the cartItems array with barcode
  noMoreQuantitiesAvailable: boolean; // Receive as prop
  resetNoMoreQuantitiesAvailable: () => void; // Receive the reset function as a prop
}

const ScanSuccessModal: React.FC<ScanSuccessModalProps> = ({
  isOpen,
  onClose,
  product,
  onBarcodeScan,
  cartItems, // Receive the cartItems array
  noMoreQuantitiesAvailable, // Destructure the prop
  resetNoMoreQuantitiesAvailable, // Destructure the reset function
}) => {
  const barcodeInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      barcodeInputRef.current?.focus(); // Focus the input when the modal opens
    }
  }, [isOpen]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim()) {
      resetNoMoreQuantitiesAvailable(); // Reset noMoreQuantitiesAvailable to false
      setIsLoading(true); // Set loading state to true when barcode is scanned
      onBarcodeScan(e.target.value.trim()); // Trigger scan on input change
      e.target.value = ""; // Clear input after scanning
      barcodeInputRef.current?.focus(); // Refocus the input after each change
    }
  };

  // Calculate the quantity of the current product in the cart based on barcode
  const quantityInCart = product
    ? cartItems.find(item => item.barcode === product.barcode)?.quantity || 0
    : 0;

  // Simulate a delay for loading effect (replace this with actual logic in your app)
  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false); // Set loading state to false after quantity updates
      }, 1000); // Simulate delay (1 second)

      return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [cartItems]); // Re-run effect when cartItems changes

  return isOpen ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        zIndex: "9999",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          width: "90%",
          maxWidth: "400px",
          maxHeight: "90vh",
          overflowY: "auto",
          textAlign: "center",
        }}
      >
        <h2 style={{ marginBottom: "20px" }}>
          {product ? "Item Successfully Added" : "Fast Scan Mode Active"}
        </h2>
        {product ? (
          <>
            <img
              src={formatBase64Image(product.imageUrl)}
              alt={product.name}
              style={{ maxWidth: "100%", height: "auto", maxHeight: "150px" }}
            />
            <p style={{ fontSize: "18px", marginTop: "10px" }}>
              {product.name}
            </p>
            <p style={{ fontSize: "18px", marginTop: "10px", color: "green" }}>
              {noMoreQuantitiesAvailable
                ? "No more quantities available"
                : isLoading
                ? "Loading..."
                : `In Cart: ${quantityInCart}`}{" "}
            </p>
          </>
        ) : (
          <p style={{ fontSize: "18px", marginTop: "10px" }}>
            Waiting for item scan...
          </p>
        )}
        <input
          ref={barcodeInputRef}
          type="text"
          placeholder="Scan or Enter Barcode"
          onChange={handleInputChange}
          style={{
            marginTop: "20px",
            padding: "10px",
            width: "100%",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
        <button
          onClick={onClose}
          style={{
            marginTop: "20px",
            padding: "10px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Exit Fast Scan Mode
        </button>
      </div>
    </div>
  ) : null;
};

export default ScanSuccessModal;
