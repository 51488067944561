//@ts-nocheck

import React, { useEffect, useState, CSSProperties } from "react";
import { formatBase64Image, processHistoryLogClient } from "./Functions";
import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  increment,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { db } from "../App";
import AddProspectModal from "./AddProspectModal";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../App"; // Ensure this is imported correctly

import AddToCartModal from "./AddToCartModal";

interface CartModalProps {
  isOpen: boolean;
  onClose: () => void;
  cartItems: any[];
  //clearCart: () => void; // Function to clear the cart
  selectedStoreId: string;
  addToCart: any;
}

const CartModal: React.FC<CartModalProps> = ({
  isOpen,
  onClose,
  cartItems,
  //clearCart,
  selectedStoreId,
  addToCart,
}) => {
  const [prospects, setProspects] = useState([]);
  const [isAddingProspect, setIsAddingProspect] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");
  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false); // New state for loading indicator
  const [totalPrice, setTotalPrice] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const [vatTotalPrice, setVatTotalPrice] = useState(0);
  const [nonVatTotalPrice, setNonVatTotalPrice] = useState(0);
  const [vatItems, setVatItems] = useState([]);
  const [nonVatItems, setNonVatItems] = useState([]);

  const [addQuantityProduct, setAddQuantityProduct] = useState(null);
  const [isClearingCart, setIsClearingCart] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  useEffect(() => {
    console.log("in cart:", selectedStoreId);
    const selectedProspect = prospects.find(
      (prospect) => prospect.id === selectedStoreId
    );
    if (selectedProspect) {
      setSelectedStore(selectedProspect.name);
    }
  }, [selectedStoreId, prospects]);

  useEffect(() => {
    const vatTotal = cartItems
      .filter((item) => item.vat)
      .reduce((acc, item) => acc + item.unitPrice * item.quantity * 1.2, 0); // Adding 20% tax

    const nonVatTotal = cartItems
      .filter((item) => !item.vat)
      .reduce((acc, item) => acc + item.unitPrice * item.quantity, 0);

    setVatTotalPrice(vatTotal.toFixed(2)); // Ensure it's a string with two decimal places
    setNonVatTotalPrice(nonVatTotal.toFixed(2));
    setVatItems(cartItems.filter((item) => item.vat));
    setNonVatItems(cartItems.filter((item) => !item.vat));
  }, [cartItems]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Prospects"), (snapshot) => {
      const fetchedProspects = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((prospect) => !prospect.archived); // Exclude archived prospects

      setProspects(fetchedProspects);
    });

    return () => unsubscribe();
  }, []);

  const handleSelectStore = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (value === "add-store") {
      setIsAddingProspect(true);
    } else {
      setSelectedStore(value);
    }
  };

  const handleRemoveFromCart = async (itemId, quantityToRemove) => {
    setIsProcessing(true);

    console.log(itemId);

    if (!itemId || quantityToRemove <= 0) {
      alert("Invalid item details or quantity.");
      setIsProcessing(false);
      return;
    }

    const warehouseItemRef = doc(db, "Warehouse", itemId);
    const warehouseItemSnap = await getDoc(warehouseItemRef);

    if (!warehouseItemSnap.exists()) {
      alert("Product not found in the warehouse.");
      setIsProcessing(false);
      return;
    }

    const warehouseItemData = warehouseItemSnap.data();
    const currentReserved = warehouseItemData.reserved || 0;

    if (quantityToRemove > currentReserved) {
      alert(
        `Cannot remove more items than are reserved. Reserved: ${currentReserved}`
      );
      setIsProcessing(false);
      return;
    }

    const newReserved = currentReserved - quantityToRemove;

    const cartRef = doc(db, "Cart", selectedStoreId || "admin");
    const cartSnap = await getDoc(cartRef);
    const batch = writeBatch(db);

    if (cartSnap.exists()) {
      const cartData = cartSnap.data();
      const itemIndex = cartData.items.findIndex((item) => item.id === itemId);
      if (itemIndex >= 0) {
        cartData.items[itemIndex].quantity -= quantityToRemove;
        if (cartData.items[itemIndex].quantity <= 0) {
          cartData.items.splice(itemIndex, 1); // Remove the item if the quantity is zero or less
        }
        batch.set(cartRef, { items: cartData.items }, { merge: true });
      }
    }

    // Unreserve inventory in the warehouse
    batch.update(warehouseItemRef, { reserved: newReserved });

    // Log the unreservation
    processHistoryLogClient(itemId, selectedStoreId, "unreserve", {
      previousReserved: currentReserved,
      newReserved: newReserved,
      quantity: warehouseItemData.quantity,
      additionalInfo: {
        unreservedBy: selectedStoreId,
        unreservedQuantity: quantityToRemove,
      },
    });

    try {
      await batch.commit();
      alert("Item removed from cart and reservation updated successfully.");
      onClose();
    } catch (error) {
      console.error("Error updating cart and reservations: ", error);
      alert("Failed to remove item from cart and update reservations.");
    } finally {
      setIsProcessing(false);
    }
  };

  const clearCart = async () => {
    if (isClearingCart) return; // Prevent further clicks if already clearing the cart

    setIsClearingCart(true);
    console.log("clearCart called");
    if (cartItems.length === 0) {
      console.log("Cart is already empty.");
      setIsClearingCart(false); // Reset the state if the cart is already empty
      return;
    }

    const batch = writeBatch(db);

    for (const item of cartItems) {
      if (!item.docId) {
        console.error("Item does not have a valid docId:", item);
        continue; // Skip this item and move to the next one
      }

      const warehouseItemRef = doc(db, "Warehouse", item.docId);
      const warehouseItemSnap = await getDoc(warehouseItemRef);
      if (warehouseItemSnap.exists()) {
        const warehouseItemData = warehouseItemSnap.data();
        const currentReserved = warehouseItemData.reserved || 0;
        const newReserved = currentReserved - item.quantity;
        batch.update(warehouseItemRef, { reserved: newReserved });

        // Log the unreservation
        processHistoryLogClient(item.docId, selectedStoreId, "unreserve", {
          previousReserved: currentReserved,
          newReserved: newReserved,
          quantity: warehouseItemData.quantity,
          additionalInfo: {
            unreservedBy: selectedStoreId,
            unreservedQuantity: item.quantity,
          },
        });
      } else {
        console.error("Warehouse item not found:", item.docId);
      }
    }

    // Clear the cart in Firestore
    const cartRef = doc(db, "Cart", selectedStoreId);
    batch.set(cartRef, { items: [] });

    try {
      await batch.commit();
      console.log("Cart cleared and quantities updated successfully.");
    } catch (error) {
      console.error("Error clearing cart and updating quantities: ", error);
    } finally {
      setIsClearingCart(false); // Reset the state after the operation is completed
    }
  };

  const confirmClearCart = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to clear the cart?"
    );
    if (userConfirmed) {
      clearCart();
    }
  };

  const generateDocId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().substr(-2); // Get last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Get month (0-11, hence +1), pad with zero if needed
    const day = now.getDate().toString().padStart(2, "0"); // Get day of the month, pad with zero if needed
    const seconds = (
      now.getHours() * 3600 +
      now.getMinutes() * 60 +
      now.getSeconds()
    )
      .toString()
      .padStart(5, "0"); // Convert current time to seconds, ensure it's 5 digits

    return `${year}-${month}-${day}-${seconds}`;
  };

  const restoreCartAdmin = async () => {
    if (!selectedStoreId || typeof selectedStoreId !== "string") {
      console.error(
        "Invalid or no store ID provided for cart restoration.",
        selectedStoreId
      );
      return;
    }

    try {
      const backupRef = doc(db, "CartBackup", selectedStoreId);
      const cartRef = doc(db, "Cart", selectedStoreId);
      const backupSnap = await getDoc(backupRef);

      if (backupSnap.exists()) {
        await setDoc(cartRef, {
          ...backupSnap.data(),
          // Optionally remove the backup timestamp when restoring
          //backupTimestamp: deleteField()
        });
        console.log("Cart restored successfully from backup.");
      } else {
        console.error("No backup found to restore.");
      }
    } catch (error) {
      console.error("Error restoring cart items:", error);
    }
  };

  const backupCartADMIN = async () => {
    if (!selectedStoreId || typeof selectedStoreId !== "string") {
      console.error(
        "Invalid or no store ID provided for cart backup.",
        selectedStoreId
      );
      return;
    }

    try {
      const cartRef = doc(db, "Cart", selectedStoreId);
      const backupRef = doc(db, "CartBackup", selectedStoreId);
      const cartSnap = await getDoc(cartRef);

      if (cartSnap.exists()) {
        await setDoc(backupRef, {
          ...cartSnap.data(),
          backupTimestamp: serverTimestamp(),
        });
        console.log("Cart items backed up successfully.");
      } else {
        console.error("No cart found to backup.");
      }
    } catch (error) {
      console.error("Error backing up cart items:", error);
    }
  };

  function isValidBase64(str) {
    // This regex checks if the string is a valid base64 format
    const regex =
      /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    return regex.test(str);
  }

  const generateInvoice = async () => {
    if (cartItems.length === 0 || !selectedStore) {
      console.error("Cart is empty or store is not selected.");
      return;
    }
    setIsGeneratingInvoice(true); // Set loading state

    const selectedProspect = prospects.find(
      (prospect) => prospect.name === selectedStore
    );

    const failedItemBarcodes = []; // Array to store barcodes of failed items
    const batch = writeBatch(db); // Initialize the Firestore batch right here

    const vatTotal = cartItems
      .filter((item) => item.vat)
      .reduce((acc, item) => acc + item.unitPrice * item.quantity * 1.2, 0); // Adding 20% tax

    const nonVatTotal = cartItems
      .filter((item) => !item.vat)
      .reduce((acc, item) => acc + item.unitPrice * item.quantity, 0);

    try {
      // Upload images and gather details, managing the number of simultaneous uploads
      const uploadPromises = cartItems.map(async (item, index) => {
        const imageRef = ref(
          storage,
          `invoiceImages/${generateDocId()}-${index}.jpg`
        );
        if (!item.imageUrl || !item.imageUrl.includes(",")) {
          console.error(
            "Image URL format is incorrect for item:",
            item.name,
            "URL:",
            item.imageUrl
          );
          failedItemBarcodes.push(item.barcode); // Add barcode to the array of failed items
          return Promise.reject(new Error("Invalid image URL format")); // Reject the promise if format is incorrect
        }

        const [prefix, base64String] = item.imageUrl.split(",");
        let imageBytes;

        if (prefix.includes("jpeg")) {
          imageBytes = Uint8Array.from(atob(base64String), (c) =>
            c.charCodeAt(0)
          );
        } else if (prefix.includes("png")) {
          imageBytes = Uint8Array.from(atob(base64String), (c) =>
            c.charCodeAt(0)
          );
        } else {
          console.error("Unsupported image format for item:", item.name);
          failedItemBarcodes.push(item.barcode); // Add barcode to the array of failed items
          return Promise.reject(new Error("Unsupported image format")); // Reject the promise if format is unsupported
        }

        const warehouseItemRef = doc(db, "Warehouse", item.docId);
        const warehouseItemSnap = await getDoc(warehouseItemRef);
        if (!warehouseItemSnap.exists()) {
          console.error("Warehouse item not found: ", item.docId);
          return null; // Skip further processing for this item
        }

        const warehouseItem = warehouseItemSnap.data();

        const timestamp = Timestamp.fromDate(new Date());
        // Prepare the log entry for the history
        const logEntry = {
          actionByUid: selectedStoreId,
          actionType: "generate_invoice",
          timestamp: timestamp.toMillis(),
          details: {
            previousReserved: warehouseItem.reserved,
            newReserved: warehouseItem.reserved,
            previousQuantity: warehouseItem.quantity,
            quantity: warehouseItem.quantity,
            processedBy: selectedStoreId,
            processedQuantity: item.quantity,
          },
        };

        // Update the warehouse item in the batch
        batch.update(warehouseItemRef, {
          //reserved: increment(-item.quantity),
          //quantity: increment(-item.quantity),
        });

        /*
        const historyDocRef = doc(db, "ProcessHistory", item.docId);
        batch.set(
          historyDocRef,
          { history: arrayUnion(logEntry) },
          { merge: true }
        );
        */

        await processHistoryLogClient(
          item.docId, // itemId
          selectedStoreId, // actionByUid
          "generate_invoice", // actionType
          logEntry.details, // details
          batch // Pass the existing batch to include the log in it
        );

        return uploadBytes(imageRef, imageBytes)
          .then((uploadResult) => getDownloadURL(uploadResult.ref))
          .then((downloadURL) => ({
            ...item,
            imageUrl: downloadURL,
          }))
          .catch((error) => {
            console.error("Failed to upload image for item:", item.name, error);
            failedItemBarcodes.push(item.barcode); // Add barcode to the array of failed items
            throw error; // Re-throw the error to handle it in the catch block
          });
      });

      const itemsWithDetails = await Promise.all(uploadPromises);
      if (itemsWithDetails.some((item) => item === null)) {
        console.error(
          "Some images failed to upload, aborting invoice generation."
        );
        return; // Abort if any image failed to upload
      }

      // All images uploaded successfully, proceed with Firestore operations
      const docId = generateDocId(); // Generate a unique ID for the invoice
      const invoiceRef = doc(db, "Invoices", docId);
      const invoiceLogRef = doc(db, "InvoiceLogs", docId); // Create a log with the same ID

      // Prepare invoice details
      const invoiceDetails = {
        items: itemsWithDetails,
        totalPrice: nonVatTotal,
        totalPriceVat: vatTotal,
        storeName: selectedProspect.name,
        storeAddress: selectedProspect.address,
        phoneNumber: selectedProspect.phoneNumber,
        timestamp: serverTimestamp(),
        storeId: selectedProspect.id,
      };

      batch.set(invoiceRef, invoiceDetails);

      // Log the invoice creation
      const invoiceLogDetails = {
        invoiceId: docId,
        createdBy: selectedStoreId,
        timestamp: serverTimestamp(),
        items: itemsWithDetails,
        status: "created",
      };

      batch.set(invoiceLogRef, invoiceLogDetails);

      // Commit batch
      await batch.commit();
      console.log("Invoice generated and logged successfully.");
      softClearCart(); // Clear the cart after generating the invoice
      onClose(); // Close the invoice modal
    } catch (error) {
      console.error("Error during invoice generation process: ", error);
    } finally {
      setIsGeneratingInvoice(false);
    }
    if (failedItemBarcodes.length > 0) {
      console.error("Items failed to upload:");
      failedItemBarcodes.forEach((barcode) => {
        console.error("- Barcode:", barcode);
      });
    }
  };

  const generateInvoiceOLD = async () => {
    if (cartItems.length === 0 || !selectedStore) {
      console.error("Cart is empty or store is not selected.");
      return;
    }
    setIsGeneratingInvoice(true); // Set loading state

    const selectedProspect = prospects.find(
      (prospect) => prospect.name === selectedStore
    );

    const itemsWithDetails = cartItems.map((item) => {
      return {
        docId: item.docId,
        quantity: item.quantity,
        name: item.name,
        description: item.description,
        category: item.category,
        barcode: item.barcode,
        imageUrl: item.imageUrl,
        price: item.unitPrice,
      };
    });

    const docId = generateDocId(); // Generate a unique ID for the invoice
    const invoiceRef = doc(db, "Invoices", docId);
    const invoiceLogRef = doc(db, "InvoiceLogs", docId); // Create a log with the same ID

    const batch = writeBatch(db);

    // Set invoice details
    const invoiceDetails = {
      items: itemsWithDetails,
      totalPrice: itemsWithDetails.reduce((total, item) => {
        return parseFloat((total + item.price * item.quantity).toFixed(2));
      }, 0),

      storeName: selectedProspect?.name,
      storeAddress: selectedProspect?.address,
      timestamp: serverTimestamp(),
      storeId: selectedStoreId, // Include the store ID
    };

    batch.set(invoiceRef, invoiceDetails);

    // Log the invoice creation
    const invoiceLogDetails = {
      invoiceId: docId,
      createdBy: selectedStoreId,
      timestamp: serverTimestamp(),
      items: itemsWithDetails,
      status: "created", // Initial status of the invoice log
    };

    batch.set(invoiceLogRef, invoiceLogDetails);

    try {
      await batch.commit();
      console.log("Invoice generated and logged successfully.");
      softClearCart(); // Clear the cart after generating the invoice
      onClose(); // Close the invoice modal
    } catch (error) {
      console.error("Error generating invoice and logging: ", error);
    } finally {
      setIsGeneratingInvoice(false);
    }
  };

  const softClearCart = async () => {
    console.log("Soft clearing cart without unreserving items");
    const cartRef = doc(db, "Cart", selectedStoreId);

    // Simply set the cart items to an empty array without touching the warehouse data
    try {
      await setDoc(cartRef, { items: [] }, { merge: true });
      console.log("Cart emptied successfully.");
    } catch (error) {
      console.error("Error clearing cart softly: ", error);
    }
  };

  const generateInvoiceButtonStyle = (
    isDisabled: boolean,
    isLoading: boolean
  ): CSSProperties => {
    return {
      ...modalStyles.closeButton,
      backgroundColor: isDisabled ? "#ced4da" : "#007bff", // Grey out if disabled
      cursor: isDisabled ? "not-allowed" : "pointer", // Change cursor based on state
      color: isDisabled ? "#666" : "white", // Change text color if disabled
      ...(isLoading && { pointerEvents: "none" }), // Prevent clicks while loading
    };
  };




  
  if (!isOpen) return null;

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <button
          style={modalStyles.topRightCloseButton}
          onClick={onClose}
          aria-label="Close"
        >
          ×
        </button>
        <h1 style={{ fontSize: 25 }}>Your Cart</h1>
        <select
          value={selectedStore}
          onChange={handleSelectStore}
          style={{
            ...modalStyles.inputStyles,
            ...(selectedStoreId !== "admin" &&
              selectedStoreId !== "admin1" &&
              selectedStoreId !== "admin2" && {
                opacity: 0.5,
                pointerEvents: "none",
              }),
          }}
          disabled={
            selectedStoreId !== "admin" &&
            selectedStoreId !== "admin1" &&
            selectedStoreId !== "admin2"
          }
        >
          <option value="">Select a store</option>
          {prospects.map((prospect) => (
            <option key={prospect.id} value={prospect.name}>
              {prospect.name}
            </option>
          ))}
          <option value="add-store">Add Store</option>
        </select>

        <button
          style={modalStyles.button}
          onClick={confirmClearCart}
          disabled={isClearingCart} // Disable the button if clearing is in progress
        >
          Clear Cart
        </button>
        <button
          style={generateInvoiceButtonStyle(
            cartItems.length === 0 || selectedStore === "",
            isGeneratingInvoice
          )}
          onClick={generateInvoice}
          disabled={cartItems.length === 0 || selectedStore === ""}
        >
          {isGeneratingInvoice ? "Loading..." : "Generate Invoice"}
        </button>

        <h3>
          Total Price (VAT): £{vatTotalPrice} (Items: {vatItems.length})
        </h3>
        {vatItems.length > 0 ? (
          <ul style={modalStyles.listStyle}>
            {vatItems.map((item) => (
              <li key={item.id} style={modalStyles.listItemStyle}>
                <button
                  style={modalStyles.removeButton}
                  onClick={() =>
                    handleRemoveFromCart(item.docId, item.quantity)
                  }
                  disabled={isProcessing} // Disable if any item is being processed
                >
                  ×
                </button>

                <img
                  src={formatBase64Image(item.imageUrl)}
                  alt={item.name}
                  style={modalStyles.imageStyle}
                />
                <div style={modalStyles.itemDetail}>
                  <p style={{ margin: 0, fontSize: 16, fontWeight: 800 }}>
                    {item.name}
                  </p>
                  <p>Quantity: {item.quantity}</p>
                  <p>
                    Price: £
                    {parseFloat(item.unitPrice * item.quantity).toFixed(2)}
                  </p>
                  <button
                    style={modalStyles.button}
                    onClick={() => setAddQuantityProduct(item)}
                  >
                    Add Quantity
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No VAT items in your cart.</p>
        )}

        <h3>
          Total Price (Non-VAT): £{nonVatTotalPrice} (Items:{" "}
          {nonVatItems.length})
        </h3>


        {nonVatItems.length > 0 ? (
          <ul style={modalStyles.listStyle}>
            {nonVatItems.map((item) => (
              <li key={item.id} style={modalStyles.listItemStyle}>
                <button
                  style={modalStyles.removeButton}
                  onClick={() =>
                    handleRemoveFromCart(item.docId, item.quantity)
                  }
                  disabled={isProcessing} // Disable if any item is being processed
                >
                  ×
                </button>

                <img
                  src={formatBase64Image(item.imageUrl)}
                  alt={item.name}
                  style={modalStyles.imageStyle}
                />
                <div style={modalStyles.itemDetail}>
                  <p style={{ margin: 0, fontSize: 16, fontWeight: 800 }}>
                    {item.name}
                  </p>
                  <p>Quantity: {item.quantity}</p>
                  <p>
                    Price: £
                    {parseFloat(item.unitPrice * item.quantity).toFixed(2)}
                  </p>
                  <button
                    style={modalStyles.button}
                    onClick={() => setAddQuantityProduct(item)}
                  >
                    Add Quantity
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No Non-VAT items in your cart.</p>
        )}

  
      </div>

      <AddToCartModal
        isOpen={addQuantityProduct !== null}
        onClose={() => setAddQuantityProduct(null)}
        product={addQuantityProduct}
        cartItems={cartItems}
        selectedStoreId={selectedStoreId}
        addToCart={addToCart}
      />

      {isAddingProspect && (
        <AddProspectModal
          isOpen={isAddingProspect}
          onClose={() => setIsAddingProspect(false)}
        />
      )}
    </div>
  );
};

// Reuse and adapt the modalStyles from the previous example for consistency
const modalStyles: { [key: string]: CSSProperties } = {
  listStyle: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    gap: "20px", // Adds space between the items
    padding: "20px", // Padding around the entire grid
  },
  listItemStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    border: "1px solid #ccc", // Adds a light border around each item for better visibility
    borderRadius: "10px", // Rounds the corners of each grid item
    backgroundColor: "#f9f9f9", // A light background color for each item
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // A subtle shadow for depth
    textAlign: "center",
    position: "relative", // This allows absolute positioning inside the item
  },

  imageStyle: {
    width: "90%", // Full width of the grid item
    height: "100px", // Fixed height for images

    //borderRadius: "50%",
    border: "3px solid #007ae4",
    //marginLeft: "20px",
    //position: "absolute",
    objectFit: "cover",
  },

  removeButton: {
    position: "absolute",
    top: "5px", // Small top margin from the corner
    right: "5px", // Small right margin from the corner
    background: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: "bold",
  },

  topRightCloseButton: {
    position: "absolute", // Position relative to the parent with position: relative
    top: "10px", // Distance from the top edge of the container
    right: "10px", // Distance from the right edge of the container
    border: "none", // No border
    background: "none", // No background
    color: "black", // Light grey color, adjust as needed
    fontSize: "24px", // Larger font size for the '×' symbol
    cursor: "pointer", // Pointer cursor on hover
    padding: "0", // No padding
    lineHeight: "1", // Normal line height
  },

  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  },
  container: {
    position: "relative", // Add this line to ensure relative positioning context
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "750px", // Set a max width for larger screens
    width: "90%", // Responsive width
    maxHeight: "70vh",
    overflowY: "auto",
  },
  inputStyles: {
    borderRadius: "12px", // Softer edges
    border: "1px solid #ced4da", // Lighter border color
    padding: "15px", // Increased padding
    marginTop: "12px", // Consistent margin top
    marginBottom: "12px", // Consistent margin bottom
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Subtle inner shadow for depth
    width: "calc(100% - 30px)", // Adjust width to account for padding
    fontSize: "14px", // Slightly larger font size for readability
  },
  button: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
  },
  closeButton: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
  },
};

export default CartModal;
