import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { SERVER_URL, UserData } from './Types';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import VerifyProviderModal from './VerifyProviderModal';

const Authentication: React.FC = () => {
  const auth = getAuth();

  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<UserData[]>([]); // State for storing fetched users
  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const [selectedUid, setSelectedUid] = useState<string | null>(null); // Track the selected UID


  useEffect(() => {
    // Fetch user data when the component mounts
    fetchUsers();
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);
  
   const openModal = (userData: UserData) => {
    setSelectedUid(userData.uid); // Set the selected UID when the button is clicked
    setIsModalOpen(true);
  };


  const rowStyles: React.CSSProperties = {
    //borderRadius: '10px',
    padding: '40px',
    border: '1px solid #000000',
    margin: '10px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'black',
    fontSize: '20px',
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/users`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      setUsers(data.unverifiedProviders); // Update the state with the fetched user data
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching user data:', error);
      throw error; // Handle the error as needed in your component
    }
  };


  return (
    <div>
    <h1 style={{ marginLeft: '60px',marginTop:'30px',marginBottom:'50px',fontSize:35 }}>Authentication</h1>
    {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <p style={{ fontSize: '24px',marginTop:-500  }}>Loading...</p>
        </div>
      ) : users.length > 0 ? (
            <ul>
              {users.map((userData) => (
                <li key={userData.uid} style={rowStyles}>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Email:</span>
                    <br />
                    <span>{userData.email}</span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>UID:</span>
                    <br />
                    <span>{userData.uid}</span>
                  </div>
                  <div>
                   
    <button style={{ marginTop: '40px' }} onClick={() => openModal(userData)}>Verify Provider</button>
    
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <p style={{ fontSize: '24px',marginTop:-500 }}>No Providers awaiting verification</p>
          </div>
                )}
      {isModalOpen && (
            <VerifyProviderModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              selectedUid={selectedUid} 
            />
          )}
        </div>
  );
};

export default Authentication;
