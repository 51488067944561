//@ts-nocheck
import React, { CSSProperties, useEffect, useState } from "react";
import { db } from "../App";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { processHistoryLogClient } from "./Functions";

interface AddWarehouseModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddWarehouseModal: React.FC<AddWarehouseModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(null);
  const [boxQuantity, setBoxQuantity] = useState(null); // This quantity is conditional
  const [unitPrice, setUnitPrice] = useState("");
  const [barcode, setBarcode] = useState(""); // State for the barcode input
  const [customBarcode, setCustomBarcode] = useState(false); // State for the custom barcode checkbox
  const [boxItem, setBoxItem] = useState(false); // State for the box item checkbox
  const [vat, setVat] = useState(false); // State for VAT checkbox

  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [categories, setCategories] = useState<any[]>([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [existingBarcodes, setExistingBarcodes] = useState([]);

  useEffect(() => {
    const fetchExistingBarcodes = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Warehouse"));
        const barcodes = querySnapshot.docs.map((doc) => doc.data().barcode);
        setExistingBarcodes(barcodes);
      } catch (error) {
        console.error("Error fetching existing barcodes: ", error);
        // Handle errors, e.g., display an error message to the user
      }
    };

    fetchExistingBarcodes();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, "Categories"));
      const fetchedCategories: any[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().Category, // Assuming your category name field is "Category"
      }));
      setCategories(fetchedCategories);
    };

    fetchCategories();
  }, []);

  const toggleCustomBarcode = () => {
    setCustomBarcode(!customBarcode);
    // Clear the barcode if we're unchecking the custom barcode checkbox
    if (customBarcode) {
      setBarcode("");
    }
  };

  const toggleBoxItem = () => {
    setBoxItem(!boxItem);
  };

  const handleImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          resizeImage(img, 500, 500, (resizedDataUrl) => {
            const base64WithoutPrefix = resizedDataUrl.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            );

            // Determine the correct prefix based on the file type
            const fileType = file.type;
            let prefix = "data:image/jpeg;base64,";
            if (fileType === "image/png") {
              prefix = "data:image/png;base64,";
            }

            //@ts-ignore
            setImage(prefix + base64WithoutPrefix);
          });
        };
        img.src = e.target!.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const resizeImage = (
    img: HTMLImageElement,
    maxWidth: number,
    maxHeight: number,
    callback: (resizedDataUrl: string) => void
  ) => {
    const canvas = document.createElement("canvas");
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = width * (maxHeight / height);
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d")!;
    ctx.drawImage(img, 0, 0, width, height);
    callback(canvas.toDataURL());
  };

  const handleSubmit = async () => {
    setUploading(true);

    // Trim the inputs
    const trimmedName = name.trim();
    const trimmedCategory = category.trim();
    const trimmedBarcode = customBarcode ? barcode.trim() : barcode; // Only trim barcode if it's custom

    // Update the state with trimmed values (optional, only if you want to update the UI with trimmed values)
    setName(trimmedName);
    setCategory(trimmedCategory);
    if (customBarcode) {
      setBarcode(trimmedBarcode);
    }

    const validationError = validateForm(
      trimmedName,
      trimmedCategory,
      trimmedBarcode
    );
    if (validationError) {
      setErrorMessage(validationError);
      setUploading(false);
      return; // Stop the form submission if validation fails
    }

    const finalQuantity =
      boxItem && boxQuantity > 0 ? boxQuantity * quantity : quantity;
    const finalBoxQuantity = boxItem && boxQuantity > 0 ? boxQuantity : null;
    const timestamp = Date.now();

    // Determine the barcode value to use
    let barcodeValue = trimmedBarcode;
    if (!customBarcode || !barcode) {
      barcodeValue = generateBarcode();
    }

    if (existingBarcodes.includes(barcodeValue)) {
      setErrorMessage(`Barcode "${barcodeValue}" already exists.`);
      setUploading(false);
      return; // Stop form submission if the barcode is a duplicate
    }

    const itemData = {
      name: trimmedName,
      category: trimmedCategory,
      description,
      quantity: finalQuantity, // Adjusted quantity based on boxQuantity if applicable
      boxQuantity: finalBoxQuantity, // Save boxQuantity, or null if it's not applicable
      unitPrice: unitPrice ? parseFloat(unitPrice) : 0, // Convert string to float here
      barcode: barcodeValue, // Set the barcode, either custom or generated
      imageUrl: image,
      timestamp,
      reserve: 0,
      vat: vat,
      archived: false, // Set archived to false by default

    };

    try {
      let docRef = await addDoc(collection(db, "Warehouse"), itemData);
      console.log("Document successfully written with ID:", docRef.id);

      // Assuming you have the user's UID and other details you want to log
      await processHistoryLogClient(docRef.id, "userUID", "create", itemData);
      onClose(); // Close the modal on success
    } catch (error) {
      console.error("Error writing document: ", error);
    } finally {
      setUploading(false);
    }
  };

  // Adjusted validateForm function
  const validateForm = (trimmedName, trimmedCategory, trimmedBarcode) => {
    setErrorMessage("");

    if (!trimmedCategory) return "Please select a category.";
    if (!trimmedName || trimmedName.length < 4)
      return "Name must be at least 4 characters long.";
    if (!description || description.length < 4)
      return "Description must be at least 4 characters long.";
    if (isNaN(quantity) || quantity <= 0)
      return "Quantity must be an integer greater than 0.";
    if (!unitPrice || parseFloat(unitPrice) <= 0)
      return "Unit Price must be more than 0.";
    if (!image) return "An image is required."; // Image validation check
    if (customBarcode) {
      const barcodeError = validateBarcode(trimmedBarcode);
      if (barcodeError) return barcodeError;
    }
    // No validation errors
    return "";
  };

  const validateBarcode = (barcode) => {
    const maxLength = 128; // Set a reasonable max length
    const invalidCharsRegex = /[\/#%.\s]/; // Regex to detect invalid characters

    if (barcode.length > maxLength) {
      return "Barcode is too long.";
    }

    if (invalidCharsRegex.test(barcode)) {
      return "Barcode contains invalid characters.";
    }

    return ""; // Return an empty string if no errors are found
  };

  // Utility function to generate a unique barcode
  function generateBarcode(): string {
    const timestampPart = Date.now().toString(); // Keep it numeric
    const randomPart = Math.floor(Math.random() * 1000000).toString(); // Generate a random number and convert it to a string
    return timestampPart + randomPart;
  }

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // Directly use the selected value to set the category
    setCategory(e.target.value);
  };

  if (!isOpen) return null;

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <h1 style={{ fontSize: 25 }}>Add Warehouse Entry</h1>
        <label>Name:</label>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={modalStyles.inputStyles}
        />
        <label>Type:</label>
        <select
          value={category}
          onChange={handleCategoryChange}
          style={modalStyles.inputStyles}
        >
          <option value="">Select a category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
        <label>Description:</label>
        <input
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={modalStyles.inputStyles}
        />
        <label>Unit Price:</label>
        <input
          type="text"
          placeholder="Unit Price"
          value={unitPrice}
          onChange={(e) => {
            // Allow only numbers and a single decimal point
            const value = e.target.value;
            const regex = /^[0-9]*\.?[0-9]*$/; // Regex to allow numbers with a single decimal point
            if (regex.test(value)) {
              setUnitPrice(value); // Save the value as a string
            }
          }}
          style={modalStyles.inputStyles}
        />
        <label>Quantity:</label>
        <input
          type="number"
          placeholder="Quantity"
          value={quantity}
          onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
          style={modalStyles.inputStyles}
        />

        {/* Checkbox for box item */}
        <label>
          <input type="checkbox" checked={boxItem} onChange={toggleBoxItem} />
          Box Item
        </label>

        {/* Conditionally render the quantity input */}
        {boxItem && (
          <div style={{ marginTop: 10 }}>
            <label>Box Quantity: </label>
            <input
              type="number"
              placeholder="Box Quantity"
              value={boxQuantity}
              onChange={(e) => setBoxQuantity(parseInt(e.target.value, 10))}
              style={modalStyles.inputStyles}
            />
          </div>
        )}

        <label>
          <input
            type="checkbox"
            checked={customBarcode}
            onChange={toggleCustomBarcode}
          />
          Custom Barcode
        </label>

        {/* Conditionally render the barcode input */}
        {customBarcode && (
          <div style={{ marginTop: 10 }}>
            <label>Custom Barcode: </label>
            <input
              type="text"
              placeholder="Barcode"
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
              style={modalStyles.inputStyles}
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "22px",

            marginBottom: "12px",
          }}
        >
          <input
            type="checkbox"
            checked={vat}
            onChange={(e) => setVat(e.target.checked)}
            style={{ marginRight: "10px" }}
          />
          <label>Apply VAT</label>
        </div>

        <input
          type="file"
          onChange={handleImageChange}
          style={modalStyles.inputStyles}
        />
        {errorMessage && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errorMessage}
          </div>
        )}
        <button style={modalStyles.closeButton} onClick={onClose}>
          Close
        </button>
        <button
          onClick={handleSubmit}
          disabled={uploading}
          style={modalStyles.button}
        >
          {uploading ? "Uploading..." : "Add Item"}
        </button>
      </div>
    </div>
  );
};

const modalStyles = {
  inputStyles: {
    borderRadius: "12px", // Softer edges
    border: "1px solid #ced4da", // Lighter border color
    padding: "15px", // Increased padding
    marginTop: "12px", // Consistent margin top
    marginBottom: "12px", // Consistent margin bottom
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Subtle inner shadow for depth
    width: "calc(100% - 30px)", // Adjust width to account for padding
    fontSize: "14px", // Slightly larger font size for readability
  } as CSSProperties,
  headerText: {
    fontSize: "18px", // Larger font size for headers
    fontWeight: "bold", // Bold font weight for emphasis
    color: "#333", // Dark color for text
    textAlign: "center", // Center-align the text
    margin: "10px 0", // Margin for spacing above and below the text
    padding: "5px", // Optional padding
    marginBottom: 30,
    borderBottom: "1px solid #ddd", // Optional bottom border for a subtle line separator
  } as CSSProperties,
  centeredMessage: {
    display: "flex",
    fontSize: 30,
    fontWeight: "700",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100%", // Take full height of the container
    textAlign: "center", // Center text alignment
  } as CSSProperties,
  vehicleIcon: {
    width: "110px", // Set both width and height to the same value for a square shape
    height: "70px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "30%", // Circular shape
    objectFit: "cover",
    paddingLeft: 20,
    paddingRight: 20,
  } as CSSProperties,
  profileIcon: {
    width: "100px", // Set both width and height to the same value for a square shape
    height: "100px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "20%", // Circular shape
    marginBottom: "10px",
    objectFit: "cover",
  } as CSSProperties,
  additionalContentContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,
  leftSide: {
    width: "50%",
    marginRight: 30,
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,

    border: "1px solid #000000",
  } as CSSProperties,
  rightSide: {
    width: "50%",
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    border: "1px solid #000000",
  } as CSSProperties,
  providerDetailRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0", // Add padding for each row if needed
    // Add any additional styling you need for each detail row
  } as CSSProperties,

  barContainer: {
    //width: '60%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: 40,
    border: "1px solid #000000",
    borderRadius: 10,
    //backgroundColor:'pink'
  } as CSSProperties,
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "33.3333%", // Divide the bar container into three equal parts
  } as CSSProperties,

  closeButton: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
    "&:hover": {
      backgroundColor: "#0056b3", // Darker shade on hover
    },
  } as CSSProperties,
  button: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
    "&:hover": {
      backgroundColor: "#0056b3", // Darker shade on hover
    },
  } as CSSProperties,

  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  } as CSSProperties, // Assert the entire object as CSSProperties
  container: {
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "600px", // Set a max width for larger screens
    width: "90%", // Responsive width
    maxHeight: "85vh",
    overflowY: "auto",
  } as CSSProperties,
  contentStyles: {
    // Define your styles here
    marginBottom: "10px",
  } as CSSProperties,
};

export default AddWarehouseModal;
