import React, { ReactNode } from "react";
import '../App.css'; // Assuming your CSS with the animation is in App.css

interface GradientContainerProps {
  children: ReactNode;
}

const GradientContainer: React.FC<GradientContainerProps> = ({ children }) => {
  const animatedGradientContainer: React.CSSProperties = {
    //paddingLeft: 20,
    //paddingRight: 20,
    //paddingTop: 50,
    height: "100vh",
    //background: "linear-gradient(-45deg, #98fbc1, #7fe0fe, #1c345d, #7fe0fe)",
    //background: "linear-gradient(-45deg, #00008B, #00008B, #00FFFF)",
    //background: "linear-gradient(-45deg, #6a39ab, #9734a0, #c5395c,#e7a649,#b5465c)",//instagram
    background: "linear-gradient(-45deg, #004e92,#004e92,#1d344c,#004e92)",//instagram
    backgroundSize: "400% 400%",
    animation: "gradientAnimation 7s ease infinite",
  };

  return (
    <div className="animatedGradientContainer" style={animatedGradientContainer}>
      <div
        style={{
          //borderTopLeftRadius: 10,
          //borderTopRightRadius: 10,
          //backgroundColor: "transparent",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default GradientContainer;
