//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
import { useLanguage } from "../LanguageContext";
import { db } from "../App";
import AddWarehouseModal from "./AddWarehouseModal";
import TitleBar from "./TitleBar";
import OrdersFilter from "./OrdersFilter";
import RowHeader from "./RowHeader";
import WarehouseRow from "./WarehouseRow";
import {
  collection,
  getDocs,
  query,
  orderBy,
  startAfter,
  limit,
  where,
  DocumentSnapshot,
  onSnapshot,
  getCountFromServer,
} from "firebase/firestore";
import { formatEpochDate, formatEpochTime } from "./Functions";
import ItemDetailsModal from "./ItemDetailsModal";
import CategoryModal from "./CategoryModal";
import axios from "axios";
import { SERVER_URL } from "./Types";
import ParsePDFModal from "./ParsePDFModal"; // Import the ParsePDFModal component
import CategoryDeleteModal from "./CategoryDeleteModal";

const Warehouse: React.FC = () => {
  const { language } = useLanguage();

  const translations = {
    Warehouse: language === "kurdish" ? "KURDISH FOR WAREHOUSE" : "Warehouse",
    Categories: language === "kurdish" ? "+ نوێ" : "Categories +",
    CategoriesDelete: language === "kurdish" ? "+ نوێ" : "Delete Category",
    PDFUpload: language === "kurdish" ? "+ نوێ" : "Upload PDF",
    New: language === "kurdish" ? "+ نوێ" : "NEW +",
    Title: language === "kurdish" ? ":ناونیشان" : "Title:",
    Body: language === "kurdish" ? ":ناوەڕۆک" : "Body:",
    Recipients: language === "kurdish" ? ":وەرگرەکان" : "Recipients:",
    Date: language === "kurdish" ? ":بەروار" : "Date:",
  };

  // State Hooks
  const [file, setFile] = useState<File | null>(null);
  const [uploadResponse, setUploadResponse] = useState({
    images: [],
    text: "",
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileModalOpen, setFileModalOpen] = useState(false); // State to control ParsePDFModal visibility

  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const [lastVisibleEntry, setLastVisibleEntry] =
    useState<DocumentSnapshot<any> | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true); // New state to track if more entries are available

  const [filters, setFilters] = useState({
    barcode: "",
    name: "",
    category: "",
    showArchived: false,
    // unitPrice: "", // Removed since it's no longer needed
  });

  const [showInvalidEntries, setShowInvalidEntries] = useState(false);

  // Set default sortCriteria and sortOrder to null (no sorting initially)
  const [sortCriteria, setSortCriteria] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const [warehouseEntries, setWarehouseEntries] = useState<any[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedWarehouseEntry, setSelectedWarehouseEntry] = useState<
    string | null
  >(null);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedItemForDetails, setSelectedItemForDetails] =
    useState<any>(null);

  const [totalItems, setTotalItems] = useState(0);
  const [archivedItems, setArchivedItems] = useState(0);
  const [isLoadingCounts, setIsLoadingCounts] = useState(true); // Add this new state

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        setIsLoadingCounts(true); // Set loading state to true while fetching counts
        // Query for total items using count aggregation
        const totalQuery = collection(db, "Warehouse");
        const totalCountSnapshot = await getCountFromServer(totalQuery);
        setTotalItems(totalCountSnapshot.data().count);

        // Query for archived items using count aggregation
        const archivedQuery = query(
          collection(db, "Warehouse"),
          where("archived", "==", true)
        );
        const archivedCountSnapshot = await getCountFromServer(archivedQuery);
        setArchivedItems(archivedCountSnapshot.data().count);
      } catch (error) {
        console.error("Error fetching item counts:", error);
      } finally {
        setIsLoadingCounts(false); // Set loading state to false after fetching
      }
    };

    fetchCounts();
  }, []);

  // Modal Handlers
  const openAddModal = () => {
    setIsAddModalOpen(true);
  };
  const openDetailsModal = (item: any) => {
    console.log("Opening details modal for item:", item);
    setSelectedItemForDetails(item);
    setIsDetailsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsAddModalOpen(false);
  };

  // Data Formatting
  const formatEntryData = (doc: any) => {
    const data = doc.data();
    let formattedDate = "";
    let formattedDateTime = "";
    let timestamp = data.timestamp
      ? data.timestamp.toDate
        ? data.timestamp.toDate()
        : new Date(data.timestamp)
      : new Date(); // Ensures compatibility with both Timestamp objects and raw data

    formattedDate = formatEpochDate(timestamp.getTime());
    formattedDateTime = formatEpochTime(timestamp.getTime());

    // Ensure quantity is a number
    const quantity = Number(data.quantity);
    if (isNaN(quantity)) {
      console.warn(`Invalid quantity for document ${doc.id}:`, data.quantity);
    }

    // Ensure reserved is a number, default to 0 if undefined
    const reserved = data.reserved !== undefined ? Number(data.reserved) : 0;
    if (isNaN(reserved)) {
      console.warn(`Invalid reserved for document ${doc.id}:`, data.reserved);
    }

    return {
      ...data,
      id: doc.id, // Document ID
      formattedDate,
      formattedDateTime,
      quantity, // Override with numeric quantity
      reserved, // Ensure reserved is defined
    };
  };

  const showInvalidEntriesInState = async () => {
    try {
      const invalidQuery = query(
        collection(db, "Warehouse"),
        where("invalid", "in", ["negative", "both", "aintequal"]) // Fetch entries with 'negative', 'both', or 'aintequal'
      );
      const snapshot = await getDocs(invalidQuery);
      const invalidEntries = snapshot.docs.map((doc) => formatEntryData(doc));

      // Set invalid entries to warehouseEntries state
      setWarehouseEntries(invalidEntries);
      setIsLoading(false); // Ensure loading spinner is off
      setLastVisibleEntry(null); // Reset pagination if needed
      setHasMore(false); // No more entries to load since we're showing filtered results
    } catch (error) {
      console.error("Error fetching invalid entries:", error);
      setIsLoading(false);
    }
  };

  // Firestore Query Builder
  const buildQuery = (isLoadMore: boolean = false) => {
    let baseQuery = collection(db, "Warehouse");
    const constraints: any[] = [];

    // Apply filters
    if (filters.category.trim() !== "") {
      constraints.push(where("category", "==", filters.category.trim()));
    }
    if (filters.barcode.trim() !== "") {
      constraints.push(where("barcode", "==", filters.barcode.trim()));
    }
    if (filters.name.trim() !== "") {
      constraints.push(where("name", "==", filters.name.trim()));
    }
    if (filters.showArchived) {
      constraints.push(where("archived", "==", true));
    } else {
      constraints.push(where("archived", "==", false));
    }

    // Apply sorting
    if (sortCriteria && sortOrder) {
      constraints.push(orderBy(sortCriteria, sortOrder));
      constraints.push(orderBy("__name__", "asc")); // Secondary sort by document ID for determinism
    } else {
      constraints.push(orderBy("__name__", "asc")); // Default sort
    }

    // Add pagination limit
    constraints.push(limit(20));

    // If loading more, add startAfter with DocumentSnapshot
    if (isLoadMore && lastVisibleEntry) {
      constraints.push(startAfter(lastVisibleEntry));
    }

    return query(baseQuery, ...constraints);
  };

  // Fetch Entries Function using getDocs
  const fetchEntries = async (isLoadMore: boolean = false) => {
    const currentQuery = buildQuery(isLoadMore);
    try {
      if (isLoadMore) {
        setIsLoadingMore(true);
      } else {
        setIsLoading(true);
      }

      const snapshot = await getDocs(currentQuery);
      const entries = snapshot.docs.map((doc) => formatEntryData(doc));

      if (isLoadMore) {
        setWarehouseEntries((prevEntries) => {
          // Filter out any duplicates
          const newEntries = entries.filter(
            (entry) => !prevEntries.some((prev) => prev.id === entry.id)
          );
          return [...prevEntries, ...newEntries];
        });
      } else {
        setWarehouseEntries(entries); // Set the regular entries here
      }

      const lastDoc = snapshot.docs[snapshot.docs.length - 1];
      if (lastDoc) {
        setLastVisibleEntry(lastDoc);
      } else {
        setLastVisibleEntry(null);
        setHasMore(false); // No more entries to load
      }

      if (isLoadMore) {
        setIsLoadingMore(false);
      } else {
        setIsLoading(false);
      }

      // Call the function to listen to real-time updates
      setupRealTimeListener(currentQuery); // Add the real-time listener
    } catch (error) {
      console.error("Error fetching documents: ", error);
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  // Function to set up real-time listener
  const setupRealTimeListener = (currentQuery) => {
    const unsubscribe = onSnapshot(currentQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          // Handle new document added to collection
          setWarehouseEntries((prevEntries) => {
            // Only add if the document is not already present
            if (!prevEntries.some((entry) => entry.id === change.doc.id)) {
              return [...prevEntries, formatEntryData(change.doc)];
            }
            return prevEntries;
          });
        }
        if (change.type === "modified") {
          // Handle document modification
          setWarehouseEntries((prevEntries) =>
            prevEntries.map((entry) =>
              entry.id === change.doc.id
                ? { ...entry, ...formatEntryData(change.doc) }
                : entry
            )
          );
        }
        if (change.type === "removed") {
          // Handle document deletion
          setWarehouseEntries((prevEntries) =>
            prevEntries.filter((entry) => entry.id !== change.doc.id)
          );
        }
      });
    });

    // Return the unsubscribe function if needed later
    return unsubscribe;
  };

  // Initial Fetch and Re-fetch on Dependencies Change
  useEffect(() => {
    if (!showInvalidEntries) {
      // Only fetch regular entries when showInvalidEntries is false
      setWarehouseEntries([]);
      setLastVisibleEntry(null);
      setHasMore(true); // Reset hasMore when filters or sorting change
      setIsLoading(true);
      fetchEntries(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortCriteria, sortOrder, showInvalidEntries]);

  // Scroll Handler for Infinite Scroll
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    // Trigger fetching more entries when the user is within 200px of the bottom
    if (
      scrollHeight - scrollTop <= clientHeight + 200 &&
      !isLoadingMore &&
      hasMore
    ) {
      loadMoreEntries();
    }
  };

  const loadMoreEntries = () => {
    if (!isLoadingMore && hasMore && lastVisibleEntry) {
      fetchEntries(true);
    }
  };

  // Sort Change Handler
  const handleSortChange = (criteria: string) => {
    if (sortCriteria === criteria) {
      // Toggle sort order if the same criteria is selected
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      // Set new sort criteria and default sort order
      setSortCriteria(criteria);
      setSortOrder("desc"); // Default to descending when changing criteria
    }

    // Reset pagination and entries
    setWarehouseEntries([]);
    setLastVisibleEntry(null);
    setHasMore(true); // Reset hasMore when sort criteria changes
    setIsLoading(true);
    fetchEntries(false);
  };

  // PDF Upload Handlers
  const handlePDFUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleModalClose = () => {
    setFileModalOpen(false);
    setFile(null); // Reset the file state
    setUploadResponse({ images: [], text: "" }); // Reset the upload response
    setResponseStatus(""); // Reset the status message
    setResponseMessage(""); // Reset the response message
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  const submitFile = () => {
    if (file) {
      const formData = new FormData();
      formData.append("pdf", file);

      axios
        .post(`${SERVER_URL}/parse-pdf`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            // Parse the JSON string into an array of objects
            let textDataArray;
            try {
              textDataArray = JSON.parse(response.data.parsedText);
            } catch (error) {
              console.error("Error parsing text data:", error);
              setResponseStatus("error");
              setResponseMessage("Failed to parse text data.");
              return;
            }

            // Now map your images to this array
            const parsedData = response.data.images.map((image, index) => ({
              image: `data:image/jpeg;base64,${image.data}`,
              json: textDataArray[index], // Now this is a JSON object
            }));

            setUploadResponse({ parsedData });
            setResponseStatus("success");
            setResponseMessage("File processed successfully.");
            setFileModalOpen(true);
          } else {
            setResponseStatus("error");
            setResponseMessage("Unexpected response from the server.");
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          let errorMessage = "Error processing the file.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          }
          setResponseStatus("error");
          setResponseMessage(errorMessage);
        });
    }
  };

  useEffect(() => {
    if (file) {
      submitFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  // Category Modal Handlers
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const handleOpenCategoryModal = () => setIsCategoryModalOpen(true);
  const handleCloseCategoryModal = () => setIsCategoryModalOpen(false);

  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] =
    useState(false);
  const openDeleteCategoryModal = () => setIsDeleteCategoryModalOpen(true);
  const closeDeleteCategoryModal = () => setIsDeleteCategoryModalOpen(false);

  // Styling
  const headerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 40px 10px 40px",
    flexDirection: language === "kurdish" ? "row-reverse" : "row", // Adjust direction for Kurdish
  };

  const button: React.CSSProperties = {
    fontSize: "13px",
    color: "white", // Text color
    fontWeight: "500",
    backgroundColor: "#1c345d", // Background color
    //borderRadius: "5px", // Make it round
    padding: "10px 20px", // Padding for sizing (adjust as needed)
    border: "none", // Remove default button border
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
  };

  const parentContainerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column", // Stack children vertically
    height: "100vh", // Set the height of this container to the full viewport height
    // Subtract the height of any other elements like the header and any margins they have
  };
  const scrollableListContainerStyles: React.CSSProperties = {
    maxHeight: "62vh", // Adjust the height as necessary
    overflowY: "auto", // Enable vertical scrolling
    //backgroundColor:'pink'
  };

  const titles = {
    image: "",
    barcode: "#",
    name: "NAME",
    category: "CATEGORY",
    description: "DESCRIPTION",
    quantity: "STOCK",
    reserved: "RESERVED",
    unitPrice: "UNIT PRICE", // Removed since it's no longer needed
    archived: "ARCHIVED",
  };

  const percentages = {
    image: "10%",
    barcode: "12%",
    name: "11%",
    category: "11%",
    description: "12%",
    quantity: "11%",
    reserved: "11%",
    unitPrice: "11%", // Removed since it's no longer needed
    archived: "11%",
  };

  const handleFilterChange = (updatedFilters: any) => {
    setFilters(updatedFilters);
  };

  // Optional: Log quantities whenever `warehouseEntries` change
  useEffect(() => {
    if (warehouseEntries.length > 0) {
      console.log(
        "Current Warehouse Entries - Quantities:",
        warehouseEntries.map((entry) => entry.quantity)
      );
    }
  }, [warehouseEntries]);

  return (
    <div style={parentContainerStyles}>
      {/* Header */}
      <div style={headerStyles}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1 style={{ fontSize: 35, marginRight: "20px" }}>
            {translations.Warehouse}
          </h1>
          <p
            style={{
              marginRight: "20px",
              padding: "5px 10px",
              backgroundColor: "#e0f7fa", // Light blue background for total items
              borderRadius: "8px",
              color: "#00796b", // Green text
              fontWeight: "bold",
            }}
          >
            {isLoadingCounts ? (
              <div className="spinner-small"></div>
            ) : (
              <>Active items: {totalItems-archivedItems}</>
            )}
          </p>

          <p
            style={{
              marginRight: "20px",
              padding: "5px 10px",
              backgroundColor:
                totalItems === archivedItems ? "#ffe6e6" : "#ffe6e6", // Light red if all are archived, else light blue
              borderRadius: "8px",
              color: "red", // Red if archived, green if active
              fontWeight: "bold",
            }}
          >
            {isLoadingCounts ? (
              <div className="spinner-small"></div>
            ) : (
              <>Archived items: {archivedItems}</>
            )}
          </p>

          <button
            style={{
              ...button,
              marginLeft: "20px",
              backgroundColor: showInvalidEntries ? "blue" : "#1c345d", // Purple when invalid entries are shown, default when not
              color: showInvalidEntries ? "white" : "white", // Optionally change the text color
              fontWeight: showInvalidEntries ? "bold" : "normal", // Make text bold when active
            }}
            onClick={() => {
              if (showInvalidEntries) {
                // If switching back to show all entries, fetch the normal entries
                setShowInvalidEntries(false);
                fetchEntries(false); // Fetch the regular entries again
              } else {
                // If showing invalid entries, load them
                setShowInvalidEntries(true);
                showInvalidEntriesInState(); // Call the function to show invalid entries
              }
            }}
          >
            {showInvalidEntries ? "Show All Entries" : "Show Invalid Entries"}
          </button>

          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
            accept=".pdf, .xlsx, .xls, .zip" // Allow PDF, Excel, and ZIP files
          />
          <button
            style={{ ...button, marginLeft: "20px" }}
            onClick={handlePDFUpload}
          >
            {translations.PDFUpload}
          </button>
          <button
            style={{ ...button, marginLeft: "20px" }}
            onClick={handleOpenCategoryModal}
          >
            {translations.Categories}
          </button>
          <button
            style={{ ...button, marginLeft: "20px" }}
            onClick={openDeleteCategoryModal}
          >
            {translations.CategoriesDelete}
          </button>
          <button
            style={{ ...button, marginLeft: "20px" }}
            onClick={openAddModal}
          >
            {translations.New}
          </button>
        </div>

        <div></div>
      </div>

      {/* Response Message */}
      {responseStatus && (
        <div
          style={{
            color: responseStatus === "error" ? "red" : "green",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          {responseMessage}
        </div>
      )}

      {/* Filters */}
      <OrdersFilter onFilterChange={handleFilterChange} />

      {/* Row Header with Sorting */}
      <RowHeader
        titles={titles}
        percentages={percentages}
        sortCriteria={sortCriteria}
        sortOrder={sortOrder}
        onSortChange={handleSortChange}
      />

      {/* Content */}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="spinner-large"></div>
        </div>
      ) : warehouseEntries.length > 0 ? (
        <div style={scrollableListContainerStyles} onScroll={handleScroll}>
          {warehouseEntries.map((entry: any) => (
            <WarehouseRow
              key={entry.id} // Ensure unique key
              entry={entry}
              openDetailsModal={openDetailsModal}
              showInvalidEntries={showInvalidEntries} // Pass the flag as a prop
            />
          ))}
          {/* Display Loading More or End of List */}
          <div style={{ textAlign: "center", padding: "10px 0" }}>
            {isLoadingMore && <div className="spinner-large"></div>}
            {!hasMore && <p style={{ fontSize: "16px" }}>End of List</p>}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "24px", marginTop: -500 }}>No Entries...</p>
        </div>
      )}

      {/* Modals */}
      {isDetailsModalOpen && selectedItemForDetails && (
        <ItemDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          item={selectedItemForDetails} // Use selectedItemForDetails here
        />
      )}
      {isAddModalOpen && (
        <AddWarehouseModal isOpen={isAddModalOpen} onClose={onCloseModal} />
      )}
      {isCategoryModalOpen && (
        <CategoryModal
          isOpen={isCategoryModalOpen}
          onClose={handleCloseCategoryModal}
        />
      )}
      <ParsePDFModal
        isOpen={fileModalOpen}
        onClose={handleModalClose} // Use handleModalClose here
        //@ts-ignore
        parsedData={uploadResponse.parsedData} // Here you should pass only the array
      />
      <CategoryDeleteModal
        isOpen={isDeleteCategoryModalOpen}
        onClose={closeDeleteCategoryModal}
      />
    </div>
  );
};

export default Warehouse;
