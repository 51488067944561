//when i hover over a tab when its collapsed make it show tag of text
import React, { useState } from "react";
import { MdBroadcastOnHome, MdLanguage, MdOutlineShoppingCart } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { useLanguage } from "../LanguageContext";
import { LiaFileExportSolid, LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FaShoppingCart } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";

interface SidebarProps {
  onTabSelect: (tabName: string) => void;
  selectedTab: string;
}

function Sidebar({ onTabSelect, selectedTab }: SidebarProps) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);

  const { language, updateLanguage } = useLanguage(); // Get the current language

  const translations = {
    NOTIFICATIONS: language === "kurdish" ? "ئاگادارییەکان" : "NOTIFICATIONS",
    SUPPORT: language === "kurdish" ? "یارمەتی" : "SUPPORT",
    INVOICES: language === "kurdish" ? "kurdish for products" : "INVOICES",
    REPORTS: language === "kurdish" ? "kurdish for products" : "REPORTS",
    PRODUCTS: language === "kurdish" ? "kurdish for products" : "PRODUCTS",
    PROSPECTS: language === "kurdish" ? "kurdish for products" : "PROSPECTS",
    CARTS: language === "kurdish" ? "kurdish for products" : "CARTS",

    WAREHOUSE: language === "kurdish" ? "KURDISH FOR WAREHOUSE" : "WAREHOUSE",
    LANGUAGE: language === "kurdish" ? "زمان" : "Language",
    KURDISH: language === "kurdish" ? "کوردی" : "Kurdish",
    ENGLISH: language === "kurdish" ? "ئینگلیزی" : "English",
  };

  const tabs = [

    /*
    {
      icon: <MdBroadcastOnHome />,
      label: translations.NOTIFICATIONS,
      key: "NOTIFICATIONS",
    },
    { icon: <BiSupport />, label: translations.SUPPORT, key: "SUPPORT" },
    */

    { icon: <BiSupport />, label: translations.WAREHOUSE, key: "WAREHOUSE" },
    { icon: <LiaFileInvoiceDollarSolid />, label: translations.INVOICES, key: "INVOICES" },
    { icon: <LiaFileExportSolid />, label: translations.REPORTS, key: "REPORTS" },
    { icon: <MdOutlineShoppingCart />, label: translations.PRODUCTS, key: "PRODUCTS" },
    { icon: <MdBroadcastOnHome />, label: translations.PROSPECTS, key: "PROSPECTS" },
    { icon: <IoCartOutline />, label: translations.CARTS, key: "CARTS" },


  ];

  const sidebarStyles: React.CSSProperties = {
    //width: '250px',
    backgroundColor: "#1d344c",
    width: sidebarCollapsed ? "63px" : "250px", //make 70 but change text to invis on close
    transition: "width 0.3s ease", // Add a transition for smooth animation
    //padding: '10px',
  };

  const toggleButtonContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "flex-end", // Align the button to the right
    paddingRight: "6px", // Add some spacing to the right
  };

  const getArrowIcon = () => {
    if (sidebarCollapsed) {
      return language === "kurdish" ? <BsArrowBarLeft /> : <BsArrowBarRight />;
    } else {
      return language === "kurdish" ? <BsArrowBarRight /> : <BsArrowBarLeft />;
    }
  };

  const toggleLanguage = () => {
    setLanguageOpen(!languageOpen);
  };

  function handleLanguageChange(language: any) {
    updateLanguage(language);
    setLanguageOpen(!languageOpen);
  }

  const listItemStyleBasedOnLanguage: React.CSSProperties = {
    ...listItemStyles,
    flexDirection: language === "kurdish" ? "row-reverse" : "row", // Reverse the order for Kurdish
  };

  return (
    <div style={sidebarStyles}>
      <div style={toggleButtonContainerStyles}>
        <button
          style={toggleButtonStyles}
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
        >
          {getArrowIcon()}
        </button>
      </div>

      <ul style={tabsContainer}>
        {tabs.map((tab, index) => (
          <li
            key={index}
            style={{
              ...listItemStyleBasedOnLanguage,
              color: tab.key === selectedTab ? "black" : "white",
              backgroundColor:
                tab.key === selectedTab ? "#e0e0e0" : "transparent",
            }}
            onClick={() => onTabSelect(tab.key)}
          >
            <div style={{ ...iconCircle, ...iconClass }}>{tab.icon}</div>
            <span>{tab.label}</span>
          </li>
        ))}
    
        {languageOpen && (
          <div style={dropdown}>
            <button
              style={{ fontSize: 15, marginBottom: 20 }}
              onClick={() => handleLanguageChange("english")}
            >
              {translations.ENGLISH}
            </button>
            <button
              style={{ fontSize: 15 }}
              onClick={() => handleLanguageChange("kurdish")}
            >
              {translations.KURDISH}
            </button>
          </div>
        )}
      </ul>
    </div>
  );
}

{/*!sidebarCollapsed && (
  <div onClick={toggleLanguage} style={languageInfo}>
    <MdLanguage style={icon} />
    <span>{translations.LANGUAGE}</span>
  </div>
)*/}

const toggleButtonStyles: React.CSSProperties = {
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  display: "flex",
  fontSize: "22px",
  color: "white",
  //justifyContent: 'flex-end',
  //alignItems: 'center',
  padding: "8px",
  marginBottom: "10px",
};

const icon: React.CSSProperties = {
  fontSize: 20, // Set the desired icon size
  marginRight: 5,
  color: "white",
};

const languageInfo: React.CSSProperties = {
  marginTop: 150,
  display: "flex",
  cursor: "pointer",
  alignSelf: "center",
  color: "white",
};

const dropdown: React.CSSProperties = {
  //position: 'absolute',
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  paddingTop: "20px",
  backgroundColor: "#1d344c",
  border: "1px solid black",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  padding: "20px",
};

const tabsContainer: React.CSSProperties = {
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  //paddingTop: '10%',
  //paddingLeft: '7%',
  height: "100%",
  overflow: "hidden",
};

const listItemStyles: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  color: "white",
  cursor: "pointer", // Add this line
  width: "100%", // Add this line to make the tab take up the entire horizontal space
};

const iconClass = {
  color: "black", // Use the prop value if provided, or default to 'silver'
};

const iconCircle: React.CSSProperties = {
  backgroundColor: "silver",
  borderRadius: "50%",
  minWidth: "32px",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "12px",
  marginLeft: "20px",
  cursor: "pointer", // Add this line
};

export default Sidebar;
