import React, { useEffect, useState } from 'react';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import {db, firebase } from '../App';
import './Pricing.css';
import { PricingData } from './Types';

const Pricing = () => {
    const [pricing, setPricing] = useState<PricingData[]>([]); // Initialize pricing as an empty array of PricingData type
    const [editedItem, setEditedItem] = useState<PricingData | null>(null);
    const [newPrice, setNewPrice] = useState<number | null>(null);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Pricing'));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        //@ts-ignore
        setPricing(data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (pricingItem: PricingData) => {
    setEditedItem(pricingItem);
    setNewPrice(pricingItem.Value); // Initialize the input field with the current price
  };

  const handleSave = async () => {
    if (editedItem && newPrice !== null) {
      try {
        // Get the document reference based on the editedItem's ID
        const pricingItemDocRef = doc(db, 'Pricing', editedItem.id);

        // Update the 'Value' field in the document
        await updateDoc(pricingItemDocRef, {
          Value: newPrice,
        });

        // Update the local state with the new price
        setPricing((prevPricing) =>
          prevPricing.map((item) =>
            item.id === editedItem.id ? { ...item, Value: newPrice } : item
          )
        );

        // Clear the edited item and new price
        setEditedItem(null);
        setNewPrice(null);
      } catch (error) {
        console.error('Error saving edited item:', error);
      }
    }
  };


  return (
    <div>
        <div className="pricing-scroll-container">

      <ul className="pricing-container">
      <h1 style={{ marginTop:'10px',marginBottom:'30px',fontSize:35 }}>Pricing</h1>

        {pricing.map((pricingItem) => (
          <li key={pricingItem.id} className="pricing-item">
            <span className="pricing-item-name">{pricingItem.id}:</span>
            <div className="pricing-item-content">
              {editedItem === pricingItem ? (
                <>
                  <input
                    type="number"
                    value={newPrice || ''}
                    onChange={(e) => setNewPrice(Number(e.target.value))}
                  />
                  <button className="pricing-item-button" onClick={handleSave}>Save</button>
                </>
              ) : (
                <>
                  <button className="pricing-item-button" onClick={() => handleEdit(pricingItem)}>Edit</button>
                  <span className="pricing-item-value">${pricingItem.Value}</span>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
    </div>

  );
  
};

export default Pricing;
