import React, { useState, useEffect, CSSProperties } from 'react';
import { db } from '../App';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';

interface CategoryDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CategoryDeleteModal: React.FC<CategoryDeleteModalProps> = ({ isOpen, onClose }) => {
  const [categories, setCategories] = useState<{ id: string; name: string }[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      const querySnapshot = await getDocs(collection(db, "Categories"));
      const fetchedCategories = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().Category as string }));
      setCategories(fetchedCategories);
      setIsLoading(false);
    };

    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  const handleDelete = async () => {
    if (!selectedCategory) {
      setErrorMessage('Please select a category to delete.');
      return;
    }
  
    setIsLoading(true);
    // Find the name of the selected category since the selectedCategory holds the ID.
    const selectedCategoryName = categories.find(cat => cat.id === selectedCategory)?.name;
    const q = query(collection(db, "Warehouse"), where("category", "==", selectedCategoryName));
  
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      setErrorMessage('This category is being used by one or more items in the warehouse. Please remove the category from those items before deleting.');
    } else {
      await deleteDoc(doc(db, "Categories", selectedCategory));
      onClose(); // Close the modal on success
    }
    setIsLoading(false);
  };
  

  if (!isOpen) return null;

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <h1>Delete Category</h1>
        {errorMessage && <div style={modalStyles.errorMessage}>{errorMessage}</div>}
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          style={modalStyles.inputStyles}
        >
          <option value="">Select a category</option>
          {categories.map(category => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
        <div style={modalStyles.buttonsContainer}>
          <button style={modalStyles.closeButton} onClick={onClose}>Close</button>
          <button style={modalStyles.addButton} onClick={handleDelete}>Delete</button>
        </div>
      </div>
    </div>
  );
};


// Reuse and adapt the modalStyles from the previous example for consistency
const modalStyles: { [key: string]: CSSProperties } = {

  
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  },
  container: {
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "400px", // Set a max width for larger screens
    width: "90%", // Responsive width
    maxHeight: "85vh",
    overflowY: "auto",
  },
  inputStyles: {
    borderRadius: "12px", // Softer edges
    border: "1px solid #ced4da", // Lighter border color
    padding: "15px", // Increased padding
    marginTop: "12px", // Consistent margin top
    marginBottom: "12px", // Consistent margin bottom
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Subtle inner shadow for depth
    width: "calc(100% - 30px)", // Adjust width to account for padding
    fontSize: "14px", // Slightly larger font size for readability
  },
  closeButton: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
    "&:hover": {
      backgroundColor: "#0056b3", // Darker shade on hover
    },
  } as CSSProperties,
  button: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
   
  },
  addButton: {
    fontSize: "16px",
    fontWeight: "600",
    backgroundColor: "#28a745", // Success color, similar to Bootstrap's .btn-success
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
   
  },
};


export default CategoryDeleteModal;
