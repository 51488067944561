import React, { useEffect, useState } from 'react';
import { SERVER_URL, UserData } from './Types';
import './ZipCodeManagementModal.css';
import { doc, getDoc, deleteDoc, updateDoc, setDoc, doc as firebaseDoc } from 'firebase/firestore';
import { db } from '../App';

interface VerifyModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedUid: any;
}

interface ApplicationData {
  [key: string]: any;
}

const VerifyProviderModal: React.FC<VerifyModalProps> = ({ isOpen, onClose, selectedUid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [applicationData, setApplicationData] = useState<ApplicationData | string | null>(null);

  useEffect(() => {
    if (selectedUid) {
      fetchApplicationData(selectedUid);
    }
  }, [selectedUid]);

  const fetchApplicationData = async (uid: string) => {
    try {
      const applicationDocRef = doc(db, 'ProviderApps', uid);
      const applicationDocSnapshot = await getDoc(applicationDocRef);

      if (applicationDocSnapshot.exists()) {
        const data = applicationDocSnapshot.data();
        setApplicationData(data);
      } else {
        setApplicationData("No application submitted");
      }
    } catch (error) {
      console.error('Error fetching application data:', error);
      throw error;
    }
  };

  const downloadFile = (fileUri: string, fileName: string) => {
    const anchor = document.createElement('a');
    anchor.href = fileUri;
    anchor.download = fileName;
    anchor.click();
  };

  const verifyAccount = async () => {
    try {
      const providerDocRef = firebaseDoc(db, 'Providers', selectedUid);
      await updateDoc(providerDocRef, {
        verified: true,
      });
      onClose();
    } catch (error) {
      console.error('Error verifying account:', error);
    }
  };

  const denyApplication = async () => {
    try {
      const applicationDocRef = doc(db, 'ProviderApps', selectedUid);
      await deleteDoc(applicationDocRef);
      onClose();
    } catch (error) {
      console.error('Error denying application:', error);
    }
  };

  return (
    <div className={`dispatch-modalM ${isOpen ? 'open' : ''}`}>
      <div className={`modal-overlayM ${isOpen ? 'open-overlay' : ''}`} onClick={onClose}></div>
      <div className={`modal-contentM ${isOpen ? 'open-content' : ''}`}>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <h2>Verification Form</h2>
            <div style={{ maxHeight: '400px', overflow: 'auto' }}>
              <ul style={{ padding: 30, border: '1px solid #ccc', borderRadius: '5px', margin: 30 }}>
                {applicationData === "No application submitted" ? (
                  <li>No application submitted</li>
                ) : (
                  <ul>
                    {Object.entries(applicationData || {}).map(([key, value]) => (
                      <li key={key}>
                        <label>{key}:</label>
                        {Array.isArray(value) ? (
                          <ul>
                            {value.map((item: Record<string, any>, index) => (
                              <li key={index}>
                                <ul>
                                  {Object.entries(item).map(([itemKey, itemValue]) => (
                                    <li key={itemKey}>
                                      <label>{itemKey}:</label>
                                      {itemKey === 'uri' && itemValue ? (
                                        <a
                                          href={itemValue}
                                          onClick={() => downloadFile(itemValue, itemValue.split('/').pop())}
                                        >
                                          Download {itemValue.split('/').pop()}
                                        </a>
                                      ) : (
                                        <span>{itemValue}</span>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>{value}</p>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </ul>
            </div>
            {applicationData === "No application submitted" ? (
              <button onClick={onClose}>Close</button>
            ) : (
              <div>
                <button onClick={verifyAccount}>Verify Account</button>
                <button onClick={denyApplication}>Deny Application</button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyProviderModal;
