import React from 'react';
import './Dashboard.css'; // Make sure to create a corresponding CSS file with your styles

const Dashboard = () => {
  // Example data for the "Things to do" list
  const tasks = [
    { id: '1', title: 'Review and sign contractor agreements', dueDate: 'Tue, 9 Jun', overdue: true },
    { id: '2', title: 'Add new starters', dueDate: 'Wed, 10 Jun', overdue: false },
    { id: '3', title: 'Review time off requests', dueDate: '1 hour ago', overdue: false },
    // ...more tasks
  ];


// Mock data for demonstration purposes
const whosAway = [
  { name: 'Paul Grant', type: 'Medical leave', date: 'Only today' },
  { name: 'Beatriz Costa', type: 'Personal leave', date: 'Mar 16 - Mar 18' },
  // ... add more as necessary
];

const teamByCountry = [
  { country: 'United States', count: 52 },
  { country: 'Portugal', count: 32 },
  // ... add more as necessary
];

// Component for "Who's Away"
const WhosAway = () => (
  <div className="whos-away">
    <h2>Who's away</h2>
    <ul>
      {whosAway.map((person, index) => (
        <li key={index}>
          {person.name} - {person.type} - {person.date}
        </li>
      ))}
    </ul>
  </div>
);

// Component for "Team by Country"
const TeamByCountry = () => (
  <div className="team-by-country">
    <h2>Team by country</h2>
    <ul>
      {teamByCountry.map((team, index) => (
        <li key={index}>
          {team.country}: {team.count}
        </li>
      ))}
    </ul>
  </div>
);

  // Component for "Overview"
  const Overview = () => (
    <div className="overview">
      <div className="overview-card">
        <div className="overview-title">Headcount</div>
        <div className="overview-number">512</div>
        <div className="overview-change">+10 from last week</div>
      </div>
      <div className="overview-card">
        <div className="overview-title">Joiners</div>
        <div className="overview-number">16</div>
        <div className="overview-change">+5 from last week</div>
      </div>
      <div className="overview-card">
        <div className="overview-title">Leavers</div>
        <div className="overview-number">8</div>
        <div className="overview-change">+2 from last week</div>
      </div>
    </div>
  );


  return (
    <div className="dashboard">
    <header className="dashboard-header">
      <h1>Good afternoon, Susie 👋</h1>
    </header>

    <div className="dashboard-content">

    <div className="left-column">
      <Overview /> {/* Make sure you have this component defined */}
      <section className="tasks">
        <h2>Things to do</h2>
        <div className="task-list">
          {tasks.map((task) => (
            <div key={task.id} className={`task-item ${task.overdue ? 'overdue' : ''}`}>
              <div className="task-title">{task.title}</div>
              <div className="task-due-date">{task.dueDate}</div>
            </div>
          ))}
        </div>
      </section>
    </div>

    <div className="right-column">
      <WhosAway /> {/* Make sure you have this component defined */}
      <TeamByCountry /> {/* Make sure you have this component defined */}
    </div>
    </div>

  </div>
);
};

export default Dashboard;
