// Dashboard.tsx
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import OrderRow from './OrderRow';
import {  Order } from './Types';

interface OrdersProps {
  todos: Order[];
  dashboardView: string;
  handleDashboardViewChange: (view: string) => void;
  renderMarkers: (map: any, maps: any) => void;
  defaultProps: {
    center: { lat: number; lng: number };
    zoom: number;
  };
}

const Orders: React.FC<OrdersProps> = ({
  todos,
  dashboardView,
  handleDashboardViewChange,
  renderMarkers,
  defaultProps,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      <div style={styles.dashboardTabs}>
        <button
          style={styles.tabButton}
          onClick={() => handleDashboardViewChange('LIST')}
        >
          List
        </button> 
        <button
          style={styles.tabButton}
          onClick={() => handleDashboardViewChange('MAP')}
        >
          Map
        </button>
      </div>
      {dashboardView === 'LIST' ? (
        <div>
          {todos.map((order, index) => (
            <OrderRow
              key={index}
              order={{
                orderID: order.orderID,
                orderType: order.service,
                orderedBy: order.orderedBy,
                orderDateService: order.dateOfService,
                orderTimeService: order.timeOfService,
                orderDatePlaced: order.dateOrderPlaced,
                orderStatus: order.orderStatus,
                address: {
                  cityAddress: order.address.cityAddress,
                  numberAddress: order.address.numberAddress,
                  streetAddress: order.address.streetAddress,
                  stateAddress: order.address.stateAddress,
                  zipCodeAddress: order.address.zipCodeAddress,
                  phoneAddress: order.address.phoneAddress,
                  location: {
                    latitude: order.address.location.latitude,
                    longitude: order.address.location.longitude,
                    latitudeDelta: order.address.location.latitudeDelta,
                    longitudeDelta: order.address.location.longitudeDelta,
                  },
                },
              }}
              isModalOpen={isModalOpen} // Pass modal state down to OrderRow
              toggleModal={toggleModal} // Pass toggleModal function down
            />
          ))}
        </div>
      ) : (
        <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyBsP52q1Y_t08O_ndbiyUuFNi9KKGgATrs', // Replace with your API key
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          />
        </div>
      )}
    </div>
  );
};

interface Styles {
    [key: string]: React.CSSProperties;
  }

  const styles: Styles = {
    dashboardTabs: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  tabButton: {
    border: 'none',
    fontSize: '15px',
    margin: '0 auto',
    display: 'block',
    padding: '5px 20px',
  },
};

export default Orders;
