//@ts-nocheck

import React, { useEffect, useState, CSSProperties } from "react";
import { collection, doc, getDoc, writeBatch } from "firebase/firestore";
import { db } from "../App";
import { processHistoryLogClient } from "./Functions";

interface CartAdminModalProps {
  isOpen: boolean;
  onClose: () => void;
  cartId: string;
}

const CartAdminModal: React.FC<CartAdminModalProps> = ({
  isOpen,
  onClose,
  cartId,
}) => {
  const [cartDetails, setCartDetails] = useState<any>(null);
  const [storeDetails, setStoreDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isClearing, setIsClearing] = useState(false);

  useEffect(() => {
    if (!cartId) return;

    const fetchCartDetails = async () => {
      try {
        const cartRef = doc(db, "Cart", cartId);
        const cartSnap = await getDoc(cartRef);
    
        if (cartSnap.exists()) {
          const cartData = cartSnap.data();
          const detailedItems = await Promise.all(
            cartData.items.map(async (item) => {
              const itemRef = doc(db, "Warehouse", item.id);
              const itemSnap = await getDoc(itemRef);
              if (itemSnap.exists()) {
                const itemData = itemSnap.data();
                return {
                  ...item,
                  unitPrice: itemData.unitPrice,
                  imageUrl: itemData.imageUrl,
                  barcode: itemData.barcode,
                  description: itemData.description,
                  category: itemData.category,
                };
              } else {
                return item;
              }
            })
          );
    
          setCartDetails({ ...cartData, items: detailedItems });
        } else {
          console.error("No such document!");
        }
    
        if (cartId !== "admin") {
          const prospectRef = doc(db, "Prospects", cartId);
          const prospectSnap = await getDoc(prospectRef);
          if (prospectSnap.exists()) {
            setStoreDetails(prospectSnap.data());
          }
        }
    
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching cart details:", error);
        setIsLoading(false);
      }
    };
    

    fetchCartDetails();
  }, [cartId]);

  const clearCart = async () => {
    console.log("clearCart called");
    if (!cartDetails || cartDetails.items.length === 0) {
      console.log("Cart is already empty.");
      return;
    }

    setIsClearing(true);

    const batch = writeBatch(db);

    for (const item of cartDetails.items) {
      if (!item.id) {
        console.error("Item does not have a valid docId:", item);
        continue; // Skip this item and move to the next one
      }

      const warehouseItemRef = doc(db, "Warehouse", item.id);
      const warehouseItemSnap = await getDoc(warehouseItemRef);
      if (warehouseItemSnap.exists()) {
        const warehouseItemData = warehouseItemSnap.data();
        const currentReserved = warehouseItemData.reserved || 0;
        const newReserved = currentReserved - item.quantity;
        batch.update(warehouseItemRef, { reserved: newReserved });

        // Log the unreservation
        processHistoryLogClient(item.id, cartId, "unreserve", {
          previousReserved: currentReserved,
          newReserved: newReserved,
          additionalInfo: {
            unreservedBy: cartId,
            unreservedQuantity: item.quantity,
          },
        });
      } else {
        console.error("Warehouse item not found:", item.id);
      }
    }

    // Clear the cart in Firestore
    const cartRef = doc(db, "Cart", cartId);
    batch.set(cartRef, { items: [] });

    try {
      await batch.commit();
      setCartDetails({ ...cartDetails, items: [] });
      console.log("Cart cleared and quantities updated successfully.");
    } catch (error) {
      console.error("Error clearing cart and updating quantities: ", error);
    } finally {
      setIsClearing(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <button
          style={modalStyles.topRightCloseButton}
          onClick={onClose}
          aria-label="Close"
        >
          ×
        </button>
        <h1 style={{ fontSize: 25 }}>Cart Details</h1>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <h3>Store Name: {storeDetails?.name || "admin"}</h3>
            <p>Store Address: {storeDetails?.address || "N/A"}</p>
            <p>Number of Items: {cartDetails?.items.length}</p>
            <p>
              Total Price: £
              {cartDetails?.items
                .reduce((acc, item) => acc + item.unitPrice * item.quantity, 0)
                .toFixed(2)}
            </p>
            <button
              style={modalStyles.button}
              onClick={clearCart}
              disabled={isClearing || !cartDetails || cartDetails.items.length === 0}
            >
              {isClearing ? "Clearing..." : "Clear Cart"}
            </button>
            <ul style={modalStyles.listStyle}>
              {cartDetails?.items.map((item) => (
                <li key={item.id} style={modalStyles.listItemStyle}>
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    style={modalStyles.imageStyle}
                  />
                  <div style={modalStyles.itemDetail}>
                    <p style={{ margin: 0, fontSize: 16, fontWeight: 800 }}>
                      {item.name}
                    </p>
                    <p><strong>Barcode:</strong> {item.barcode || "N/A"}</p>
                    <p><strong>Description:</strong> {item.description || "N/A"}</p>
                    <p><strong>Category:</strong> {item.category || "N/A"}</p>
                    <p>Quantity: {item.quantity}</p>
                    <p>Unit Price: £{item.unitPrice ? item.unitPrice.toFixed(2) : "N/A"}</p>
                    <p>Total Price: £{item.unitPrice ? (item.unitPrice * item.quantity).toFixed(2) : "N/A"}</p>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        <button style={modalStyles.closeButton} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

// Reuse and adapt the modalStyles from the previous example for consistency
const modalStyles: { [key: string]: CSSProperties } = {
  listStyle: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
    gap: "20px",
    padding: "20px",
  },
  listItemStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    textAlign: "center",
    position: "relative",
  },
  imageStyle: {
    width: "90%",
    height: "100px",
    border: "3px solid #007ae4",
    objectFit: "cover",
  },
  topRightCloseButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    border: "none",
    background: "none",
    color: "black",
    fontSize: "24px",
    cursor: "pointer",
    padding: "0",
    lineHeight: "1",
  },
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  },
  container: {
    position: "relative",
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "750px",
    width: "90%",
    maxHeight: "85vh",
    overflowY: "auto",
  },
  button: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#007bff",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
  },
  closeButton: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
  },
};

export default CartAdminModal;
