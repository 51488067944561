// OrderRow.tsx

import React, { useState } from "react";
import DispatchModal from "./DispatchModal";
import { Location } from "./Types";

interface Order {
  orderID: string;
  orderType: string;
  orderedBy: string;
  orderDateService: string;
  orderTimeService: string;
  orderDatePlaced: string;
  orderStatus: string;
  address: {
    cityAddress: string;
    numberAddress: string;
    streetAddress: string;
    stateAddress: string;
    zipCodeAddress: string;
    phoneAddress: string;
    location: Location;
  };
}

interface OrderRowProps {
  order: Order;
  isModalOpen: boolean;
  toggleModal: () => void;
}

const DispatchButton: React.FC<{
  isOpen: boolean;
  handleDispatchClick: (event: React.MouseEvent) => void;
}> = ({ isOpen, handleDispatchClick }) => {
  return (
    <div style={{ position: "relative", marginRight: 10 }}>
      <button onClick={handleDispatchClick} style={styles.dispatchBtn}>
        Dispatch
      </button>
    </div>
  );
};

const OrderRow: React.FC<OrderRowProps> = ({
  order,
  isModalOpen,
  toggleModal,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(order.orderStatus);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleExpand = () => {
    if (!isDropdownOpen) {
      setExpanded(!expanded);
    }
  };

  const handleOptionClick = (option: string) => {
    // Handle the selected option (e.g., reschedule or cancel)
    console.log(`Selected option: ${option}`);
    // Close the dropdown menu
    setIsDropdownOpen(false);
  };

  const handleDispatchClick = (event: React.MouseEvent) => {
    // Prevent the click event from propagating to the row element
    event.stopPropagation();
    toggleModal();
  };

  const handleDropDownClick = (event: React.MouseEvent) => {
    // Prevent the click event from propagating to the row element
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div style={styles.orderRow}>
      <div style={styles.orderSummary} onClick={toggleExpand}>
        <div>
          <span style={styles.rowLabel}>Order ID:</span>
          <br />
          <span>{order.orderID}</span>
        </div>
        <div>
          <span style={styles.rowLabel}>Area:</span>
          <br />
          <span>{order.address.cityAddress}, </span>
          <span>{order.address.zipCodeAddress}</span>
        </div>
        <div>
          <span style={styles.rowLabel}>Service:</span>
          <br />
          <span>{order.orderType}</span>
        </div>
        <div>
          <span style={styles.rowLabel}>Customer ID:</span>
          <br />
          <span>{order.orderedBy}</span>
        </div>
        <div>
          <span style={styles.rowLabel}>Service Date/Time:</span>
          <br />
          <span>
            {order.orderDateService} {order.orderTimeService}
          </span>
        </div>
        <div style={styles.buttonContainer}>
          <DispatchButton
            isOpen={isModalOpen}
            handleDispatchClick={handleDispatchClick}
          />
          <div style={styles.dispatchButton}>
            <button onClick={handleDropDownClick} style={styles.dispatchBtn}>
              <span style={styles.dropdownArrow}>&#9662;</span>
            </button>

            {isDropdownOpen && (
              <div style={styles.dropdownMenu}>
                <button
                  onClick={() => handleOptionClick("Reschedule")}
                  style={styles.dropdownMenuButton}
                >
                  Reschedule
                </button>
                <button
                  onClick={() => handleOptionClick("Cancel")}
                  style={styles.dropdownMenuButton}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          <span>Status: </span>

          <span style={getStatusStyle(selectedStatus)}>
            {order.orderStatus}
          </span>
        </div>
      </div>
      {expanded && (
        <div style={styles.expandedDetails}>
          <div style={styles.leftPart}>
            <span>Address Details:</span>
            <br />
            <span>{order.address.numberAddress}</span>
            <span>, {order.address.streetAddress}</span>
            <br />
            <span>City:{order.address.cityAddress}</span>
            <br />
            <span>State: {order.address.stateAddress}</span>
            <br />
            <span>ZipCode: {order.address.zipCodeAddress}</span>
          </div>
          <div style={styles.rightPart}>
            <span>Order Placed On:</span>
            <br />
            <span>{order.orderDatePlaced}</span>
          </div>
        </div>
      )}
      <DispatchModal isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
};

const getStatusStyle = (status: string) => ({
  border: `3px solid black`, // Add border style
  color: "black",
  backgroundColor: getStatusColor(status), // Fill the oval with the desired color
  padding: "5px",
  //borderRadius: '20px',
});

const getStatusColor = (status: string) => {
  switch (status) {
    case "Pending":
      return "yellow";
    case "Completed":
      return "green";
    case "Canceled":
    case "Delayed":
      return "red";
    default:
      return "black";
  }
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  buttonContainer: {
    display: "flex", // Display its children in a row
    alignItems: "center", // Vertically center the items
  },

  dispatchButton: {
    position: "relative",
  },
  dispatchBtn: {
    background: "#1d344c",
    color: "#fff",
    border: "none",
    padding: "10px 10px",
    cursor: "pointer",
    borderRadius: "0px",
    display: "flex",
    alignItems: "center",
  },
  dispatchBtnHover: {
    background: "#0056b3",
  },
  dropdownArrow: {
    transition: "transform 0.2s",
  },
  dropdownArrowOpen: {
    transform: "rotate(180deg)",
  },
  dropdownMenu: {
    position: "absolute",
    top: "100%",
    left: "0",
    background: "#fff",
    border: "2px solid #1d344c",
    display: "flex",
    flexDirection: "column",
    zIndex: "1",
  },
  dropdownMenuButton: {
    border: "none",
    background: "none",
    padding: "10px",
    cursor: "pointer",
    textAlign: "left",
  },
  dropdownMenuButtonHover: {
    background: "#f5f5f5",
  },

  rowLabel: {
    fontWeight: "bold",
  },
  orderRow: {
    border: "1px solid #1d344c",
    //borderRadius: '10px',
    padding: "40px",
    margin: "10px",
    backgroundColor: "white",
  },
  orderSummary: {
    display: "flex",
    flexDirection: "row", // Adjust to a row layout
    justifyContent: "space-between", // Add space between elements
    alignItems: "center",
    color: "black",
    fontSize: "20px",
  },

  expandedDetails: {
    display: "flex",
    flexDirection: "row", // Arrange left and right parts side by side
    justifyContent: "space-between", // Create space between left and right parts
    alignItems: "flex-start", // Align text at the top of each part
    marginTop: "20px",
    padding: "35px",
    borderRadius: "10px",
    backgroundColor: "#f7f7f7",
  },
  leftPart: {
    marginRight: "200px", // Add spacing between left and right parts
  },
  rightPart: {
    flex: 1, // Right part takes up 50% of the available width
  },
};

export default OrderRow;
