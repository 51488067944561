import React, { useState, CSSProperties } from "react";
import { db } from "../App";
import { addDoc, collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

interface AddStoreModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddProspectModal: React.FC<AddStoreModalProps> = ({ isOpen, onClose }) => {
  const [storeName, setStoreName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");

  
  const validateFields = () => {
    if (storeName.trim().length < 3) {
      return "Store name must be at least 3 characters long.";
    }
    if (address.trim().length < 4) {
      return "Address must be at least 4 characters long.";
    }
    if (!/^\+?\d{10,15}$/.test(phoneNumber.trim())) {
      return "Please enter a valid phone number.";
    }
    if (!/\S+@\S+\.\S+/.test(email.trim())) {
      return "Please enter a valid email address.";
    }
    return "";
  };


  const handleAddStore = async () => {
    setUploading(true);
    const validationError = validateFields();
    if (validationError) {
      setErrorMessage(validationError);
      setUploading(false);
      return;
    }
    const auth = getAuth();
    // Generate a default password from the phone number
    const defaultPassword = phoneNumber.trim().slice(-6); // Last 6 digits
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email.trim(), defaultPassword);
      const uid = userCredential.user.uid;
      await setDoc(doc(db, "Prospects", uid), {
        name: storeName.trim(),
        address: address.trim(),
        phoneNumber: phoneNumber.trim(),
        email: email.trim(),
        created: serverTimestamp(),
      });
      console.log("Store and user successfully added!");
      onClose(); // Close the modal on success
    } catch (error) {
      console.error("Error adding store and user: ", error);
      setErrorMessage("Failed to add store. Please try again.");
    } finally {
      setUploading(false);
    }
  };
  

  if (!isOpen) return null;

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <h1>Add Store</h1>
        {errorMessage && (
          <div style={{ color: "red", marginBottom: "10px" }}>{errorMessage}</div>
        )}
        <input
          type="text"
          placeholder="Store Name"
          value={storeName}
          onChange={(e) => setStoreName(e.target.value)}
          style={modalStyles.inputStyles}
        />
        <input
          type="text"
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          style={modalStyles.inputStyles}
        />
          <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={modalStyles.inputStyles}
        />
        <input
          type="text"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          style={modalStyles.inputStyles}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <button
            onClick={onClose}
            style={{
              ...modalStyles.closeButton,
              backgroundColor: "#6c757d",
              marginRight: "10px",
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleAddStore}
            disabled={uploading}
            style={modalStyles.closeButton}
          >
            {uploading ? "Adding..." : "Add Store"}
          </button>
        </div>
      </div>
    </div>
  );
};



// Reuse modalStyles from your AddWarehouseModal component or adjust as necessary
const modalStyles = {
  inputStyles: {
    borderRadius: "12px", // Softer edges
    border: "1px solid #ced4da", // Lighter border color
    padding: "15px", // Increased padding
    marginTop: "12px", // Consistent margin top
    marginBottom: "12px", // Consistent margin bottom
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Subtle inner shadow for depth
    width: "calc(100% - 30px)", // Adjust width to account for padding
    fontSize: "14px", // Slightly larger font size for readability
  } as CSSProperties,
  headerText: {
    fontSize: "18px", // Larger font size for headers
    fontWeight: "bold", // Bold font weight for emphasis
    color: "#333", // Dark color for text
    textAlign: "center", // Center-align the text
    margin: "10px 0", // Margin for spacing above and below the text
    padding: "5px", // Optional padding
    marginBottom: 30,
    borderBottom: "1px solid #ddd", // Optional bottom border for a subtle line separator
  } as CSSProperties,
  centeredMessage: {
    display: "flex",
    fontSize: 30,
    fontWeight: "700",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100%", // Take full height of the container
    textAlign: "center", // Center text alignment
  } as CSSProperties,
  vehicleIcon: {
    width: "110px", // Set both width and height to the same value for a square shape
    height: "70px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "30%", // Circular shape
    objectFit: "cover",
    paddingLeft: 20,
    paddingRight: 20,
  } as CSSProperties,
  profileIcon: {
    width: "100px", // Set both width and height to the same value for a square shape
    height: "100px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "20%", // Circular shape
    marginBottom: "10px",
    objectFit: "cover",
  } as CSSProperties,
  additionalContentContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,
  leftSide: {
    width: "50%",
    marginRight: 30,
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,

    border: "1px solid #000000",
  } as CSSProperties,
  rightSide: {
    width: "50%",
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    border: "1px solid #000000",
  } as CSSProperties,
  providerDetailRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0", // Add padding for each row if needed
    // Add any additional styling you need for each detail row
  } as CSSProperties,

  barContainer: {
    //width: '60%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: 40,
    border: "1px solid #000000",
    borderRadius: 10,
    //backgroundColor:'pink'
  } as CSSProperties,
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "33.3333%", // Divide the bar container into three equal parts
  } as CSSProperties,

  closeButton: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
    "&:hover": {
      backgroundColor: "#0056b3", // Darker shade on hover
    },
  } as CSSProperties,

  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  } as CSSProperties, // Assert the entire object as CSSProperties
  container: {
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "600px", // Set a max width for larger screens
    width: "90%", // Responsive width
    maxHeight: "85vh",
    overflowY: "auto",
  } as CSSProperties,
  contentStyles: {
    // Define your styles here
    marginBottom: "10px",
  } as CSSProperties,
};

export default AddProspectModal;
