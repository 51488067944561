//@ts-nocheck

import React, { useEffect, useState } from "react";
import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { db } from "../App"; // Ensure the correct import for your Firebase setup
import InvoiceDetailsModal from "./InvoiceDetailsModal";
import { addDoc } from "firebase/firestore";

interface Invoice {
  id: string;
  date: any;
  storeName: string;
  storeAddress: string;
  totalPrice: number;
  totalPriceVat: number;
  status: string;
}

interface GenerateReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  report: { reportID: string; date: any } | null;
}

const GenerateReportModal: React.FC<GenerateReportModalProps> = ({
  isOpen,
  onClose,
  report,
}) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState<Set<string>>(
    new Set()
  );
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
  
      const invoicesRef = collection(db, "Invoices");
  
      // Query to get documents where status is "Accepted"
      const queryRef = query(
        invoicesRef,
        where("status", "==", "Accepted")
      );
  
      const unsubscribe = onSnapshot(queryRef, (snapshot) => {
        const invoicesList = snapshot.docs
          .map((docSnapshot) => {
            const invoiceData = docSnapshot.data();
            return {
              id: docSnapshot.id,
              ...invoiceData,
            };
          })
          .filter((invoice) => {
            // Filter out invoices where 'void' or 'archive' is true, or where those fields exist
            return (invoice.void !== true) && (invoice.archive !== true);
          })
          .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds); // Sort by most recent
  
        setInvoices(invoicesList);
        setIsLoading(false);
      });
  
      return () => unsubscribe();
    }
  }, [isOpen]);
  
  
  const saveReportToFirestore = async () => {
    if (selectedInvoices.size === 0) {
      alert("Please select at least one invoice to generate a report.");
      return;
    }

    const { totalPrice, totalPriceVat } = getTotalPriceAndVat();
    const { start, end } = getInvoiceDateRange();
    const selectedInvoicesDetails = getSelectedInvoices().map((invoice) => ({
      invoiceID: invoice.id,
      totalPrice: invoice.totalPrice,
      totalPriceVat: invoice.totalPriceVat || 0,
      storeId:invoice.storeId,
      storeName:invoice.storeName,
    }));

    const reportData = {
      reportID: report?.reportID,
      date: report?.date,
      totalPrice,
      totalPriceVat,
      numberOfInvoices: selectedInvoices.size,
      invoices: selectedInvoicesDetails,
      dateRange: start && end ? `${start} - ${end}` : null,
    };

    try {
      await setDoc(doc(db, "Reports", report?.reportID), reportData);
      alert("Report generated and saved successfully!");
      onClose(); // Close the modal after saving
    } catch (error) {
      console.error("Error generating report: ", error);
      alert("Failed to generate report. Please try again.");
    }
  };

  const openDetailsModal = (invoice: Invoice) => {
    setSelectedInvoice(invoice);
    setIsDetailsModalOpen(true);
  };

  const toggleSelectInvoice = (invoiceId: string) => {
    setSelectedInvoices((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(invoiceId)) {
        newSelected.delete(invoiceId);
      } else {
        newSelected.add(invoiceId);
      }
      return newSelected;
    });
  };

  const getSelectedInvoices = () => {
    return invoices.filter((invoice) => selectedInvoices.has(invoice.id));
  };

  const getTotalPriceAndVat = () => {
    const selected = getSelectedInvoices();
    const totalPrice = selected.reduce(
      (sum, invoice) => sum + invoice.totalPrice,
      0
    );
    const totalPriceVat = selected.reduce(
      (sum, invoice) => sum + (invoice.totalPriceVat || 0),
      0
    );
    return { totalPrice, totalPriceVat };
  };

  const getInvoiceDateRange = () => {
    const selected = getSelectedInvoices();
    if (selected.length === 0) return { start: null, end: null };

    const dates = selected.map(
      (invoice) => new Date(invoice.timestamp.seconds * 1000)
    );
    const sortedDates = dates.sort((a, b) => a.getTime() - b.getTime());
    return {
      start: sortedDates[0].toLocaleDateString(),
      end: sortedDates[sortedDates.length - 1].toLocaleDateString(),
    };
  };

  const handleMonthSelect = (monthIndex: string) => {
    setSelectedMonth(monthIndex);
    const selectedInvoicesInMonth = invoices.filter(invoice => {
      const invoiceDate = new Date(invoice.timestamp.seconds * 1000);
      return invoiceDate.getMonth().toString() === monthIndex;
    }).map(invoice => invoice.id);
  
    setSelectedInvoices(prevSelected => {
      const newSelected = new Set(prevSelected);
      selectedInvoicesInMonth.forEach(id => newSelected.add(id));
      return newSelected;
    });
  };

  
  const { totalPrice, totalPriceVat } = getTotalPriceAndVat();
  const { start, end } = getInvoiceDateRange();
  const selectedCount = selectedInvoices.size;

  if (!isOpen || !report) return null;

  const modalBackdropStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalStyle: React.CSSProperties = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "1200px", // Increase the width of the modal
    width: "100%",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 1001,
  };

  const summaryStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  };

  const gridContainerStyle: React.CSSProperties = {
    paddingLeft: 50,
    paddingRight: 50,
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", // Smaller items in the grid
    gap: "20px",
    alignItems: "start",
    maxHeight: "calc(57vh)",
    overflowY: "auto",
  };

  const invoiceItemStyle = (isSelected: boolean): React.CSSProperties => ({
    border: isSelected ? "4px solid #007bff" : "4px solid #ddd", // Blue outline if selected
    padding: "10px", // Smaller padding
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    fontSize: "14px", // Smaller font size
  });

  const addButtonStyle: React.CSSProperties = {
    backgroundColor: "#1c345d",
    color: "white",
    padding: "10px 20px",
    margin: "10px",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "16px",
  };

  return (
    <div style={modalBackdropStyle}>
      <div style={modalStyle}>
        <div style={summaryStyle}>
          <div>
            <h2>Generate Report</h2>
            <p>
              <strong>Report ID:</strong> {report.reportID}
            </p>
            <p>
              <strong>Date:</strong> {report.date}
            </p>
            <p>
              <strong>Selected Invoices:</strong> {selectedCount} invoices
            </p>
            <p>
              <strong>Total Price NON VAT:</strong> £{totalPrice.toFixed(2)}
            </p>
            <p>
              <strong>Total Price VAT:</strong> £{totalPriceVat.toFixed(2)}
            </p>
            {start && end && (
              <p>
                <strong>Date Range:</strong> {start} - {end}
              </p>
            )}
            <select
              value={selectedMonth || ""}
              onChange={(e) => handleMonthSelect(e.target.value)}
              style={{
                marginBottom: "20px",
                padding: "5px 10px",
                borderRadius: "5px",
              }}
            >
              <option value="">Select Month</option>
              {Array.from({ length: 12 }, (_, i) => {
                const month = new Date(0, i).toLocaleString("default", {
                  month: "long",
                });
                return (
                  <option key={i} value={i}>
                    {month}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <button onClick={onClose} style={addButtonStyle}>
              Close
            </button>
            <button
              onClick={saveReportToFirestore}
              style={{ ...addButtonStyle, backgroundColor: "#28a745" }}
            >
              Generate Report
            </button>
          </div>
        </div>

        {isLoading ? (
          <p>Loading invoices...</p>
        ) : (
          <div style={gridContainerStyle}>
            {invoices.map((invoice) => (
              <div
                key={invoice.id}
                style={invoiceItemStyle(selectedInvoices.has(invoice.id))}
                onClick={() => toggleSelectInvoice(invoice.id)}
              >
                <h3>Invoice ID: {invoice.id}</h3>
                <p>
                  Date:{" "}
                  {invoice.timestamp
                    ? new Date(
                        invoice.timestamp.seconds * 1000
                      ).toLocaleString()
                    : "No timestamp available"}
                </p>{" "}
                <p>Store: {invoice.storeName}</p>
                <p>Address: {invoice.storeAddress}</p>
                <p>Total Price: £{invoice.totalPrice.toFixed(2)}</p>
                {invoice.totalPriceVat && (
                  <p>Total Price VAT: £{invoice.totalPriceVat.toFixed(2)}</p>
                )}
                <button
                  style={{ ...addButtonStyle, backgroundColor: "#007bff" }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event
                    openDetailsModal(invoice);
                  }}
                >
                  Invoice Details
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {isDetailsModalOpen && selectedInvoice && (
        <InvoiceDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          invoice={selectedInvoice}
        />
      )}
    </div>
  );
};

export default GenerateReportModal;
