import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

// Define the shape of your context data
interface LanguageContextData {
  language: string;
  updateLanguage: (newLanguage: string) => void;
}

// Create the context with a default value
const LanguageContext = createContext<LanguageContextData>({
  language: 'english', // Provide a default language
  updateLanguage: () => {} // Provide a default function
});

export const LanguageProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('appLanguage') || 'english');

  useEffect(() => {
    // Update the class of the root element based on the language
    const root = document.body; // Use document.body instead of document.documentElement

    console.log('herhehrehrh')

    if (language === 'kurdish') {
      root.classList.add('kurdish-font');
      root.classList.remove('english-font');
    } else if (language === 'english') {
      root.classList.add('english-font');
      root.classList.remove('kurdish-font');
    } else {
      root.classList.remove('kurdish-font', 'english-font');
    }
    
    // Save the language choice in local storage
    localStorage.setItem('appLanguage', language);
  }, [language]);
  
  const updateLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
    localStorage.setItem('appLanguage', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, updateLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
