import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TopBar from "./TopBar";
import Sidebar from "./SideBar";
import { db } from "../App";
import { collection, getDocs } from "firebase/firestore"; // Import Firestore methods
import { Order } from "./Types";
import Orders from "./Orders";
import Prospects from "./Prospects";
import Providers from "./Providers";
import Pricing from "./Pricing";
import Notification from "./Notification";
import Warehouse from "./Warehouse";
import Dashboard from "./Dashboard";
import Authentication from "./Authentication";
import { useLanguage } from "../LanguageContext";
import Products from "./Products";
import Invoices from "./Invoices";
import CartsAdmin from "./CartsAdmin";
import Reports from "./Reports";

const Index: React.FC = () => {
  const navigate = useNavigate();

  const auth = getAuth();

  const { language } = useLanguage(); // Get the current language

  const [selectedTab, setSelectedTab] = useState("WAREHOUSE"); // Default selected tab
  const [dashboardView, setDashboardView] = useState("LIST"); // Default dashboard view
  const [markers, setMarkers] = useState<google.maps.LatLngLiteral[]>([]);

  const [todos, setTodos] = useState<Order[]>([]);

  const contentContainerStyleBasedOnLanguage: React.CSSProperties = {
    ...styles.contentContainer,
    flexDirection: language === "kurdish" ? "row-reverse" : "row", // Change direction for Kurdish
  };

  const fetchPost = async () => {
    await getDocs(collection(db, "Orders")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      //@ts-ignore
      setTodos(newData);
      //console.log(newData);
      const newMarkers = newData.map((order) => ({
        //@ts-ignore
        lat: order.address.location.latitude,
        //@ts-ignore
        lng: order.address.location.longitude,
      }));
      //console.log(newMarkers)

      setMarkers(newMarkers); // Set the markers state
    });
  };

  const defaultProps = {
    center: {
      lat: 33.7175,
      lng: -117.8311,
    },
    zoom: 11,
  };

  useEffect(() => {
    fetchPost();
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        //const uid = user.uid;
        // console.log("uid", uid)
      } else {
        navigate("/login");
        console.log("user is logged out");
      }
    });
  }, [navigate]);

  {
    /*
  useEffect(() => {
    // Get user's current location using browser's geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);    */
  }

  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
  };

  const handleDashboardViewChange = (view: string) => {
    setDashboardView(view);
  };

  const renderMarkers = (map: any, maps: any) => {
    todos.forEach((todo) => {
      const marker = new maps.Marker({
        position: {
          lat: todo.address.location.latitude,
          lng: todo.address.location.longitude,
        },
        map,
        title: todo.orderID, // You can set the title to something meaningful
      });

      // Add click event listener to the marker (if needed)
      marker.addListener("click", () => {
        // Handle marker click event here
        console.log(`Marker clicked: ${todo.orderID}`);
      });
    });
  };

  const tabContent: { [key: string]: JSX.Element } = {
    WAREHOUSE: <Warehouse />,
    INVOICES: <Invoices />,
    REPORTS: <Reports />,
    PRODUCTS: <Products />,
    PROSPECTS: <Prospects />,
    CARTS: <CartsAdmin />,


    NOTIFICATIONS: <Notification />,
    SUPPORT: <Notification />,
    ORDERS: (
      <Orders
        todos={todos}
        dashboardView={dashboardView}
        handleDashboardViewChange={handleDashboardViewChange}
        renderMarkers={renderMarkers}
        defaultProps={defaultProps}
      />
    ),
    PROVIDERS: <Providers />,
    PRICING: <Pricing />,
    DASHBOARD: <Dashboard />,
    AUTHENTICATION: <Authentication />,

    // Add other tab content as needed
  };

  return (
    <div style={styles.container}>
      <TopBar />
      <div style={contentContainerStyleBasedOnLanguage}>
        <Sidebar onTabSelect={handleTabSelect} selectedTab={selectedTab} />
        <div style={styles.content}>{tabContent[selectedTab]}</div>
      </div>
    </div>
  );
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  contentContainer: {
    display: "flex",
    flex: 1,
  },
  dashboardTabs: {
    display: "flex",
    justifyContent: "center", // Center the buttons horizontally
    marginBottom: "10px", // Add some spacing below the buttons
  },
  tabButton: {
    border: "none", // Remove border
    fontSize: "15px",
    margin: "0 auto", // This will center the button horizontally
    display: "block", // Ensures the button takes the full width available
    padding: "5px 20px", // Add some padding so it looks nice
  },
  content: {
    flex: 1,
    //border: '2px solid red',
    //padding: '10px',
  },
};

export default Index;
