//@ts-nocheck
import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../App";

const InvoiceDetailsModal = ({ isOpen, onClose, invoice, invoiceId }: any) => {
  const [loadedInvoice, setLoadedInvoice] = useState(null);

  useEffect(() => {
    if (invoiceId) {
      const loadInvoiceData = async () => {
        const invoiceDocRef = doc(db, "Invoices", invoiceId);
        const invoiceSnap = await getDoc(invoiceDocRef);
        if (invoiceSnap.exists()) {
          setLoadedInvoice(invoiceSnap.data());
        } else {
          console.error("Invoice not found:", invoiceId);
        }
      };
      loadInvoiceData();
    } else {
      setLoadedInvoice(null); // Reset the state if no invoiceId is provided
    }
  }, [invoiceId]);

  const displayInvoice = invoiceId ? loadedInvoice : invoice;

  if (!isOpen || (!displayInvoice && invoiceId)) return null;

  const modalStyles = {
    background: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1001,
    },
    container: {
      backgroundColor: "white",
      padding: "25px",
      borderRadius: "10px",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
      maxWidth: "720px",
      width: "90%",
      maxHeight: "85vh",
      overflowY: "auto",
      position: "relative",
    },
    closeButton: {
      fontSize: "16px",
      fontWeight: "600",
      backgroundColor: "#6c757d",
      color: "white",
      borderRadius: "10px",
      padding: "8px 10px",  // Reduced padding
      border: "none",
      cursor: "pointer",
      outline: "none",
      transition: "background-color 0.2s ease",
      position: "absolute",
      top: "10px",
      right: "10px",
    },
    itemGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
      gap: "10px",  // Reduced gap
      padding: "10px",  // Reduced padding
    },
    itemContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "10px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      textAlign: "center",
      position: "relative",
    },
    imageStyle: {
      width: "90%",
      height: "100px",
      border: "3px solid #007ae4",
      objectFit: "cover",
    },
    itemDetail: {
      marginTop: "5px",  // Reduced margin
      fontSize: "12px",  // Reduced font size
    },
    detailRow: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      flexWrap: "wrap",  // Ensure text wraps
    },
    detailText: {
      width: "50%",
      textAlign: "left",
      padding: "0 0px",
      wordBreak: "break-word",  // Ensure long text wraps
    },
  };
  

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <button style={modalStyles.closeButton} onClick={onClose} aria-label="Close">×</button>
        <h2>Invoice Details</h2>
        {displayInvoice ? (
          <>
            <p><strong>Invoice ID:</strong> {invoiceId || invoice.id}</p>
            <p><strong>Date:</strong> {new Date(displayInvoice.timestamp.seconds * 1000).toLocaleString()}</p>
            <p><strong>Store:</strong> {displayInvoice.storeName || "No Store Name"}</p>
            <p><strong>Address:</strong> {displayInvoice.storeAddress || "No Address"}</p>
            <p><strong>Phone Number:</strong> {displayInvoice.phoneNumber || "No Phone Number"}</p>
            <p><strong>Total Price:</strong> £{displayInvoice.totalPrice.toFixed(2)}</p>
            <h3>Items:</h3>
            <div style={modalStyles.itemGrid}>
              {displayInvoice.items.map((item, index) => (
                <div key={index} style={modalStyles.itemContainer}>
                  <img
                    src={item.imageUrl}
                    alt="Item"
                    style={modalStyles.imageStyle}
                  />
                  <div style={modalStyles.itemDetail}>
                    <p style={{ margin: 0, fontSize: 16, fontWeight: 800 }}>{item.name}</p>
                    <div style={modalStyles.detailRow}>
                      <p style={modalStyles.detailText}>Barcode: {item.barcode}</p>
                      <p style={modalStyles.detailText}>Category: {item.category}</p>
                    </div>
                    <div style={modalStyles.detailRow}>
                      <p style={modalStyles.detailText}>Description: {item.description}</p>
                      <p style={modalStyles.detailText}>Quantity: {item.quantity}</p>
                    </div>
                    <div style={modalStyles.detailRow}>
                      <p style={modalStyles.detailText}>Unit Price: £{item.unitPrice.toFixed(2)}</p>
                      <p style={modalStyles.detailText}>Total: £{(item.quantity * item.unitPrice).toFixed(2)}</p>
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <p>Loading invoice details...</p>
        )}
      </div>
    </div>
  );
};

export default InvoiceDetailsModal;
