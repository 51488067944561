import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { SERVER_URL, UserData } from './Types';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import SendNotificationModal from './SendNotificationModal';
import { useLanguage } from '../LanguageContext';

const Notification: React.FC = () => {

  const { language } = useLanguage(); 

  const translations = {
    Notification: language === 'kurdish' ? 'ئاگادارییەکان' : 'Notifications',
    New: language === 'kurdish' ? '+ نوێ' : 'NEW +',
    Title: language === 'kurdish' ? ':ناونیشان' : 'Title:',
    Body: language === 'kurdish' ? ':ناوەڕۆک' : 'Body:',
    Recipients: language === 'kurdish' ? ':وەرگرەکان' : 'Recipients:',
    Date: language === 'kurdish' ? ':بەروار' : 'Date:',
  };

  const [notifications, setNotifications] = useState<[]>([]); // State for storing fetched users
  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const [selectedNotification, setSelectedNotification] = useState<string | null>(null); // Track the selected UID


   const openModal = () => {
    setSelectedNotification(null); // Set the selected UID when the button is clicked
    setIsModalOpen(true);
  }
  const onCloseModal = () => {
    setIsModalOpen(false);
    fetchNotifications(); // Re-fetch notifications when the modal closes
  };
  

  const headerStyles: React.CSSProperties = {
    display: 'flex',
    justifyContent: language === 'kurdish' ? 'space-between' : 'space-between',
    alignItems: 'center',
    margin: '10px 60px 10px 60px',
    flexDirection: language === 'kurdish' ? 'row-reverse' : 'row', // Adjust direction for Kurdish
  };

  const baseRowStyles: React.CSSProperties = {
    //borderRadius: '10px',
    color: 'black',
    fontSize: '20px',
    padding: '40px',
    border: '2px solid #000000',
    margin: '10px',
    marginRight:'60px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
 
  };

  const rowStyles: React.CSSProperties = {
    ...baseRowStyles, // Spread the base row styles
    flexDirection: language === 'kurdish' ? 'row-reverse' : 'row', // Add conditional flexDirection
  };

  const columnStyles: React.CSSProperties = {
    flex: 0.1, // Distributes space evenly
    paddingLeft:40,
    //minWidth: '0', // Ensures flex shrinkage if necessary
  };
  const parentContainerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column', // Stack children vertically
    height: '100vh', // Set the height of this container to the full viewport height
    // Subtract the height of any other elements like the header and any margins they have
  };
const scrollableListContainerStyles: React.CSSProperties = {
  flex: 0.9, // Takes up all available space in a flex container
  overflowY: 'auto', // Enable vertical scrolling
};
  function formatDateTime(dateStr:any) {
    const dateObj = new Date(dateStr);

    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = dateObj.getFullYear();

    return `${hours}:${minutes} ${day}/${month}/${year}`;
}


  const fetchNotifications = async () => {
    setIsLoading(true)
    try {
        const response = await fetch(`${SERVER_URL}/api/notifications`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        setNotifications(data);
        setIsLoading(false)

    } catch (error) {
        console.error('Error fetching notifications:', error);
        setIsLoading(false)
    }
};

useEffect(() => {
  // Fetch user data when the component mounts
  fetchNotifications();
    }, []);

  return (
    <div style={parentContainerStyles}>
      <div style={headerStyles}>
      <h1 style={{ fontSize: 35 }}>{translations.Notification}</h1>
        <button style={{ fontSize: 30 }} onClick={openModal}>{translations.New}</button>
       </div>    
    {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <p style={{ fontSize: '24px',marginTop:-500  }}>Loading...</p>
        </div>
      ) : notifications.length > 0 ? (
        <div style={scrollableListContainerStyles}>
            <ul>
              {notifications.map((notification:any) => (
                <li key={notification.title} style={rowStyles}>
    <div style={columnStyles}>
    <span style={{ fontWeight: 'bold' }}>{translations.Title}</span>
                    <br />
                    <span>{notification.title}</span>
                  </div>
                  <div style={columnStyles}>
                  <span style={{ fontWeight: 'bold' }}>{translations.Body}</span>
                    <br />
                    <span>{notification.body}</span>
                  </div>
                  <div style={columnStyles}>
                  <span style={{ fontWeight: 'bold' }}>{translations.Recipients}</span>
                    <br />
                    {notification.recipients && notification.recipients.length > 0 ? `Block ${notification.recipients.join(', ')}` : 'All'}
                  </div>
                  <div style={columnStyles}>
                  <span style={{ fontWeight: 'bold' }}>{translations.Date}</span>
                    <br />
                    <span>{formatDateTime(notification.date)}</span>
                  </div>
                  <div>

                  
    <button style={{ fontSize:0, opacity:0}} >Details</button>
    
                  </div>
                </li>
              ))}
            </ul>
            </div>

          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <p style={{ fontSize: '24px',marginTop:-500 }}>No Notification Data Available</p>
          </div>
                )}
      {isModalOpen && (
            <SendNotificationModal
              isOpen={isModalOpen}
              onClose={onCloseModal}
              />
          )}
        </div>
  );
};

export default Notification;
